const style = theme => ({
  headerRightContent: {
    marginRight: 20
  },
  buttonsMobile: {
    margin: '15px auto 0'
  },
  warningContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center'
  },
  formContainer: {
    paddingBottom: 20
  },
  scrollableGridItem: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 20
    },
    [theme.breakpoints.up('sm')]: {
      overflow: 'auto',
      height: `calc(100vh - ${theme.headerHeight}px - ${theme.subheaderHeight}px)`
    }
  },
  footerButton: {
    margin: '10px 0'
  }
})

export default style

import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, TableFooter, TablePagination, Paper, Checkbox, Input } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Field, FieldArray } from 'redux-form'

import { translations } from '../../../config'

import style from './style'
import InputLabel from '../../InputLabel'
import CopyToClipboardButton from '../../CopyToClipboardButton'
import Loader from '../../Loader/Loader'
import Chip from '../../Chip/Chip'

const TableList = ({
  classes,
  name,
  label,
  headers,
  disabled,
  count,
  defaultRowsPerPage = 10,
  rowsPerPageOptions,
  onPageChange,
  onRowsPerPageChange,
  onInputChange,
  enableSearch,
  query,
  onOptionClick,
  enablePagination,
  groupOptionsBy,
  isLoading
}) => {

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(enablePagination ? defaultRowsPerPage : 1000000)

  const onChangePage = useCallback((event, page) => {
    setPage(page)
    onPageChange && onPageChange({ offset: page * rowsPerPage, limit: rowsPerPage })
  }, [setPage, onPageChange])

  const onChangeRowsPerPage = useCallback((e) => {
    const limit = e.target.value
    setRowsPerPage(limit)
    onRowsPerPageChange && onRowsPerPageChange({ limit, offset: 0 })
    setPage(0)
  }, [setRowsPerPage, onRowsPerPageChange, setPage])

  const handleInputChange = useCallback((e) => {
    setPage(0)
    onInputChange && onInputChange(e)
  }, [setPage, onInputChange])

  return (
    <FieldArray
      name={name}
      headers={headers}
      disabled={disabled}
      label={label}
      page={page}
      count={count}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onInputChange={handleInputChange}
      onOptionClick={onOptionClick}
      component={renderTable}
      enableSearch={enableSearch}
      query={query}
      classes={classes}
      enablePagination={enablePagination}
      groupOptionsBy={groupOptionsBy}
      isLoading={isLoading}
    />
  )
}

const renderTable = (props) => {
  const {
    classes,
    label,
    headers,
    fields,
    disabled,
    page,
    count,
    rowsPerPage,
    rowsPerPageOptions,
    onChangePage,
    onChangeRowsPerPage,
    onInputChange,
    enableSearch,
    query,
    onOptionClick,
    enablePagination = true,
    groupOptionsBy,
    isLoading
  } = props

  const options = fields.getAll()
  const finalCount = _.isNil(count) ? -1 : count

  return (
    <>
      <InputLabel
        htmlFor={label}
        key='label'
        shrink
      >
        {label}
      </InputLabel>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            {enableSearch &&
              <Input
                className={classes.searchInput}
                onChange={onInputChange}
                value={query}
                placeholder={translations('Search')}
              />}
            {renderHead(headers)}
          </TableHead>
          <TableBody>
            {
              isLoading && (
                <TableRow>
                  <TableCell colSpan={headers.length}>
                    <Loader />
                  </TableCell>
                </TableRow>
              )
            }
            {
              !isLoading && (
                groupOptionsBy
                  ? renderGroupedOptions({
                    classes,
                    headers,
                    options,
                    fields,
                    disabled,
                    rowsPerPage,
                    page,
                    onOptionClick,
                    groupOptionsBy
                  })
                  : renderOptions(
                    classes,
                    headers,
                    options,
                    fields,
                    disabled,
                    rowsPerPage,
                    page,
                    onOptionClick
                  )
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              {enablePagination &&
                <TablePagination
                  count={finalCount}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions || [3, 5, 10, 25]}
                />
              }
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  )
}

const renderHead = (headers) => {
  if (!headers) { return <div /> }
  const headerCells = _.map(headers, header => {
    const width = _.get(header, 'width', '20%')
    const align = _.get(header, 'align', 'left')
    return <TableCell align={align} width={width}><b>{header.label}</b></TableCell>
  })
  const headerRow = <TableRow>{headerCells}</TableRow>
  return headerRow
}

const renderOptions = (
  classes,
  headers,
  options,
  fields,
  disabled,
  rowsPerPage,
  page,
  onOptionClick
) => {
  const start = rowsPerPage * page
  const end = rowsPerPage * (page + 1) - 1

  let count = 0
  const maxRowsReached = count > rowsPerPage
  const shouldNotRender = (index) => (index < start || index > end)

  const optionRows = fields.map((field, index) => {
    const option = options[index]
    if (!option || maxRowsReached || shouldNotRender(index)) { return <></> }
    count++
    return (
      <TableRow
        className={onOptionClick ? classes.clickableRow : null}
        onClick={onOptionClick ? () => { onOptionClick(option.id) } : null}
      >
        {renderOption({ headers, option, field, disabled })}
      </TableRow>
    )
  })
  return optionRows
}

// Currently does not support pagination
const renderGroupedOptions = ({
  classes,
  headers,
  options,
  fields,
  disabled,
  onOptionClick,
  groupOptionsBy
}) => {
  if (_.isEmpty(options)) {
    return
  }

  const groupedOptions = _.groupBy(options, groupOptionsBy)
  const parentIds = _.keys(groupedOptions)
  const allFields = fields.getAll()

  const mappedOptions = []
  for (const parentId of parentIds) {
    if (!parentId || parentId === 'null') {
      continue
    }
    const categoryOption = _.find(options, option => option.id === parentId)
    const parentData = groupedOptions[parentId]

    // Need to find the correct index as Field uses the fields name and index to pass values to input
    const fieldIndex = _.findIndex(allFields, field => field.id === categoryOption.id)
    const field = `${fields.name}[${fieldIndex}]`

    const parentOption = (
      <TableRow
        className={onOptionClick ? classes.clickableRow : null}
        onClick={onOptionClick ? () => { onOptionClick(categoryOption.id) } : null}
      >
        {renderOption({ headers, option: categoryOption, field, disabled })}
      </TableRow>
    )

    const childOptions = parentData.map((item, index) => {
      const fieldIndex = _.findIndex(allFields, field => field.id === item.id)
      const field = `${fields.name}[${fieldIndex}]`
      return (
        <TableRow
          className={onOptionClick ? classes.clickableRow : null}
          onClick={onOptionClick ? () => { onOptionClick(item.id) } : null}
        >
          {renderOption({ headers, option: item, field, disabled, isChild: true })}
        </TableRow>
      )
    })

    mappedOptions.push(parentOption)
    mappedOptions.push(...childOptions)
  }
  return mappedOptions
}

const renderOption = ({ headers, option, field, disabled, isChild }) => {
  const children = _.map(option, (value, key) => {
    const header = (_.find(headers, h => h.id === key))
    const type = _.get(header, 'type')
    const align = _.get(header, 'align', 'left')
    const readOnly = _.get(header, 'readOnly', false)
    if (type) {
      return (
        <TableCell align={align}>
          <Field
            name={`${field}.${key}`}
            type={type}
            component={getComponent}
            disabled={disabled || readOnly}
            isChild={isChild}
          />
        </TableCell>
      )
    }
  })
  return (children)
}

const getComponent = (fields) => {
  const {
    input,
    disabled,
    type,
    isChild
  } = fields
  const value = _.get(input, 'value')
  if (value === 'HIDE') {
    return <></>
  }
  switch (type) {
    case 'label':
      return <div style={{ marginLeft: isChild ? 30 : 0 }}>{value}</div>
    case 'checkbox':
      return <Checkbox {...input} disabled={disabled} />
    case 'input':
      return <Input {...input} disabled={disabled} />
    case 'copyButton':
      return <CopyToClipboardButton text={value} />
    default:
      return value
  }
}

export default withStyles(style)(TableList)


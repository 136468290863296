import React, { useEffect, useState } from 'react';
import { styled, Grid, IconButton, Typography, Collapse, Card, Paper, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import _ from 'lodash'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: theme.spacing(1)
}))

const Content = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  boxShadow: 0
}))

const ExpandContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: 0,
  paddingRight: 0
}))

const Name = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingLeft: 5,
  fontWeight: 600
}))

const PrimaryTableCell = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  fontSize: '14px',
  fontWeight: '600',
  borderBottom: 'none'
}))

const SecondaryTableCell = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  fontSize: '14px',
  borderBottom: 'none'
}))

const HybridTableCell = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  fontSize: '14px',
}))

const ActionTableCell = styled('div')(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '600',
  padding: 0
}))

const FinalActionTableCell = styled('div')(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '600',
  padding: 0,
  borderBottom: 'none'
}))

export const SectionTypes = {
  ACTION: 'ACTION',
  DETAIL: 'DETAIL',
  HYBRID: 'HYBRID'
}

const Section = (props) => {
  const {
    name,
    registerSection,
    toggleSectionVisiblity,
    getSection,
    rows: passedRows = [],
    collapsible = true
  } = props

  const [timeout, changeTimeout] = useState(0)
  const [rows, setRows] = useState([])

  useEffect(() => {
    const validRows = _.filter(passedRows, row => row.value)
    setRows(validRows)
  }, [passedRows])

  useEffect(() => {
    registerSection(name)
    // Don't want initial animation
    setTimeout(() => changeTimeout(300), 1000)
  }, [])

  const sectionDetails = getSection(name)
  const isOpen = _.get(sectionDetails, 'isOpen')
  const SectionIcon = isOpen ? ExpandLess : ExpandMore

  const renderRow = (row, index) => {
    if (!row.type || !row.value) {
      return
    }
    const type = row.type
    switch (type) {
      case SectionTypes.DETAIL:
        return renderDetail(row)
      case SectionTypes.ACTION:
        return renderAction(row, index)
      case SectionTypes.HYBRID:
        return renderHybrid(row)
    }
  }

  const renderDetail = (row) => {
    return (
      <TableRow key={row.name}>
        <TableCell component={PrimaryTableCell} scope='row' style={{ width: '30%' }}>
          {row.name}
        </TableCell>
        <TableCell component={SecondaryTableCell} align='right' style={{ width: '70%' }}>
          {row.value}
        </TableCell>
      </TableRow>
    )
  }

  const renderAction = (row, index) => {
    const isLastRow = (index === rows.length - 1)
    return (
      <TableRow key={row.name} style={{ width: '100%' }}>
        <TableCell component={isLastRow ? FinalActionTableCell : ActionTableCell} scope='row'>
          {row.value}
        </TableCell>
      </TableRow>
    )
  }

  const renderHybrid = (row) => {
    return (
      <TableRow key={row.name}>
        <TableCell component={HybridTableCell} style={{ fontWeight: '600' }} scope='row'>
          {row.name}
        </TableCell>
        <TableCell component={HybridTableCell} scope='row' align='center'>
          {row.value}
        </TableCell>
        <TableCell component={HybridTableCell} scope='row' align='right'>
          {row.action}
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={11}>
          <Name>{name}</Name>
        </Grid>
        <Grid item xs={1}>
          {collapsible &&
            <ExpandContainer>
              <IconButton
                onClick={() => toggleSectionVisiblity(name)}
                disableRipple={true}
                style={{ padding: 0 }}
              >
                <SectionIcon fontSize={'medium'} />
              </IconButton>
            </ExpandContainer>
          }
        </Grid>
        <Grid item xs={12}>
          <Collapse in={isOpen} timeout={timeout}>
            <TableContainer component={Content} variant='outlined'>
              <Table>
                <TableBody>
                  {rows.map((row, index) => renderRow(row, index))}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </Grid>
      </Grid>
    </Container >
  )
}

export default Section
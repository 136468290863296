import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import Section from '../../Section'
import moment from 'moment'
import { SectionTypes } from '../../Section'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { translations } from '../../../../../../config'
import { getCopyDetailButton, getTooltipDetailButton } from '../utils'
import { getHasUserManagement } from '../../../../../../store/modules/auth/selectors'
import { useAbilities } from '../../../../../../ability/ability-context'
import ReportLocation from './components/ReportLocation'

const ReportDetails = (props) => {
  const {
    registerSection,
    toggleSectionVisiblity,
    getSection,
    report
  } = props

  const dispatch = useDispatch()
  const ability = useAbilities()

  const reportId = _.get(report, 'id')
  const hasUserManagement = useSelector(getHasUserManagement)
  const creationTime = moment(_.get(report, 'createdAt')).format('DD/MM/YYYY HH:mm')
  const updatedTime = moment(_.get(report, 'updatedAt')).format('DD/MM/YYYY HH:mm')
  const userId = _.get(report, 'user.id')
  const firstName = _.get(report, 'user.firstName')
  const lastName = _.get(report, 'user.lastName')
  const author = (firstName && lastName) ? `${firstName} ${lastName}` : null
  const systemReference = _.get(report, 'id')
  const reportForm = translations(getReportForm(report))
  const reportType = _.get(report, 'typeOfReport')
  const reportStatus = getStatusLabel(_.get(report, 'status'))
  const source = _.get(report, 'source.name')
  const application = _.get(report, 'application.0.name')
  const reportSubmissionRoute = _.get(report, 'reportSubmissionRoute')
  const location = _.get(report, 'location')

  const viewUser = useCallback(() => {
    dispatch(push(`/users/${userId}`))
  }, [dispatch, userId])

  const idButton = getCopyDetailButton({ text: systemReference, code: true })
  const authorButton = hasUserManagement
    ? getTooltipDetailButton({ text: author, hoverText: 'View User', onClick: viewUser })
    : author

  const locationRender = location ? <ReportLocation location={location} reportId={reportId} /> : null

  const canViewReportInternalFields = ability.can('viewReportInternalFields', 'reportManagement')

  const rows = [
    { name: 'Creation Time', type: SectionTypes.DETAIL, value: creationTime, visible: true },
    { name: 'Updated Time', type: SectionTypes.DETAIL, value: updatedTime, visible: true },
    { name: 'Reporter', type: SectionTypes.DETAIL, value: userId ? authorButton : null, visible: true },
    { name: 'System Reference', type: SectionTypes.DETAIL, value: idButton, visible: canViewReportInternalFields },
    { name: 'Report Form', type: SectionTypes.DETAIL, value: reportForm, visible: true },
    { name: 'Report Type', type: SectionTypes.DETAIL, value: reportType, visible: true },
    { name: 'Report Status', type: SectionTypes.DETAIL, value: reportStatus, visible: canViewReportInternalFields },
    { name: 'Report Submission Route', type: SectionTypes.DETAIL, value: reportSubmissionRoute, visible: canViewReportInternalFields },
    { name: 'Source', type: SectionTypes.DETAIL, value: source, visible: canViewReportInternalFields },
    { name: 'Application', type: SectionTypes.DETAIL, value: application, visible: true },
    { name: 'Location', type: SectionTypes.DETAIL, value: locationRender, visible: true }
  ]

  const rowsToBeShown = rows.filter(record => record.visible)

  return (
    <Section
      name='Report Details'
      registerSection={registerSection}
      toggleSectionVisiblity={toggleSectionVisiblity}
      getSection={getSection}
      rows={rowsToBeShown}
      collapsible={false}
    />
  )
}

const getStatusLabel = (status) => {
  if (!status) { return }
  const statuses = {
    draft: translations('Report Management - Status DRAFT'),
    complete: translations('Report Management - Status SUBMITTED'),
    ackFailed: translations('Report Management - Status ACK FAIL'),
    ackPassed: translations('Report Management - Status ACK SUCCESS'),
    inProgress: translations('Report Management - Status IN PROGRESS')
  }

  const translated = statuses[status]
  return translated || status
}

const getReportForm = (report) => {
  const overrideFormSchemaName = _.get(report, 'overrideFormSchemaName')
  if (overrideFormSchemaName) {
    return overrideFormSchemaName
  }
  return _.get(report, 'formSchema.name')
}

export default ReportDetails
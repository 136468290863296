import { translations } from "../../../config"
import modalService from '../../../services/modalService'
import moment from 'moment'
import { push } from 'connected-react-router'
import fp from 'lodash/fp'
const formatName = ({ fields }) => fp.compose(
    (value) => value || null,
    fp.join(' '),
    fp.compact,
    fp.at(fields)
)
const formatDate = (field) => {
    const date = moment(field)
    if (!date.isValid()) {
        return null
    }
    return date.format('DD/MM/YYYY HH:mm')
}
export const handleOrigin = ({ entry }) => {
    switch (entry.origin) {
        case 'orgUser':
            return {
                subject: translations('Change Request - Subject - New Organisation Approval Request'),
                message: translations('Change Request - User Request Message', { organisationName: entry.organisationName, interpolation: { escapeValue: false } }),
                reviewButtonText: translations('Review Request'),
                reviewButtonOnClick: ({ dispatch }) => () => {
                    dispatch(push(`/users/${entry.userId}`))
                    modalService.close()
                },
                createdAt: formatDate(entry.createdAt),
                raisedBy: formatName({ fields: ['firstName', 'lastName'] })(entry),
                raisedByLink: `/users/${entry.userId}`,
                decidedBy: formatName({ fields: ['decidedByUserFirstName', 'decidedByUserLastName'] })(entry),
                decidedAt: formatDate(entry.decidedAt),
                decidedByLink: `/users/${entry.decidedByUserId}`,
                approvalStatusMessage: entry.approvalStatusMessage,
                hideApprovalEntry: !entry.decidedByUserId
            }
        default:
            return {}
    }
}

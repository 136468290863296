import _ from 'lodash'
import { LOCATION_CHANGE, replace, push } from 'connected-react-router'
import { initialize, destroy, getFormValues } from 'redux-form'
import { matchPath } from 'react-router'
import FormSchemaLibrary from '@redant/mhra-form-schema-library'

import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import { selectors as authSelectors } from '../auth'
import * as reportDetailsConstants from './constants'
import reportDetailsActions from './actions'
import { selectors as formViewSelectors } from '../formViews'
import { selectors as routingSelectors } from '../routing'

const formSchemaLibrary = new FormSchemaLibrary()

class ReportDetailsMiddleware {
  loadReportMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const result = matchPath(action.payload.location.pathname, { path: '/reports/:id', exact: true }) 
        || matchPath(action.payload.location.pathname, { path: '/report-management/:id/amend', exact: true })
      const formId = _.get(result, 'params.id')
      const isRoutingToReportDetailsView = result && formId !== 'new'
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (isRoutingToReportDetailsView && isLoggedIn) {
        dispatch(reportDetailsActions.fetchReportById({ id: formId }))
      }
    }
  }

  loadReportAndNotificationMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const result = matchPath(action.payload.location.pathname, { path: '/reports/:id/notification/:notificationId', exact: true })
      const reportId = _.get(result, 'params.id')
      const notificationId = _.get(result, 'params.notificationId')
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (result && isLoggedIn) {
        dispatch(reportDetailsActions.fetchReportFollowupNotification({ id: notificationId }))
        dispatch(reportDetailsActions.fetchReportById({ id: reportId }))
      }
    }
  }

  loadReportForAckMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const result = matchPath(action.payload.location.pathname, { path: '/reports/:reportid/acknowledgements/:id', exact: true })
      const reportId = _.get(result, 'params.reportid')
      const isLoggedIn = authSelectors.getIsLoggedIn(getState())
      if (result && isLoggedIn) {
        dispatch(reportDetailsActions.fetchReportById({ id: reportId }))
      }
    }
  }

  showModalOnSubmitFormSuccess = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === reportDetailsConstants.SUBMIT_REPORT && action.status === 'SUCCESS') {
      const { isPublic } = action
      const clearStore = () => dispatch(reportDetailsActions.resetStore())
      const destroyForm = () => dispatch(destroy(reportDetailsConstants.NEW_FORM_NAME))
      if (!isPublic) {
        dispatch(push('/report-management'))
      }

      modalService.action({
        title: translations('Report Submission Success Modal Title'),
        text: (
          isPublic
          ? translations('Report Submission Success Modal Text (Public)')
          : translations('Report Submission Success Modal Text')
        ),
        disableBackdropClick: true,
        actions: (
          isPublic
          ? [
            {
              success: true,
              text: translations('ok'),
              onClick: () => {
                clearStore()
                destroyForm()
              },
              primary: true
            }
          ]
          : [
            {
              success: true,
              text: translations('Done'),
              onClick: () => {
                clearStore()
              },
              primary: true
            },
            {
              success: true,
              text: translations('Create a new report'),
              onClick: () => {
                clearStore()
                destroyForm()
                dispatch(push(`/reports`))
              }
            }
          ]
        )
      })
    }
  }

  showModalOnSubmitFormFail = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === reportDetailsConstants.SUBMIT_REPORT && action.status === 'FAIL') {
      modalService.action({
        title: translations('Report Submission Failed'),
        text: translations('Please try again'),
        actions: [
          {
            text: translations('Okay')
          }
        ]
      })
    }
  }

  showModalOnSubmitFormDraftSuccess = ({ dispatch, getState }) => next => action => {
    next(action)
    const isUpdateSuccess = action.type === reportDetailsConstants.UPDATE_REPORT_DRAFT
    const submittedDraftAction = (
      action.type === reportDetailsConstants.SUBMIT_REPORT_DRAFT ||
      isUpdateSuccess
    )
    if (submittedDraftAction && action.status === 'SUCCESS') {
      const currentFields = getFormValues(reportDetailsConstants.NEW_FORM_NAME)(getState())
      const draftId = _.get(action, 'result.report.id')

      let actions = [
        {
          success: true,
          text: translations('Close Report'),
          onClick: () => {
            dispatch(reportDetailsActions.resetStore())
            dispatch(push('/report-management'))
          },
          primary: true
        }
      ]

      if (draftId) {
        actions.unshift({
          text: translations('Continue Editing'),
          onClick: () => dispatch(replace(`/reports/${draftId}?edit=true`))
        })
      } else if (isUpdateSuccess) {
        actions.unshift({
          text: translations('Continue Editing'),
          onClick: () => dispatch(initialize(reportDetailsConstants.NEW_FORM_NAME, currentFields))
        })
      }

      modalService.action({
        title: translations('Draft Report Saved'),
        text: translations('You can access this report through Report Management.'),
        disableBackdropClick: true,
        actions
      })
    }
  }

  showModalOnSubmitFormDraftFail = ({ dispatch, getState }) => next => action => {
    next(action)
    const submittedDraftAction = (
      action.type === reportDetailsConstants.SUBMIT_REPORT_DRAFT ||
      action.type === reportDetailsConstants.UPDATE_REPORT_DRAFT
    )
    if (submittedDraftAction && action.status === 'FAIL') {
      modalService.action({
        title: translations('Draft Submission Failed'),
        text: translations('Please try again'),
        actions: [
          {
            text: translations('Okay')
          }
        ]
      })
    }
  }

  loadXmlIntoReportFormOnLoadXmlSuccess = ({ dispatch, getState }) => next => action => {
    next(action)
    const currentLocation = routingSelectors.getLocationPathname(getState())
    const isNotBeta = matchPath(currentLocation, { path: '/reports/new', exact: true })
      || matchPath(currentLocation, { path: '/reports/:id', exact: true })
    if (action.type === reportDetailsConstants.LOAD_EXISTING_REPORT && action.status === 'SUCCESS' && isNotBeta) {
      const xmlObject = action.result
      const schemaName = formViewSelectors.getSelectedReportType(getState())
      const reportSchema = formSchemaLibrary.getDefaultView(schemaName.name)
      const schemaIdsSet = _
        .chain(reportSchema.sections)
        .map('fields')
        .mapValues(schema => Object.keys(_.groupBy(schema, 'id')))
        .reduce((memo, idArray) => {
          return memo.concat(idArray)
        })
        .value()

      const xmlObjectIdsSet = Object.keys(xmlObject)

      // Check to ensure there are no fields from the XML that our form can't handle
      const extraFields = _.difference(xmlObjectIdsSet, schemaIdsSet)
      
      let modalTitle = translations('Report Load Success')
      let modalContent = translations('Loading a report will clear the form and repopulate the fields. Would you like to continue?')
      if (extraFields.length > 0) {
        modalTitle = translations('Report Load Warning')
        modalContent = translations('Report Load Warning Content', { fields: extraFields.join('\n') })
      }

      modalService.action({
        title: modalTitle,
        text: modalContent,
        actions: [
          {
            success: true,
            text: translations('Yes'),
            onClick: () => {
              dispatch(initialize('newForm', xmlObject))
            },
            primary: true
          },
          {
            success: false,
            text: translations('No'),
            onClick: () => _.noop
          }
        ]
      })
    }
  }

  clearFormOnNavigateAwayMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const result = matchPath(action.payload.location.pathname, { path: '/reports/:id', exact: true }) 
        || matchPath(action.payload.location.pathname, { path: '/report-management/:id/amend', exact: true })

      if (!result) {
        dispatch(reportDetailsActions.resetStore())
      }
    }
  }
}

export default new ReportDetailsMiddleware()

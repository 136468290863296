import * as constants from './constants'
import reducers from './reducers'
import { UNINITIALISED } from '../../middleware/redux-promise'

const defaultState = {
  status: UNINITIALISED,
  error: undefined,
  selectedReportFollowupNotification: {
    result: undefined,
    error: undefined,
    status: undefined
  },
  xmlEditor: {
    validate: {
      status: undefined,
      error: undefined,
      validationErrors: undefined,
      message: undefined
    },
    updateXML: {
      status: undefined,
      error: undefined,
      message: undefined
    },
    reassignment: {
      status: undefined,
      error: undefined
    }
  }
}
export const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_STORE:
      return defaultState
    case constants.SUBMIT_REPORT:
      return reducers.submitReport(state, action)
    case constants.FETCH_REPORT:
      return reducers.fetchReport(state, action)
    case constants.FETCH_REPORT_FOLLOWUP_NOTIFICATION:
      return reducers.fetchReportFollowupNotification(state, action)
    case constants.REGENERATE_REPORT_BY_ID:
      return state
    case constants.VALIDATE_REPORT:
      return reducers.validateReport(state, action)
    case constants.UPDATE_REPORT_XML:
      return reducers.updateReportXML(state, action)
    case constants.RESET_VALIDATION:
      return reducers.resetValidation(state, action)
    case constants.RESET_UPDATE_XML:
      return reducers.resetUpdateXML(state, action)
    case constants.REASSIGN_REPORT_TO_ORGANISATION:
      return reducers.reassignReportToOrganisation(state, action)
    case constants.RESET_REPORT_REASSIGNMENT:
      return reducers.resetReportAssignment(state, action)
    case constants.LOAD_EXISTING_REPORT:
      return reducers.loadExistingReport(state, action)
    default:
      return state
  }
}

export default reducer

import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import LocationFreeText from './LocationFreeText'
import LocationDropdown from './LocationDropdown'
import { translation } from '../../services/translations'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const ModeButton = styled.button`
  color: #0000FF;
  background-color: inherit;
  border: 0;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  margin-left: 16px;
  width: max-content;
  &:hover, &:active, &:focus {
    color: #000099;
  }
`
const Location = (props) => {
  const {
    input: {
      value,
      onChange
    }
  } = props

  const [isFreeText, setIsFreeText] = useState(_.isArray(value))

  const switchMode = (e) => {
    if (isFreeText) {
      onChange(null)
      setIsFreeText(false)
    } else {
      onChange(['', ''])
      setIsFreeText(true)
    }
  }

  return (
    <Container>
      {
        isFreeText && (
          <LocationFreeText {...props} />
        )
      }
      {
        !isFreeText && (
          <LocationDropdown {...props} />
        )
      }
      <ModeButton
        type='button'
        onClick={switchMode}
      >
        {translation(`Location Mode Switch - ${isFreeText ? 'Free Text' : 'Dropdown'}`)}
      </ModeButton>
    </Container>
  )
}

export default Location

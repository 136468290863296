import React from 'react'
import { styled, withStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import Dropdown from '../../../../components/Fields/Dropdown'
import TabbedContent from '../../../../components/TabbedContent'

import { translations } from '../../../../config'

import style from './style'
import Heading from '../../../../components/Heading'

import { useDispatch, useSelector } from 'react-redux'
import { replace } from 'connected-react-router'
import { useLocation } from 'react-router-dom';
import { selectors as healthCheckSelectors } from '../../../../store/modules/platformHealthCheck'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { FormControl } from '@material-ui/core'
import qs from 'qs'

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

const tabs = [
  {
    label: translations('Last 24 Hours'),
    content: null,
    value: '24hr'
  },
  {
    label: translations('Last 7 Days'),
    content: null,
    value: '7d'
  },
  {
    label: translations('Last 30 Days'),
    content: null,
    value: '30d'
  }
]

const HealthCheckView = props => {
  const {
    classes,
  } = props

  const dispatch = useDispatch()
  const location = useLocation()

  const query = _.get(location, 'search')

  const selectedOrganisationId = useSelector(authSelectors.getUserSelectedOrganisationId)

  const loggedInUserOrganisationsOptions = useSelector(authSelectors.getUserOrganisationsOptions)

  const organisationId = useSelector(healthCheckSelectors.getSelectedOrganisationId) || selectedOrganisationId

  const sourceOptions = useSelector(healthCheckSelectors.getSourcesForSelectedOrganisationAsOptionsIncludedAll)
  const { timePeriod = '24hr', sourceId = 'AllSources', organisationIds = [organisationId] } = qs.parse(query, { ignoreQueryPrefix: true }) || {}
  const orgIds = typeof organisationIds === 'string' ? [organisationIds] : organisationIds

  const onChangeOrganisation = event => {
    const ids = event.target.value
    setOrganisationIds(ids)
  }

  const onChangeSource = event => {
    const sourceId = event.target.value
    setSourceId(sourceId)
  }

  const setOrganisationIds = (orgIds) => {
    const organisationIds = typeof orgIds === 'string' ? [orgIds] : orgIds
    dispatch(replace({ search: qs.stringify({ timePeriod, organisationIds, sourceId }, { arrayFormat: 'repeat' }) }))
  }

  const setTimePeriod = (e, timePeriod) => dispatch(replace({ search: qs.stringify({ timePeriod, sourceId, organisationIds }, { arrayFormat: 'repeat' }) }))

  const setSourceId = (sourceId) => {
    if (!_.isEmpty(sourceId)) {
      dispatch(replace({ search: qs.stringify({ timePeriod, sourceId, organisationIds }, { arrayFormat: 'repeat' }) }))
    }
  }

  const dropdownProps = []

  const organisationComponent =
    _.size(loggedInUserOrganisationsOptions) > 1 ?
      (
        <FormControl className={classes.dropdown}>
            <Dropdown
              label={'Organisation'}
              value={orgIds}
              options={loggedInUserOrganisationsOptions}
              onChange={onChangeOrganisation}
              passedProps={{ multiple: true }}
              multiple={true}
              noErrorTextLabel
              class={classes.organisation}
            />
        </FormControl>
      ) : null

  if (!_.isEmpty(sourceOptions)) {
    dropdownProps.push({
      options: sourceOptions,
      value: sourceId,
      onChange: onChangeSource,
      name: 'select-source',
      label: 'Report Source'
    })
  }

  if (!_.isEmpty(sourceOptions)) {
    dropdownProps.push({
      options: sourceOptions,
      value: sourceId,
      onChange: onChangeSource,
      name: 'select-source'
    })   
  }

  return (
    <>
      <Column>
        <div className={classes.filters}>
          {organisationComponent}
          {
            dropdownProps.map((someProps, i) =>
            <FormControl className={classes.dropdown}>
            <Dropdown
                  key={someProps.name}
                  {...someProps}
                  givenClasses={classes}
                  noNullOption
                />
              </FormControl>
            )
          }
        </div>
      </Column>
      <Column>
        <div className={classes.filters}>
          <TabbedContent
            tabs={tabs}
            tabValue={timePeriod}
            handleTabChange={setTimePeriod}
            noDivider
          />
        </div>
      </Column>
    </>
  )
}

export default withStyles(style)(HealthCheckView)

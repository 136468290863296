
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Heading from '../../../components/Heading'
import P from '../../../components/P'
import { FormBody, FormError } from '../../../components/Form'
import FormContentBox from '../../../components/FormContentBox'
import { translations } from '../../../config'
import styles from './style'
import Editable from '../../../components/Editable'
import { constants as platformDetailsConstants } from '../../../store/modules/platformDetails'
import PlatformOrganisations from '../PlatformOrganisations'
import PlatformReportManagement from '../PlatformReportManagement'

const PlatformDetailsForm = Editable(FormContentBox(platformDetailsConstants.EDIT_PLATFORM_FORM_NAME))
const PlatformFormSchemaFieldExclusionForm = Editable(FormContentBox(platformDetailsConstants.PLATFORM_FORM_SCHEMA_FIELD_EXCLUSIONS_FORM_NAME))

const ViewPlatformScreen = ({ classes, initialValues, onSubmit, formSchemaConfigurationInitialValues, formSchemaFieldExclusionsConfigurationSchema, onSubmitFormSchemaConfiguration, platformName, canEditPlatformDetails, schema, getPageTitle }) => {
  const pageTitle = getPageTitle(platformName || 'Platform Management')

  return (
    <div className={classes.container}>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <Fragment>
            <Heading component='h1' uppercase>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </Fragment>
        )}
      />
      <div className={classes.contentContainer}>
        <div className={classes.requiredDeactivateButtonContainer}>
          <P value={translations('required fields')} className={classes.requiredText} />
        </div>
        <FormError givenClass={classes.submitError} />
        <PlatformDetailsForm
          editable={canEditPlatformDetails}
          enableReinitialize
          initialValues={initialValues}
          givenClass={classes.formContainer}
          onSubmit={onSubmit}
          boxName={translations('Platform')}
          formId={platformDetailsConstants.EDIT_PLATFORM_FORM_NAME}>
          <FormBody
            schema={schema.schema}
            globalClasses={{
              col: classes.columnStyle,
              row: classes.rowStyle
            }}
          />
        </PlatformDetailsForm>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.requiredDeactivateButtonContainer}>
          <P value={translations('required fields')} className={classes.requiredText} />
        </div>
        <FormError givenClass={classes.submitError} />
        <PlatformOrganisations
          platformDetails={initialValues}
          classes={classes}
          editable={canEditPlatformDetails}
        />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.requiredDeactivateButtonContainer}>
          <P value={translations('required fields')} className={classes.requiredText} />
        </div>
        <FormError givenClass={classes.submitError} />
        <PlatformReportManagement
          platformDetails={initialValues}
          classes={classes}
          editable={canEditPlatformDetails}
        />
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.requiredDeactivateButtonContainer}>
          <P value={translations('required fields')} className={classes.requiredText} />
        </div>
        <FormError givenClass={classes.submitError} />
        <PlatformFormSchemaFieldExclusionForm
              editable={true}
              enableReinitialize
              initialValues={formSchemaConfigurationInitialValues}
              givenClass={classes.formContainer}
              onSubmit={onSubmitFormSchemaConfiguration}
              boxName={translations('Form Schema Configuration')}
              formId={platformDetailsConstants.PLATFORM_FORM_SCHEMA_FIELD_EXCLUSIONS_FORM_NAME}>
              <FormBody
                schema={formSchemaFieldExclusionsConfigurationSchema.schema}
                layout={['formSchemaFieldExclusions:12']}
                globalClasses={{
                  col: classes.columnStyle,
                  row: classes.rowStyle
                }}
              />
              <FormError translate />
            </PlatformFormSchemaFieldExclusionForm>
      </div>
    </div>
  )
}

ViewPlatformScreen.defaultProps = {
  initialValues: {}
}

ViewPlatformScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  platformName: PropTypes.string,
  canEditPlatformDetails: PropTypes.bool,
  schema: PropTypes.object,
  formSchemaFieldExclusionsConfiguration: PropTypes.object, 
  formSchemaConfigurationInitialValues: PropTypes.object, 
  getPageTitle: PropTypes.func.isRequired,
  onSubmitFormSchemaConfiguration: PropTypes.func.isRequired,
}

export default withStyles(styles)(ViewPlatformScreen)

import React, { Fragment, useState } from 'react'
import moment from 'moment'
import _ from 'lodash'

import { useParams } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import Hidden from '@material-ui/core/Hidden'
import { AutoTable } from '../../../../components/Table'
import KeyValueCard from '../../../../components/KeyValueCard'
import P from '../../../../components/P'
import NumberOfResultsAnnouncer from '../../../../components/NumberOfResultsAnnouncer'
import SubmittingButton from '../../../../components/SubmittingButton'

import { translations } from '../../../../config'
import {
  actions as reportConfigurationActions,
  hooks as reportConfigurationHooks
} from '../../../../store/modules/reportConfiguration'
import style from './style'
import { styled } from '@material-ui/core/styles'

import modalService from '../../../../services/modalService'
import ReportSummaryModal from '../../../ReportManagement/ReportsSearchScreen/NewReportSummaryModal'
import getFormSchemaLabel from '../../../../helpers/getFormSchemaLabel'

const _getStatusLabel = (status) => {
  const statuses = {
    draft: translations('Report Management - Status DRAFT'),
    complete: translations('Report Management - Status SUBMITTED'),
    ackFailed: translations('Report Management - Status ACK FAIL'),
    ackPassed: translations('Report Management - Status ACK SUCCESS'),
    inProgress: translations('Report Management - Status IN PROGRESS')
  }

  const translated = statuses[status]
  return translated || status
}

const NoResultsContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  textTransform: 'uppercase'
}))

const ReportsSearchTable = ({ classes }) => {
  const { id: formViewId } = useParams()
  const dispatch = useDispatch()

  const hasMore = reportConfigurationHooks.useReportsHasMore(formViewId)
  const page = reportConfigurationHooks.useReportsPage(formViewId)
  const loadMore = reportConfigurationActions.searchReportsNext({ formViewId, page })
  const isLoading = reportConfigurationHooks.useReportsLoading()
  const results = reportConfigurationHooks.useReportsById(formViewId)
  const hasSearched = reportConfigurationHooks.useReportsHaveBeenSearched()
  const error = reportConfigurationHooks.useReportHasError()

  const noResults = !isLoading && !(results || []).length

  const [openSummaryModel, setOpenSummaryModel] = useState(false)
  const [reportId, setReportId] = useState()

  const onReportClick = report => {
    setReportId(_.get(report, 'id'))
    const schemaName = _.get(report, 'formSchema.name')
    if (report.archivedAt) {
      modalService.action({
        title: `ID: ${report.id}`,
        text: `${moment(report.updatedAt).format('DD/MM/YYYY HH:mm')}
          Safety Report ID: ${report.extReportId || ''}
          Report Type: ${getFormSchemaLabel(schemaName)}`
      })
    } else {
      setOpenSummaryModel(true)
    }
  }

  let rows = []
  if (results && results.length) {
    rows = results.map(report => {
      const { id, updatedAt, messageNumber, extReportId, status, formSchema, archivedAt, user, senderId } = report
      const userName = user && user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : ''
      const reportType = _.get(formSchema, 'name')
      const onClick = () => onReportClick(report)
      const data = [
        ['Date', moment(updatedAt).format('DD/MM/YYYY HH:mm'), { xs: 1, cardDatumWidth: '50%' }],
        ['Sender', senderId, { xs: 1, cardDatumWidth: '50%' }],
        ['User', userName, { xs: 2, cardDatumWidth: '50%' }],
        ['Report Type', getFormSchemaLabel(reportType), { xs: 3, cardDatumWidth: '50%' }],
        ['Message Number', messageNumber, { xs: 2, cardDatumWidth: '100%', onClick }],
        ['Safety Report ID', extReportId, { xs: 2, cardDatumWidth: '100%' }],
        ['Status', _getStatusLabel(status), { xs: 1, cardDatumWidth: '50%' }]
      ].map(datum => {
        const [label, value, props] = datum
        return [translations(label), value, props]
      })
      return {
        key: id,
        deactivated: archivedAt,
        onClick,
        data
      }
    })
  }

  const renderError = () => {
    return <div className={classes.content}>{translations('Sorry, an error has occurred. Please try again.')}</div>
  }

  return (
    error
      ? renderError()
      : <Fragment>
        <NumberOfResultsAnnouncer count={rows.length} />
        {
          results &&
          <div className={classes.contentContainer}>
            <Hidden smDown>
              <AutoTable rows={rows} />
            </Hidden>
            <Hidden mdUp>
              {rows.map(row => {
                return <div className={classes.cardContainer} key={row.key}>
                  <KeyValueCard {..._.pick(row, ['onClick', 'data', 'deactivated'])} />
                </div>
              })}
            </Hidden>
          </div>
        }
        {noResults && hasSearched &&
          <NoResultsContainer>
            <P role='status' value={translations('No results found')} />
          </NoResultsContainer>
        }
        {
          hasMore &&
          <div className={classes.submittingButton}>
            <SubmittingButton
              onClick={() => dispatch(loadMore)}
              isSubmitting={isLoading}>
              {translations('Load more results')}
            </SubmittingButton>
          </div>
        }
        <ReportSummaryModal 
          isOpen={openSummaryModel} 
          handleClose={() => setOpenSummaryModel(false)} 
          reportId={reportId}
        />
      </Fragment>
  )
}

export default withStyles(style)(ReportsSearchTable)

import _ from 'lodash'
import { translations } from '../../../../config'
import { actions as submissionReportsActions } from '../../../../store/modules/submissionReports'
import modalService from '../../../../services/modalService'
import ReceiverModal from '../Modals/ReceiverModal'
import ErrorStatusModal from '../Modals/ErrorStatusModal'
import Send from '@material-ui/icons/Send'

export const showReceiverSummary = ({ dispatch }) => ({
  submissionReportId,
  receiverId,
  receiverStatus,
  receiverName,
  receiverStatusMessage,
  canResend = false,
  sentToReceiverSince = false,
  submission
}) => {
  const message = _.get(receiverStatusMessage, 'message')
  const details = _.get(receiverStatusMessage, 'details')
  const errors = _.get(receiverStatusMessage, 'errors', [])
  const actions = []

  if (canResend) {
    actions.push({
      label: translations('Send to receiver'),
      icon: Send,
      onClick: () => {
        modalService.open({
          component: ReceiverModal,
          smallModal: true,
          modalIndex: 1,
          receiverId,
          onSelect: async ({ receiverId: nextReceiverId, onClose }) => {
            try {
              await dispatch(submissionReportsActions.distributeSubmissions({
                resources: [{
                  receiverId: nextReceiverId,
                  submissionReportId
                }]
              }))
              onClose()
            } catch (error) {
              console.error(error)
              throw error
            }
          }
        })
      }
    })
  }

  modalService.open({
    component: ErrorStatusModal,
    fullScreen: false,
    modalTitle: translations('Case Management - Receiver Status Title'),
    message,
    status: receiverStatus,
    details,
    errors: _.flatten(errors),
    contextActions: actions,
    sentToReceiverSince,
    submission,
    receiverId,
    receiverName,
    modalIndex: 0
  })
}

export const showValidationSummary = () => ({ validationStatus, validationErrors = [] }) => {
  const errors = _.map(validationErrors, (validationErrorGroup) => {
    const validationErrorGroupErrors = _.get(validationErrorGroup, 'errors', [])
    if (_.size(validationErrorGroupErrors) > 0) {
      const groupErrors = _.map(validationErrorGroupErrors, (validationError) => {
        return _.get(validationError, 'message')
      })
      return _.flatten(groupErrors)
    } else {
      return validationErrorGroup
    }
  })

  modalService.open({
    component: ErrorStatusModal,
    fullScreen: false,
    modalTitle: translations('Case Management - Validation Status Title'),
    message: translations(`Case Management - Validation Message - ${validationStatus}`),
    status: validationStatus,
    errors: _.flatten(errors)
  })
}
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { translations } from '../../../config'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { selectors as formViewSelectors, actions as formViewActions } from '../../../store/modules/formViews'
import { authCombinedSelectors } from '../../../store/modules/combinedSelectors'
import { actions as reportDetailsActions, selectors as reportDetailsSelectors } from '../../../store/modules/reportDetails'
import { useSelector } from 'react-redux'

import BackBar from '../../../components/BackBar'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import Editable from '../../../components/Editable'
import ReportForm from './components/ReportForm/ReportForm'
import IconText from '../../../components/IconText'
import Warning from '@material-ui/icons/Warning'
import { Grid, Hidden } from '@material-ui/core'

import { goBack, push } from 'connected-react-router'
import { useDispatch } from 'react-redux'

import modalService from '../../../services/modalService'

import Button from '../../../components/Button'
import FileBrowserEnhancer from '../../../components/FileBrowserEnhancer'
import Notification from './components/Notification'

import toastService from '../../../services/toastService'

import getFormSchemaLabel from '../../../helpers/getFormSchemaLabel'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { withStyles } from '@material-ui/core/styles'

import { useExistingReportErrors } from '../hooks'

import style from './style'

const NewReportBeta = Editable(({ classes, location, match }) => {

  const dispatch = useDispatch()

  const reportId = _.get(match, 'params.id')
  const followUpNotificationId = _.get(match, 'params.notificationId')

  const reportDetailsFormSchemaName = useSelector(reportDetailsSelectors.getFormSchemaName)
  const schemaName = useSelector(formViewSelectors.getSelectedSchemaName) || reportDetailsFormSchemaName
  const formSchemaName = useSelector(formViewSelectors.getSelectedFormSchemaName) || schemaName

  const formSchemaId = useSelector(formViewSelectors.getSelectedFormSchemaId)
  const isWorkspace = useSelector(authSelectors.isLoggedIntoWorkspace)
  const selectedOrganisation = useSelector(authSelectors.getSelectedOrganisation)
  const skipReadOnlyView = useSelector(platformSelectors.getHasSkipReportReadOnlyViewEnabled)

  const showUpdates = useSelector(authCombinedSelectors.userCanSeeReportUpdates)
  const hasNewerVersion = useSelector(reportDetailsSelectors.getHasNewerVersion)
  const showVersionWarning = hasNewerVersion && showUpdates

  const title = _getTitle({ formTitle: schemaName, isNewFormMode: true })
  const pageTitle = useSelector(platformSelectors.getPageTitle)(title)
  const existingReport = useSelector(reportDetailsSelectors.getExistingReport)

  const [formState, setFormState] = useState()
  const [isLoading, setIsLoading] = useState()
  const [initialValues, setInitialValues] = useState()
  const [organisationDetails, setOrganisationDetails] = useState()
  const [productDetails, setProductDetails] = useState()
  const [isReadOnly, setIsReadOnly] = useState(false)
  const isReportEditable = useSelector(reportDetailsSelectors.getIsReportEditable)
  const [isNewReport, setIsNewReport] = useState(false)

  useExistingReportErrors(existingReport)

  useEffect(() => {
    if (reportId) {
      if (reportId !== 'new') {
        setIsReadOnly(!skipReadOnlyView)
      } else {
        setIsNewReport(true)
      }
    }
  }, [reportId])

  useEffect(() => {
    const product = _.get(location, 'state.product')
    if (product) {
      setProductDetails(product)
    }
    if (!isWorkspace && selectedOrganisation) {
      setOrganisationDetails(selectedOrganisation.details)
    }
  }, [location])

  useEffect(() => {
    // Reset selected report type
    return (() => {
      dispatch(formViewActions.selectReport(null))
    })
  }, [])

  const onFormStateChange = (state) => {
    setFormState(state)
  }

  const onSuccess = (props) => {
    const extReportId = _.get(props, 'response.report.extReportId')
    dispatch(push('/report-management'))
    toastService.action({
      type: 'success',
      message: translations('New Report Beta - Success submitting report', { reportId: extReportId }),
      autoHideDuration: 6000
    })
  }

  const onError = (error) => {
    toastService.action({
      type: 'error',
      message: translations('New Report Beta - Error submitting report', { error: error.message }),
      autoHideDuration: 6000
    })
  }

  const onSaveDraftSuccess = (props = {}) => {
    const { report: { extReportId } = {} } = props
    dispatch(push('/report-management'))
    toastService.action({
      type: 'success',
      message: translations('New Report Beta - Draft Success', { reportId: extReportId }),
      autoHideDuration: 6000
    })
  }

  const onSaveDraftError = (error) => {
    toastService.action({
      type: 'error',
      message: translations('New Report Beta - Error saving draft', { error }),
      autoHideDuration: 6000
    })
  }

  const _renderWarning = ({ classes }) => {
    return (
      <div className={classes.warningContainer}>
        <IconText iconSize={26} fontSize={16} icon={Warning} text={translations('A newer version of this report is available')} />
      </div>
    )
  }

  const onLoadExisting = (e) => {
    setIsLoading(true)
    const file = e.target.files[0]
    dispatch(reportDetailsActions.loadExistingReport({ file, formSchemaId })).then(result => {
      const messageNumber = _.get(result, 'messagenumb')
      if (_.isNil(messageNumber) || _.isEmpty(messageNumber)) {
        const omitted = _.omit(result, ['messagenumb'])
        setInitialValues(omitted)
      } else {
        setInitialValues(result)
      }
      setIsLoading(false)
    })
      .catch(error => {
        setIsLoading(false)
        toastService.action({
          type: 'error',
          message: translations('New Report Beta - Error loading existing', { error: error.message }),
          autoHideDuration: 6000
        })
      })
  }

  const loadableReports = _.includes([
    'devicesReport',
    'mhraR2Report',
    'fscaReport'
  ], formSchemaName)

  const ReportFormRender = (<ReportForm
    isWorkspace={isWorkspace}
    onFormStateChange={onFormStateChange}
    onSuccess={onSuccess}
    onError={onError}
    onSaveDraftSuccess={onSaveDraftSuccess}
    onSaveDraftError={onSaveDraftError}
    initialValues={initialValues}
    organisationDetails={organisationDetails}
    productDetails={productDetails}
    isReadOnly={isReadOnly}
    isNewReport={isNewReport}
  />)

  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar onClick={() => onBackClick({ dispatch, isDirty: _.get(formState, 'dirty'), schemaName })} />
        )}
        centerContent={(
          <>
            <Heading component='h1'>{pageTitle.title}</Heading>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </>
        )}
        rightContent={
          <>
            {loadableReports &&
              <FileBrowserEnhancer
                accept={['.xml']}
                onChange={onLoadExisting}
                renderContent={({ onClick }) => (
                  <Button onClick={onClick} raised variant='contained' style={{ marginRight: 20 }}>
                    {translations('Load Existing Report')}
                  </Button>
                )}
              />
            }
            {isReadOnly && isReportEditable && !skipReadOnlyView && (
              <Button
                onClick={() => setIsReadOnly(false)}
                raised
                variant='contained'
                buttonType='primary'
                style={{ marginRight: 20 }}
              >
                {translations('Update')}
              </Button>
            )}
          </>
        }
      />
      {showVersionWarning && !followUpNotificationId && _renderWarning({ classes })}
      {!isLoading && followUpNotificationId ? (
        <Grid container>
          <Grid item xs={12} sm={4} lg={3} className={classes.scrollableGridItem}>
            <Notification notificationId={followUpNotificationId} />
          </Grid>
          <Grid item xs={12} sm={8} lg={9} className={classes.scrollableGridItem}>
            <div className={classes.formContainer}>
              {showVersionWarning && _renderWarning({ classes })}
              {ReportFormRender}
            </div>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.formContainer}>
          {!isLoading && (ReportFormRender)}
        </div>
      )}
    </div>
  )
})


const _getTitle = ({ editing, isNewFormMode, reportStatus, formTitle }) => {
  const formType = reportStatus === 'draft' ? 'Draft' : 'Report'
  const isEditing = !!editing ? 'Edit' : 'View'
  if (isNewFormMode) {
    return getFormSchemaLabel(formTitle) || `Standard`
  }
  return translations(`${isEditing} ${formType}`)
}

const onBackClick = ({ dispatch, isDirty, schemaName }) => {
  if (!isDirty) {
    dispatch(goBack())
    return
  }

  let warningMessage
  switch (schemaName) {
    case 'devicesReport':
      warningMessage = 'Discard Devices Report Notice Text'
      break
    case 'sabreSAR':
    case 'sabreSAE':
      warningMessage = 'Discard SAE/SAR Notice Text'
      break
    case 'mhraR2Report':
    case 'mhraR3Report':
      warningMessage = 'Discard Report Notice Text'
      break
    default:
      warningMessage = 'Discard Report Notice Text Default'
  }

  modalService.action({
    title: translations('Warning'),
    text: translations(warningMessage),
    actions: [
      { text: translations('Cancel') },
      {
        text: translations('Discard changes and go back'),
        onClick: () => {
          dispatch(goBack())
        }
      }
    ]
  })
}

export default withStyles(style)(NewReportBeta)

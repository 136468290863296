import _ from 'lodash'
import moment from 'moment'
import {
  compose,
  flattenProp,
  onlyUpdateForKeys,
  renameProp,
  withHandlers,
  withPropsOnChange
} from 'recompose'
import { connect } from 'react-redux'
import { actions as submissionReportsActions, selectors as submissionReportsSelectors } from '../../../../store/modules/submissionReports'

import { translations } from '../../../../config'
import SubmissionReportsRow from './SubmissionReportsRow'
import { authCombinedSelectors } from '../../../../store/modules/combinedSelectors'

const mapStateToProps = (state, ownProps) => {
  const { submissionReportId } = ownProps
  return {
    isSelected: submissionReportsSelectors.isSelected(submissionReportId)(state),
    showAdminOptions: authCombinedSelectors.canUseAdminOptionsForReport(state),
    canResendTransmissions: authCombinedSelectors.canResendTransmissions(state)
  }
}

const enhancer = compose(
  flattenProp('entry'),
  renameProp('id', 'submissionReportId'),
  connect(mapStateToProps),
  withPropsOnChange(['createdAt', 'updatedAt', 'submissionReportId', 'submission', 'receiver', 'report'], (props) => {
    const { createdAt, updatedAt, submissionReportId, submission, receiver, report } = props
    const extReportId = _.get(submission, 'extReportId')
    return {
      receiverId: _.get(receiver, 'id'),
      receiverName: _.get(receiver, 'name', '-'),
      rowHeading: extReportId || submissionReportId,
      application: _.get(report, 'application.0.name', '-'),
      source: _.get(report, 'source.name', '-'),
      updatedAt: moment(updatedAt).format('DD/MM/YYYY HH:mm'),
      createdAt: moment(createdAt).format('DD/MM/YYYY HH:mm'),
      extReportId
    }
  }),
  withPropsOnChange(['extReportId'], (props) => {
    const { extReportId, updateFilter } = props
    return {
      updateQuery: () => updateFilter({ name: 'query', value: extReportId })
    }
  }),
  withPropsOnChange(['entry'], (props) => {
    const { entry, rowMoreOptions, submissionReportId, receiverId, receiverName, receiverStatus, submission, canResendTransmissions } = props
    return {
      onReceiver: () => rowMoreOptions.showReceiverSummary({
        submissionReportId,
        receiverId,
        receiverStatus,
        receiverStatusMessage: _.get(entry, 'receiverStatusMessage'),
        receiverName,
        canResend: _.get(entry, 'canResend', false) || canResendTransmissions,
        sentToReceiverSince: _.get(entry, 'sentToReceiverSince', false),
        submission
      })
    }
  }),
  withPropsOnChange(['entry'], (props) => {
    const { rowMoreOptions, reportId } = props
    return {
      showReportSummary: () => rowMoreOptions.showReportSummary({ reportId })
    }
  }),
  withPropsOnChange(['entry'], (props) => {
    const { entry, validationStatus, rowMoreOptions } = props
    return {
      onValidationErrors: () => rowMoreOptions.showValidationSummary({
        validationStatus,
        validationErrors: _.get(entry, 'validationErrors')
      })
    }
  }),
  withPropsOnChange(['entry'], (props) => {
    const { submissionReportId, rowMoreOptions } = props
    return {
      showManuallyProcessed: () => rowMoreOptions.showManuallyProcessed({
        submissionReportId
      })
    }
  }),
  withPropsOnChange(['entry'], (props) => {
    const { manuallyProcessedReason, manuallyProcessedByUser, createdAt, updatedAt, rowMoreOptions } = props
    return {
      showManuallyProcessedSummary: () => rowMoreOptions.showManuallyProcessedSummary({
        manuallyProcessedReason,
        manuallyProcessedByUser,
        createdAt,
        updatedAt
      })
    }
  }),
  withPropsOnChange(['entry'], (props) => {
    const { submissionReportId, rowMoreOptions } = props
    return {
      setReceiverStatusToFailed: () => rowMoreOptions.setReceiverStatusToFailed({
        submissionReportId
      })
    }
  }),
  withPropsOnChange(['showReportSummary', 'onReceiver', 'onValidationErrors', 'updateQuery', 'setReceiverStatusToFailed', 'showAdminOptions'], (props) => {
    const { showReportSummary, showManuallyProcessed, onReceiver, onValidationErrors, updateQuery, showManuallyProcessedSummary, setReceiverStatusToFailed, showAdminOptions } = props

    const menuOptions = [
      {
        label: translations('Case Management - Report summary'),
        value: 'reportSummary',
        action: showReportSummary,
        visible: true
      },
      {
        label: translations('Case Management - Receiver summary'),
        value: 'receiverStatus',
        action: onReceiver,
        visible: true
      },
      {
        label: translations('Case Management - Validation summary'),
        value: 'validationErrors',
        action: onValidationErrors,
        visible: true
      },
      {
        label: translations('Case Management - Search via case'),
        value: 'searchCase',
        action: updateQuery,
        visible: true
      },
      {
        label: translations('Case Management - Mark as manually processed'),
        value: 'manuallyProcessed',
        action: showManuallyProcessed,
        visible: true
      },
      {
        label: translations('Case Management - View manually processed reason'),
        value: 'manuallyProcessedSummary',
        action: showManuallyProcessedSummary,
        visible: true
      },
      {
        label: translations('Case Management - Mark receiver status as failed'),
        value: 'setReceiverStatusToFailed',
        action: setReceiverStatusToFailed,
        visible: showAdminOptions
      }
    ]

    return {
      menuOptions: _.filter(menuOptions, { visible: true })
    }
  }),
  withHandlers({
    toggleSelected: ({ dispatch, submissionReportId }) => () => {
      dispatch(submissionReportsActions.toggleSelected({ submissionReportId }))
    }
  }),
  onlyUpdateForKeys(['entry', 'isSelected'])
)

export default enhancer(SubmissionReportsRow)

import _ from 'lodash'
import { constants as authConstants, actions as authActions, selectors as authSelectors } from '../auth'
import { constants as userDetailsConstants } from '../userDetails'
import { constants as organisationDetailsConstants } from '../organisationDetails'
import { LOCATION_CHANGE, push } from 'connected-react-router'
import { matchPath } from 'react-router'
import modalService from '../../../services/modalService'
import { translations } from '../../../config'
import { SUCCESS, FAIL } from '../../middleware/redux-promise'
import analyticsService from '../../../services/analyticsService'
import digitalStoreSdk from '../../../digitalStoreSdk'
import { SELECT_ORGANISATION } from './constants'

class AuthMiddleware {
  updateAuthUserOnUserProfileUpdateMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    if (action.type === userDetailsConstants.UPDATE_USER && action.status === SUCCESS) {
      const currentUser = authSelectors.getCurrentUser(getState())
      const user = action.result
      if (currentUser.id === user.id) {
        dispatch(authActions.updateUser({ user }))
      }
    }
  }

  updateAuthUserOnOrganisationUpdateMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === organisationDetailsConstants.UPDATE_ORGANISATION && action.status === SUCCESS) {
      const currentUser = authSelectors.getCurrentUser(getState())
      const updatedOrganisation = action.result
      const currentUserOrganisations = _.get(currentUser, 'organisations', [])
      const hasMatchingOrganisation = !!currentUserOrganisations.find(org => org.id === updatedOrganisation.id)
      if (hasMatchingOrganisation) {
        const newUserObject = {
          ...currentUser,
          organisations: currentUserOrganisations.map(org => {
            if (org.id === updatedOrganisation.id) {
              return {
                ...org,
                ...updatedOrganisation,
                details: {
                  ...org.details,
                  ..._.get(updatedOrganisation, 'details')
                }
              }
            } else {
              return org
            }
          })
        }
        dispatch(authActions.updateUser({ user: newUserObject }))
      }
    }
  }

  accountLockedMiddleware = ({ dispatch, getState }) => next => (action) => {
    next(action)
    const isRoutingToSelectOrganisation = action.type === authConstants.LOGIN && action.status === FAIL && action.error.code === 'RA-02-02'
    if (isRoutingToSelectOrganisation) {
      const pushToForgotPassword = () => dispatch(push(`/forgot-password`))
      modalService.action({
        title: translations('Account Locked'),
        text: translations('Too many unsuccessful login attempts. Please reset your password to unlock your account'),
        actions: [
          {
            success: true,
            text: translations('Reset Password'),
            onClick: () => pushToForgotPassword(),
            primary: true
          },
          {
            text: translations('Cancel')
          }
        ]
      })
    }
  }

  closeModalsOnLogoutMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.status === SUCCESS && action.type === authConstants.LOGOUT) {
      modalService.close()
    }
  }

  resetPassword = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.status === SUCCESS && action.type === authConstants.RESET_PASSWORD) {
      analyticsService.sendCustomEvent({ type: 'resetPasswordRequest' })
    }
  }

  ssoLoginSuccessMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === LOCATION_CHANGE) {
      const res = matchPath(action.payload.location.pathname, {
        path: '/sso/success/:token',
        exact: true
      })
      if (res && res.params.token) {
        digitalStoreSdk.auth.setToken(res.params.token)
        dispatch(authActions.getSSOAuthContext())
      }
    }
  }

  editWatchListFailMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === authConstants.EDIT_WATCH_LIST && action.status === FAIL) {
      modalService.continue({
        title: translations('Edit Watch List Fail Modal Title'),
        text: _.map(action.payload, (watching, drugName) => {
          return translations(
            `Edit Watch List Fail Modal Text - ${watching ? 'Add' : 'Remove'}`,
            { drugName }
          )
        }).join('. ')
      })
    }
  }

  loadSelectedOrganisationMiddleware = ({ dispatch, getState }) => next => action => {
    next(action)
    if(action.type === SELECT_ORGANISATION && action.organisationId) {
      const organisationId = action.organisationId
      dispatch(authActions.loadSelectedOrganisation({ organisationId }))
    }
  }
}

export default new AuthMiddleware()

import React from 'react'
import { useSelector } from 'react-redux'
import { selectors as platformSelectors } from '../../../store/modules/platforms'

import NewReport from '../NewReport'
import NewReportBeta from '../NewReportBeta'

/**
 * Conditionally render a reporting form based on platform configuration
 * @component
 */
const NewReportScreen = (props) => {  
  const hasNewReportBetaEnabled = useSelector(platformSelectors.getHasNewReportBetaEnabled)
  let ReportFormComponent

  if (hasNewReportBetaEnabled) {
    ReportFormComponent = NewReportBeta
  } else {
    ReportFormComponent = NewReport
  }

  return <ReportFormComponent {...props} />
}

export default NewReportScreen

import React from 'react'
import { Provider, ReactReduxContext } from 'react-redux'
import { Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { store, persistor, history } from './store'
// import TagManager from 'react-gtm-module'
import { apps, theme, environment } from './config'
import { mergeDynamicTheme } from './helpers/dynamicThemer'
import Root from './containers/Root'
import ConfigProvider from './containers/ConfigProvider'
import FullScreenLoader from './components/FullScreenLoader/FullScreenLoader'

import digitalStoreSdk from './digitalStoreSdk'

import { AbilityContext } from './ability/ability-context'
import ability from './ability'
import { getPermissions } from './store/modules/auth/selectors'

store.subscribe(() => {
  const permissions = getPermissions(store.getState())
  ability.update(permissions)
})

const routes = []

routes.push(require('./containers/Home').routes)
routes.push(require('./containers/Webview').routes)
routes.push(require('./containers/Login').routes)
routes.push(require('./containers/SelectOrganisation').routes)
routes.push(require('./containers/Migration').routes)
routes.push(require('./containers/ForgotPassword').routes)
routes.push(require('./containers/ResetPassword').routes)
routes.push(require('./containers/ReportManagement').routes)
routes.push(require('./containers/ReportConfiguration').routes)
routes.push(require('./containers/Notifications').routes)
routes.push(require('./containers/VerifyOta').routes)
routes.push(require('./containers/EmailVerified').routes)
routes.push(require('./containers/FollowUp').routes)

if (apps.USER_MANAGEMENT) {
  routes.push(require('./containers/Users').routes)
}

if (apps.RESOURCES) {
  routes.push(require('./containers/Resources').routes)
}

if (apps.REPORTS) {
  routes.push(require('./containers/Reports').routes)
}

if (apps.ACKNOWLEDGEMENTS) {
  routes.push(require('./containers/Acknowledgements').routes)
}

if (apps.ORGANISATION_MANAGEMENT) {
  routes.push(require('./containers/Organisations').routes)
  routes.push(require('./containers/OwnOrganisation').routes)
}

if (apps.NEWS) {
  routes.push(require('./containers/News').routes)
}

if (apps.PRODUCTS) {
  routes.push(require('./containers/Products').routes)
}

if (apps.PLATFORMS) {
  routes.push(require('./containers/Platforms').routes)
}

if (apps.PHARMACEUTICAL_REFERRALS) {
  routes.push(require('./containers/PharmaceuticalReferrals').routes)
}

if (apps.COMMUNICATIONS) {
  routes.push(require('./containers/Communications').routes)
  routes.push(require('./containers/AnnouncementManagementScreen').routes)
}

if (apps.ANALYTICS) {
  routes.push(require('./containers/Analytics').routes)
}

if (apps.CASE_MANAGEMENT) {
  routes.push(require('./containers/CaseManagement').routes)
}

if (apps.CHANGE_MANAGEMENT) {
  routes.push(require('./containers/ChangeManagement').routes)
}

if (apps.REPORT_CONFIGURATION_NEXT) {
  routes.push(require('./containers/ReportConfigurationNext').routes)
}

if (apps.DEVELOPER) {
  routes.push(require('./containers/Developer').routes)
}

if (apps.INTEGRATION_MANAGEMENT) {
  routes.push(require('./containers/IntegrationManagement').routes)
}

if (apps.TERMINOLOGY) {
  routes.push(require('./containers/Terminology').routes)
}

if (apps.WEBSITE_MANAGEMENT) {
  routes.push(require('./containers/WebsiteManagement').routes)
}

if (apps.PERMISSIONS_EDITOR) {
  routes.push(require('./containers/PermissionsEditor').routes)
}

if (apps.EXPORT_MANAGEMENT) {
  routes.push(require('./containers/ExportManagement').routes)
}

routes.push(require('./containers/NotFound').routes)

// const getGTMId = () => {
//   if (window.cordova) {
//     return window.env.REACT_APP_DS_GTM_ID_MOBILE || process.env.REACT_APP_DS_GTM_ID_MOBILE || environment.REACT_APP_DS_GTM_ID_MOBILE
//   }
//   return window.env.REACT_APP_DS_GTM_ID_BROWSER || process.env.REACT_APP_DS_GTM_ID_BROWSER || environment.REACT_APP_DS_GTM_ID_MOBILE
// }

const delay = ms => new Promise(resolve => {
  setTimeout(resolve, ms)
})

const onBeforeLift = async () => {
  const { auth } = store.getState()
  if (auth && auth.user) {
    await digitalStoreSdk.auth.setCredentials(auth.user)
    await digitalStoreSdk.auth.getFreshToken()
  }
  await delay(0)
  return true
}

export default class Routes extends React.Component {
  // componentDidMount () {
  //   const gtmId = getGTMId()
  //   if (gtmId) {
  //     TagManager.initialize({ gtmId })
  //   }
  // }

  render() {
    return (
      <Provider store={store} context={ReactReduxContext}>
        <PersistGate
          loading={<FullScreenLoader />}
          persistor={persistor}
          onBeforeLift={onBeforeLift}
        >
          <ConfigProvider>
            {(dynamicTheme) => {
              const myTheme = mergeDynamicTheme({ dynamicTheme, theme })
              return (
                <MuiThemeProvider theme={createMuiTheme({ ...theme })}>
                  <ConnectedRouter history={history} context={ReactReduxContext}>
                    <AbilityContext.Provider value={ability}>
                      <Root>
                        <Switch>
                          {routes}
                        </Switch>
                      </Root>
                    </AbilityContext.Provider>
                  </ConnectedRouter>
                </MuiThemeProvider>
              )
            }}
          </ConfigProvider>
        </PersistGate>
      </Provider>
    )
  }
}

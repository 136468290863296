import { PhoneNumberUtil } from 'google-libphonenumber'
import moment from 'moment'
import _ from 'lodash'
import fp from 'lodash/fp'

import passwordPolicy from '../passwordPolicy'
import { dateFormat, timeFormat, translations, countryCodes } from '../config'

import requiredRepeatables from './requiredRepeatables'
import isUniqueReceiver from './isUniqueReceiver'

const dateAndTimeFormat = `${dateFormat}, ${timeFormat}`

const _emailRegex = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i

export const email = value => {
  const errorMessage = translations('Error - Invalid Email')
  return value && !_emailRegex.test(value)
    ? errorMessage
    : undefined
}

export const password = value => {
  const validatePassword = passwordPolicy.validatePassword(value)
  if (validatePassword instanceof Error) {
    return validatePassword.message
  }
}

export const time = value => {
  if (value && !moment(value, timeFormat, true).isValid()) {
    return translations('Error - Invalid Time')
  }
}

export const timeRequired = value => {
  if (!moment(value, timeFormat, true).isValid()) {
    return translations('Error - Invalid Time')
  }
}

export const hostname = value => {
  if (
    value &&
    !/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/i.test(
      value
    )
  ) {
    return translations('Error - Invalid Hostname Format')
  }
}

export const dateTime = value => {
  if (value && !moment(value, dateAndTimeFormat, true).isValid()) {
    return translations('Error - Invalid Date Time')
  }
}
export const dateTimeRequired = value => {
  if (!moment(value, dateAndTimeFormat, true).isValid()) {
    return translations('Error - Invalid Date Time')
  }
}

export const date = value => {
  if (value && !moment(value, dateFormat, true).isValid()) {
    return translations('Error - Invalid Date')
  }
}

export const checkoutDate = value => {
  if (value) {
    const formattedDate = moment(value, 'DD/MM/YYYY')
    if (formattedDate.isAfter(moment())) {
      return 'Date cannot be in the future'
    }
  }
}

export const dateTimeNotInPast = value => {
  if (value) {
    const givenDate = moment(value)
    if (!givenDate.isValid()) {
      return translations('Error - Invalid Date')
    }
    if (!givenDate.isAfter(moment().subtract({ hours: 1, minutes: 1 }))) {
      return translations('Error - Date In Past')
    }
  } else {
    return translations('Error - Invalid Date')
  }
}

export const dateTimeNotInFuture = (dateFormatField, dateFormat) => (
  value,
  allValues
) => {
  const compareField = _.get(allValues, dateFormatField)
  if (!compareField || !value) return null
  const format = _.invert(dateFormat)[compareField]
  const date = moment(value, format.toUpperCase())
  if (date.isAfter(moment())) {
    return translations('Error - Date In Future')
  }
}

export const dobMinAge = minAge => value => {
  const minDob = moment().subtract(minAge, 'years')
  if (moment(value, dateFormat).isAfter(minDob, 'day')) {
    return `Minimum age is ${minAge}`
  }
}

export const dateRequired = value => {
  if (!moment(value, dateFormat, true).isValid()) {
    return translations('Error - Invalid Date')
  }
}

export const passwordConfirmation = (value, form) => {
  const confirmationPassword = form.password
  if (confirmationPassword !== value) {
    return translations('Error - Passwords Do Not Match')
  }
}

export const requiredWith = ({ fieldWith, withName, fieldName }) => (value, form) => {
  const field = _.get(form, fieldWith, undefined)
  if (field && !value) {
    return (withName && fieldName)
      ? translations(`Error - Missing required with field with names`, { withName, fieldName })
      : translations(`Error - Missing required with field`, { fieldWith })
  }
  if (!field && value) {
    return (withName && fieldName)
      ? translations(`Error - Missing required with field with names`, { withName: fieldName, fieldName: withName })
      : undefined
  }
  return undefined
}

export const requiredCheckbox = value => {
  return !value ? translations('Error - Field Is Required') : undefined
}

export const required = value => {
  if(_.isNil(value) || (_.isString(value) && _.isEmpty(value.trim()))) {
    return translations('Error - Field Is Required')
  }
  return undefined
}

export const requiredWithFieldName = fieldName => value => {
  const errorMessage = translations(`Error - Fieldname Is Required`, {
    fieldName
  })
  return !value ? errorMessage : undefined
}

export const maxLength = (max, field) => value => {
  const errorMessage = translations('Error - Fieldname Max Limit', {
    field,
    max
  })
  return value && value.length > max ? errorMessage : undefined
}

export const minLength = (min, field) => value => {
  const errorMessage = translations('Error - Fieldname Min Limit', {
    field,
    min
  })
  return !_.isNil(value) && value.length < min ? errorMessage : undefined
}

export const maxNumericLength = (max, field) => value => {
  const errorMessage = translations('Error - Fieldname Max Numeric Limit', {
    field,
    max
  })
  return (value && !/^[0-9]*$/i.test(value)) || (value && value.length) > max
    ? errorMessage
    : undefined
}

export const numeric = value => {
  const errorMessage = translations('Error - Must Be A Number')
  return value && !/^[0-9]*$/i.test(value) ? errorMessage : undefined
}

export const decimalNumeric = value => {
  const errorMessage = translations('Error - Must Be A Number')
  return value && !/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i.test(value) ? errorMessage : undefined
}

export const phoneNumber = value => {
  if (value) {
    // starts with +44
    // check the next character is not a 0
    // could use String.startsWith but IE support is bad :(
    const ukCode = '+44'
    // because of the space
    if (
      value.substring(0, ukCode.length) === ukCode &&
      (value[ukCode.length] === '0' || value[ukCode.length + 1] === '0')
    ) {
      return translations('Error - Telephone Leading Zero')
    }
    try {
      PhoneNumberUtil.getInstance().parse(value)
    } catch (e) {
      return translations('Error - Invalid Telephone Number')
    }
  }
}

export const simplePhoneNumber = value => {
  return value && !/^[0-9]{0,15}$/i.test(value)
    ? translations('Error - Invalid Telephone Number')
    : undefined
}

export const phoneNumberCountryCode = value => {
  return value && !/^\+[0-9 ]*[^\s]$/i.test(value)
    ? translations('Error - Invalid Country Code')
    : undefined
}

export const countryIsoCode = value => {
  return value && !/^[\w]{2}$/i.test(value)
    ? translations('Error - Invalid Country Code')
    : undefined
}

export const url = value => {
  const pattern = new RegExp(
    '^((http|https)://www.|(http|https)://)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'
  )

  return value && !pattern.test(value)
    ? translations('Error - Invalid URL')
    : undefined
}

export const organisationRequired = value => {
  const errorText = translations('Error - Organisation Is Required')
  if (value instanceof Array) {
    return value.length === 0 ? errorText : undefined
  } else {
    return !value ? errorText : undefined
  }
}

export const ean = value => {
  const errorText = translations('Error - Invalid EAN')
  if (value && !!parseInt(value) && value.length >= 12 && value.length <= 13) {
    return undefined
  } else {
    return errorText
  }
}

export const isAcceptedDateFormat = dateFormats => value => {
  if (!value) return undefined
  const date = {
    year: value.substring(0, 4),
    month: value.substring(4, 6),
    day: value.substring(6, 8)
  }
  let error = translations('Error - Invalid Date')

  _.forEach(dateFormats, format => {
    if (format === 'YYYYMMDD' && (!!date.year && !!date.month && !!date.day)) {
      error = undefined
    }
    if (format === 'YYYYMM' && (!!date.year && !!date.month)) {
      error = undefined
    }
    if (format === 'YYYY' && !!date.year) {
      error = undefined
    }
  })

  return error
}

export const valueBlocklist = (
  blacklist = [],
  message = 'Error - Field Is Required',
  ignoreCase = false
) => value => {
  let valueToCompare = value
  if (ignoreCase) {
    blacklist = blacklist.map(val => _.toLower(val))
    valueToCompare = _.toLower(value)
  }
  return blacklist.indexOf(valueToCompare) > -1 ? translations(message) : undefined
}

const _getSingleDateFromRepeatable = (values, comparator) => {
  if (comparator === 'after') return moment.max(values)
  if (comparator === 'before') return moment.min(values)
  return null
}

const _getCompareValue = (childField, compareField, comparator) => {
  if (childField && _.isArray(compareField)) {
    let valueArray = compareField.map(val =>
      moment(val[childField], 'YYYYMMDD')
    )
    return _getSingleDateFromRepeatable(valueArray, comparator)
  } else {
    return moment(compareField, 'YYYYMMDD')
  }
}

export const isDateBeforeOrEqual = (
  compareFieldSelector,
  fieldName,
  childField
) => (value, allValues) => {
  const compareField = _.get(allValues, compareFieldSelector)
  if (!compareField) return null
  const thisValue = moment(value, 'YYYYMMDD')

  const compareValue = _getCompareValue(childField, compareField, 'after')

  if (thisValue.isAfter(compareValue)) {
    return translations('Error - Date Cannot Be After {{field}}', {
      field: translations(fieldName)
    })
  }
}

export const isDateAfterOrEqual = (
  compareFieldSelector,
  fieldName,
  childField
) => (value, allValues) => {
  const compareField = _.get(allValues, compareFieldSelector)
  if (!compareField) return null
  const thisValue = moment(value, 'YYYYMMDD')

  const compareValue = _getCompareValue(childField, compareField, 'before')
  if (thisValue.isBefore(compareValue)) {
    return translations('Error - Date Cannot Be Before {{field}}', {
      field: translations(fieldName)
    })
  }
}

export const safetyReportIdFormat = value => {
  // [2 letter country code]-[textstring]-[textstring]
  if (!value) return null
  const strings = value.split('-')
  if (
    strings.length < 3 ||
    !_.find(countryCodes, c => c.value === strings[0])
  ) {
    return translations('Error - Safety Report IDs must be in correct format')
  }
}

/**
 * Validate a string to make sure it's a valud UUID/V4
 * @type {string} Form field value
 * @returns {string|undefined} If invalid returns translated error message
 */
export const isUUID = (value) => {
  const isValid = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)
  return isValid ? undefined : translations('Error - Invalid UUID')
}

/**
 * Simple check to make sure vieWJSON is an array of objects with the correct properties
 * @type {string} Form field value
 * @returns {string|undefined} If invalid returns translated error message
 */
export const isViewJSON = (value) => {
  if (!value) return undefined

  try {
    const parsedValue = JSON.parse(value)
    const requiredKeys = ['id', 'name', 'fields']

    const isEmpty = _.isArray(parsedValue) && (_.size(parsedValue) === 0)
    if (isEmpty) {
      return translations('Error - Empty viewJSON')
    }

    const hasRequiredKeys = fp.compose(
      fp.some(_.identity),
      fp.map((section) => !requiredKeys.every(key => key in section))
    )(parsedValue)

    if (hasRequiredKeys) {
      return translations('Error - Required fields viewJSON')
    }
  } catch (error) {
    return translations('Error - Invalid viewJSON')
  }
}

/**
 * Simple check to ensure input is valid JSON
 * @type {string} Form field value
 * @returns {string|undefined} If invalid returns translated error message
 */
export const isJSON = (value) => {
  if (!value) return undefined
  try {
    JSON.parse(value)
    return
  } catch (error) {
    return translations('Error - Invalid JSON')
  }
}

/**
 * Check array has objects with the required keys to be a valid dropdown option
 * @type {string} Form field value
 * @returns {string|undefined} If invalid returns translated error message
 */
export const isDropdownOptions = (value) => {
  if (!value) return undefined
  try {
    const options = JSON.parse(value)

    const hasKeys = _.every(options, (option) => {
      return _.every(['label', 'value'], (prop) => prop in option)
    })

    return !hasKeys ? translations('Error - Invalid Dropdown options') : undefined
  } catch (error) {
    return translations('Error - Invalid Dropdown options')
  }
}

/**
 * Check input is a valid regular expression
 * @type {string} Form field value
 * @returns {string|undefined} If invalid returns translated error message
 */
export const isRegExp = (value) => {
  if (!value) return undefined
  try {
    RegExp(value, 'gi')
    return undefined
  } catch (error) {
    return translations('Error - Invalid pattern')
  }
}

export const isLessThanField = ({ field, fieldName }) => (value, formValues) => {
  const limit = _.get(formValues, field)
  if (fieldName && value && limit && !_.lt(Number(value), Number(limit))) {
    return translations('Error - Less than field', { fieldName })
  }
}

export const isGreaterThanField = ({ field, fieldName }) => (value, formValues) => {
  const limit = _.get(formValues, field)
  if (fieldName && value && limit && !_.gt(Number(value), Number(limit))) {
    return translations('Error - Greater than field', { fieldName })
  }
}

/**
 * Enables password validation if length of password is greater than zero
 * Is used in SubmissionServiceUser update as a password of length zero means do not change the password
 * @param {*} value 
 * @returns 
 */
export const passwordIfLengthGreaterThanZero = value => {
  if(value && value.length > 0) {
    return password(value)
  }
  return
}

const subdomainRegex = /^[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/
export const subdomain = value => {
  const errorMessage = translations('Error - Invalid Subdomain')
  return value && !subdomainRegex.test(value)
    ? errorMessage
    : undefined
}

export { requiredRepeatables, isUniqueReceiver }

import React, { Fragment } from 'react'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'

import { translations } from '../../../config'
import Editable from '../Editable'
import Button from '../../Button'
import BlockList, { BlockListItem } from '../../BlockList'
import P from '../../P'
import { InputLabel, styled } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import ViewIcon from '@material-ui/icons/RemoveRedEye'
import EditIcon from '@material-ui/icons/Edit'
import { colors } from '../../../config/theme'
import styles from './style'

const Message = styled('p')(() => ({
  color: colors.darkGrey,
  margin: '1em'
}))

const _getLabelForFieldType = (values, selector, schema) => {
  const selectorKey = _.get(selector, 'key') || selector
  const selectorType = _.get(selector, 'type')

  const selectedValue = values[selectorKey]
  const matchField = _.find(schema, (field) => field.id === selectorKey)

  const nullFavourLabel = _.get(matchField, 'props.nullFlavours.options')
  let nullLabel
  if (nullFavourLabel) {
    nullLabel = _.find(nullFavourLabel, (label) => (
      label.value === selectedValue
    ))
  }

  if (selectorType === 'list' && _.isArray(selectedValue)) {
    const selectorLabel = _.get(selector, 'label', 'Items')
    return `${selectorLabel}: ${_.join(selectedValue, ', ')}`
  }

  if (!matchField) {
    return selectedValue
  }

  const fieldType = matchField.field
  if (fieldType === 'Dropdown') {
    const options = _.get(matchField, 'props.options')
    if (!options) return selectedValue
    const matchedOption = _.find(options, (option) => option.value === selectedValue)
    return translations(_.get(matchedOption, 'label'))
  }

  if (nullLabel) {
    return {
      nullFlavour: true,
      value: nullLabel.label
    }
  }

  return selectedValue
}

const _getLabel = (values, labelSelector, repeatableSeparator, repeatableLabelPlaceholder, schema, i, repeatableDefaultValues) => {
  if (!labelSelector) return null
  if (_.isArray(labelSelector)) {
    const matchedLabels = _.chain(labelSelector)
      .map(selector => _getLabelForFieldType(values, selector, schema))
      .thru(value => {
        const checkNull = _.find(value, 'nullFlavour')
        if (checkNull) {
          return [checkNull.value]
        } else {
          return value
        }
      })
      .compact()
      .value()
    const joinedLabel = matchedLabels.join(repeatableSeparator || ' - ').trim()
    if (joinedLabel) return joinedLabel
    if (repeatableDefaultValues) {
      return repeatableDefaultValues[i].label
    }
    return repeatableLabelPlaceholder ? `${repeatableLabelPlaceholder} ${i + 1}` : i
  }
  return values[labelSelector]
}

const EditableRepeatable = Editable({
  input: (props) => {
    const {
      onAdd,
      displayValues = [],
      repeatableLabel,
      repeatableInformation,
      repeatableSeparator,
      repeatableLabelPlaceholder,
      repeatableDefaultValues,
      label,
      classes,
      onEdit,
      onDelete,
      schema,
      disabled = false,
      disableAdd = false,
      disableEdit = false,
      hitMaxLength,
      maxLength
    } = props
    return (
      <div>
        <InputLabel shrink>{label}</InputLabel>
        { repeatableInformation && 
          <div className={classes.repeatableInfoContainer}>
            {repeatableInformation.map(info => 
            {
              return (<p className={classes.repeatableInfo}>{info}</p>)
            })}
          </div> 
        }
        <BlockList className={classes.fieldWrap}>
          {displayValues.length
            ? _.map(displayValues, (val, i) => {
              const label = _getLabel(val, repeatableLabel, repeatableSeparator, repeatableLabelPlaceholder, schema, i, repeatableDefaultValues)
              const { disableDelete = false, error } = val
              return <BlockListItem
                key={`repetable-${i}`}
                label={`${i + 1}. ${label} `}
                error={translations(error)}
                renderRight={
                  !disabled
                    ? () => <Fragment>
                      {!disableEdit && <IconButton
                        aria-label={translations('Edit')}
                        onClick={() => onEdit(val, i)}
                        children={<EditIcon />}
                      />}
                      {!disableDelete && <IconButton
                        aria-label={translations('Delete')}
                        onClick={() => onDelete(val)}
                        children={<DeleteIcon />}
                      />}
                    </Fragment>
                    : undefined
                }
              />
            })
            : <div className={classes.emptyLabel}>{'Please Add'}</div>
          }
          {
            disabled
              ? null : disableAdd
                ? null
                : <div className={classes.buttonWrapper}>
                  <Button
                    raised
                    variant='contained'
                    onClick={onAdd}
                  >
                    {'Add'}
                  </Button>
                </div>
          }
          {hitMaxLength && (
            <Message>{translations('Max Repeatable Length - Message', { maxLength })}</Message>
          )}
        </BlockList>
      </div>
    )
  },
  display: ({ displayValues = [], onView, repeatableLabel, repeatableLabelPlaceholder, repeatableSeparator, repeatableDefaultValues, repeatableLabelCapitalizedOnDisplay, label, classes, schema, hideIfEmpty, formValuesViewable = true, renderLabelAsLink = false }) => {
    if (!displayValues.length && hideIfEmpty) return null
    return displayValues.length
      ? (
        <div>
          <P value={label} type='textButton' />
          <div className={classes.fieldWrap}>
            <BlockList>
              {displayValues.map((val, i) => {
                const label = _getLabel(val, repeatableLabel, repeatableSeparator, repeatableLabelPlaceholder, schema, i, repeatableDefaultValues)
                return (
                  <BlockListItem
                    key={`repetable-${i}`}
                    label={label}
                    renderLabelAsLink={renderLabelAsLink}
                    renderRight={() => {
                      return (
                        formValuesViewable && <IconButton aria-label={translations('View')} onClick={() => onView(val)}>
                          <ViewIcon />
                        </IconButton>
                      )
                    }}
                  />
                )
              })}
            </BlockList>
          </div>
        </div>
      )
      : (
        <div>
          <P value={label} type='textButton' />
          <P value={'-'} />
        </div>
      )
  }
})

export default withStyles(styles)(EditableRepeatable)

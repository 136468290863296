import * as validators from '../../../validators'
import { countriesFullNamesValues, countriesISO3, countryCodes, organisationTypes, timezonesAsLabelValueDropdown, translations } from '../../../config'
import { categoryOptions } from '../../../store/modules/formViews/selectors'
import _ from 'lodash'

const smallTextLengthValidator = validators.maxLength(50, 'Text')
const longTextLengthValidator = validators.maxLength(512, 'Text')

export const organisationDetails = {
  schema: [
    {
      id: 'senderorganization',
      field: 'Input',
      props: {
        label: 'Organisation name',
        name: 'senderorganization',
        validate: [smallTextLengthValidator],
        shrink: true,
        required: true
      }
    },
    {
      id: 'extOrganisationId',
      field: 'Input',
      props: {
        label: 'Organisation ID',
        name: 'extOrganisationId',
        validate: [smallTextLengthValidator],
        shrink: true,
        required: true
      }
    },
    {
      id: 'senderevid',
      field: 'Input',
      props: {
        label: 'Sender ID',
        name: 'senderevid',
        validate: [smallTextLengthValidator],
        shrink: true,
        required: true
      }
    },
    {
      id: 'messagereceiveridentifier',
      field: 'Input',
      props: {
        label: 'Receiver ID',
        name: 'messagereceiveridentifier',
        validate: [smallTextLengthValidator],
        shrink: true,
        required: false
      }
    },
    {
      id: 'sendertype',
      field: 'Dropdown',
      props: {
        label: 'Type',
        options: organisationTypes,
        name: 'sendertype',
        validate: [smallTextLengthValidator],
        shrink: true,
        required: true
      }
    },
    {
      id: 'country',
      field: 'Dropdown',
      props: {
        shrink: true,
        name: 'country',
        label: 'Country',
        autocomplete: true,
        options: countriesFullNamesValues,
        required: true
      }
    },
    {
      id: 'timeZone',
      field: 'Dropdown',
      props: {
        shrink: true,
        name: 'timeZone',
        label: 'Time Zone',
        autocomplete: true,
        options: timezonesAsLabelValueDropdown,
        required: false
      }
    }
  ]
}

export const organisationContactDetails = {
  schema: [
    {
      id: 'senderdepartment',
      field: 'Input',
      props: {
        label: 'Department',
        name: 'senderdepartment',
        required: false,
        shrink: true
      }
    },
    {
      id: 'sendertitle',
      field: 'Input',
      props: {
        label: 'Title',
        name: 'sendertitle',
        validate: [smallTextLengthValidator],
        required: false,
        shrink: true
      }
    },
    {
      id: 'sendergivename',
      field: 'Input',
      props: {
        label: 'Given Name',
        name: 'sendergivename',
        validate: [smallTextLengthValidator],
        required: false,
        shrink: true
      }
    },
    {
      id: 'sendermiddlename',
      field: 'Input',
      props: {
        label: 'Middle Name',
        name: 'sendermiddlename',
        validate: [smallTextLengthValidator],
        required: false,
        shrink: true
      }
    },
    {
      id: 'senderfamilyname',
      field: 'Input',
      props: {
        label: 'Family Name',
        name: 'senderfamilyname',
        validate: [smallTextLengthValidator],
        required: false,
        shrink: true
      }
    },
    {
      id: 'senderemailaddress',
      field: 'Email',
      props: {
        label: 'Email',
        name: 'senderemailaddress',
        validate: [validators.email, smallTextLengthValidator],
        required: true,
        shrink: true
      }
    },
    {
      id: 'senderstreetaddress',
      field: 'Input',
      props: {
        label: 'Street Address',
        name: 'senderstreetaddress',
        validate: [smallTextLengthValidator],
        required: false,
        shrink: true
      }
    },
    {
      id: 'sendercity',
      field: 'Input',
      props: {
        label: 'City',
        name: 'sendercity',
        validate: [smallTextLengthValidator],
        required: false,
        shrink: true
      }
    },
    {
      id: 'senderstate',
      field: 'Input',
      props: {
        label: 'State or Province',
        name: 'senderstate',
        validate: [smallTextLengthValidator],
        required: false,
        shrink: true
      }
    },
    {
      id: 'senderpostcode',
      field: 'Input',
      props: {
        label: 'Postcode',
        name: 'senderpostcode',
        validate: [smallTextLengthValidator],
        required: false,
        shrink: true
      }
    },
    {
      id: 'sendercountrycode',
      field: 'Dropdown',
      props: {
        shrink: true,
        name: 'sendercountrycode',
        label: 'Primary source country',
        autocomplete: true,
        options: countryCodes,
        required: false
      }
    },
    {
      id: 'sendertel',
      field: 'Input',
      props: {
        label: 'Telephone',
        name: 'sendertel',
        validate: [validators.simplePhoneNumber, validators.maxLength(10, 'Field')],
        required: false,
        shrink: true
      }
    },
    {
      id: 'sendertelextension',
      field: 'Input',
      props: {
        label: 'Telephone Extension',
        name: 'sendertelextension',
        validate: [validators.numeric],
        required: false,
        shrink: true
      }
    },
    {
      id: 'sendertelcountrycode',
      field: 'Input',
      props: {
        label: 'Telephone Country Code',
        name: 'sendertelcountrycode',
        validate: [validators.phoneNumberCountryCode],
        required: false,
        shrink: true
      }
    },
    {
      id: 'senderfax',
      field: 'Input',
      props: {
        label: 'Fax',
        name: 'senderfax',
        validate: [validators.simplePhoneNumber],
        required: false,
        shrink: true
      }
    },
    {
      id: 'senderfaxextension',
      field: 'Input',
      props: {
        label: 'Fax Extension',
        name: 'senderfaxextension',
        validate: [validators.numeric],
        required: false,
        shrink: true
      }
    },
    {
      id: 'senderfaxcountrycode',
      field: 'Input',
      props: {
        label: 'Fax Country Code',
        name: 'senderfaxcountrycode',
        validate: [validators.phoneNumberCountryCode],
        required: false,
        shrink: true
      }
    }
  ]
}

export const organisationConfiguration = {
  schema: [
    {
      id: 'themeId',
      field: 'ThemeSelect',
      props: {
        label: 'Theme',
        name: 'themeId',
        shrink: true,
        required: false
      }
    },
    {
      id: 'isGuestSubmissionAllowed',
      field: 'Checkbox',
      props: {
        label: 'Allow Guest Submission',
        name: 'isGuestSubmissionAllowed'
      }
    },
    {
      id: 'guestSubmissionUrl',
      field: 'Input',
      props: {
        label: 'Guest Submission URL',
        name: 'guestSubmissionUrl',
        shrink: true,
        disabled: true,
        passedProps: { readOnly: true }
      }
    },
    {
      id: 'isUsingMedDRATerms',
      field: 'Checkbox',
      props: {
        label: 'Use MedDRA Terms?',
        name: 'isUsingMedDRATerms'
      }
    },
    {
      id: 'meddraVersion',
      field: 'Dropdown',
      props: {
        label: 'MedDRA Version',
        name: 'meddraVersion',
        options: []
      }
    },
    {
      id: 'isMandatoryMedDRA',
      field: 'Checkbox',
      props: {
        label: 'Allow Mandatory MedDRA?',
        name: 'isMandatoryMedDRA'
      }
    },
    {
      id: 'isUsingHospitalsUKList',
      field: 'Checkbox',
      props: {
        label: 'Use UK Hospitals?',
        name: 'isUsingHospitalsUKList'
      }
    },
    {
      id: 'orgIcon',
      field: 'ImagePicker',
      props: {
        label: 'Organisation Icon',
        name: 'orgIcon',
        shrink: true,
        required: false,
        help: 'Org Icon - Help'
      }
    },
    {
      id: 'privacyPolicyLink',
      field: 'Input',
      props: {
        label: 'Privacy Policy Link',
        name: 'privacyPolicyLink',
        shrink: true,
        required: false,
        validate: [validators.url]
      }
    },
    {
      id: 'mandatoryTerms',
      field: 'Checkbox',
      props: {
        label: 'Allow Mandatory Terms Of Use?',
        name: 'mandatoryTerms'
      }
    },
    {
      id: 'termsLink',
      field: 'Input',
      props: {
        label: 'Terms Of Use Link',
        name: 'termsLink',
        shrink: true,
        required: false,
        validate: [validators.url]
      }
    }
  ]
}

export const receiverConfiguration = ({ receivers, formSchemas, sources }) => {
  return {
    layout: [
      'receiverSchemas:12'
    ],
    schema: [
      {
        id: 'receiverSchemas',
        field: 'Repeatable',
        props: {
          label: 'Report Forms and Receivers',
          name: 'receiverSchemas',
          repeatableLabel: [
            'schema',
            'receiver',
            'source',
            'condition_formViewCategory',
            {
              key: 'condition_report',
              label: 'Medicinal Product conditions',
              type: 'list'
            },
            'email'
          ],
          repeatableLabelPlaceholder: 'schema',
          repeatableValidate: validators.isUniqueReceiver,
          shrink: true,
          required: false,
          schema: [
            {
              id: 'schema',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'schema',
                label: 'Please Select Your Required Reporting Form',
                required: true,
                options: formSchemas
              }
            },
            {
              id: 'receiver',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'receiver',
                label: 'Please Select The Associated Receiver',
                required: true,
                options: receivers
              }
            },
            {
              id: 'source',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'source',
                label: 'Select the report source',
                required: false,
                options: sources
              }
            },
            {
              id: 'condition_formViewCategory',
              field: 'Dropdown',
              props: {
                shrink: true,
                name: 'condition_formViewCategory',
                label: 'Select a report configuration category',
                required: false,
                options: categoryOptions
              }
            },
            {
              id: 'email',
              field: 'Input',
              props: {
                shrink: true,
                name: 'email',
                label: 'Internal Report Notification Email Address',
                required: false,
                validate: [validators.email]
              }
            },
            {
              id: 'condition_report',
              field: 'Input',
              props: {
                shrink: true,
                type: 'hidden',
                name: 'condition_report',
                label: 'Specify the drug names',
                required: false
              }
            }
          ]
        }
      }
    ]
  }
}

export const newsConfiguration = {
  layout: [
    'isNewsAllowed:6',
    'isNewsPublic:6',
    'publicNewsUrls:12'
  ],
  schema: [
    {
      id: 'isNewsAllowed',
      field: 'Checkbox',
      props: {
        label: 'Allow News',
        name: 'isNewsAllowed'
      }
    },
    {
      id: 'isNewsPublic',
      field: 'Checkbox',
      props: {
        label: 'Make News Public',
        name: 'isNewsPublic'
      }
    },
    {
      id: 'publicNewsUrls',
      field: 'Repeatable',
      props: {
        shrink: true,
        name: 'publicNewsUrls',
        label: 'Public News RSS Feeds',
        repeatableLabel: ['publicURL'],
        repeatableSeparator: ': ',
        required: false,
        disabled: true,
        renderLabelAsLink: true,
        formValuesViewable: false,
        schema: [
          {
            id: 'isoCode',
            field: 'Input',
            props: {
              shrink: true,
              name: 'isoCode',
              label: 'ISO Code',
              required: true
            }
          },
          {
            id: 'publicURL',
            field: 'Input',
            props: {
              shrink: true,
              name: 'publicURL',
              label: 'Public URL',
              required: true
            }
          }
        ]
      }
    }
  ]
}

export const professionsConfiguration = ({ professionGroups }) => {
  return [
    {
      id: 'professions',
      field: 'Repeatable',
      layout: [
        'professions:12'
      ],
      props: {
        shrink: true,
        name: 'professions',
        label: 'Professions',
        formId: 'professions',
        repeatableLabelPlaceholder: 'Profession',
        repeatableLabel: ['name'],
        repeatableLabelCapitalizedOnDisplay: true,
        required: true,
        schema: [
          {
            id: 'name',
            field: 'Input',
            props: {
              label: 'Name',
              name: 'name',
              validate: [smallTextLengthValidator],
              shrink: true,
              required: true
            }
          },
          {
            id: 'type',
            field: 'Dropdown',
            props: {
              label: 'Type',
              name: 'type',
              options: [
                { label: 'Health Care Professional', value: 'Health Care Professional' },
                { label: 'Public', value: 'Public' },
                { label: 'Parent', value: 'Parent' },
                { label: 'Company', value: 'Company' }
              ],
              multiple: false,
              shrink: true,
              required: true
            }
          },
          {
            id: 'professionXML',
            field: 'Input',
            props: {
              label: 'XML Value',
              name: 'professionXML',
              validate: [validators.numeric],
              required: true,
              shrink: true
            }
          },
          {
            id: 'translationKey',
            field: 'Input',

            props: {
              label: 'Translation Key',
              name: 'translationKey',
              required: false,
              shrink: true,
              readOnly: true,
              disabled: true,
              disableUnderline: true
            }
          },
          {
            id: 'position',
            field: 'Input',
            props: {
              label: 'Position',
              name: 'position',
              validate: [validators.numeric],
              required: false,
              shrink: true
            }
          },
          {
            id: 'professionGroupId',
            field: 'Dropdown',
            props: {
              label: 'Group',
              name: 'professionGroupId',
              options: professionGroups,
              multiple: false,
              shrink: true,
              required: false
            }
          }
        ]
      }
    }
  ]
}

export const professionGroupsConfiguration = () => {
  return [
    {
      id: 'professionGroups',
      field: 'Repeatable',
      layout: [
        'professionGroups:12'
      ],
      props: {
        shrink: true,
        name: 'professionGroups',
        label: 'Profession Groups',
        formId: 'professionGroups',
        repeatableLabelPlaceholder: 'Profession Group',
        repeatableLabel: ['name'],
        repeatableLabelCapitalizedOnDisplay: true,
        required: true,
        schema: [
          {
            id: 'name',
            field: 'Input',
            props: {
              label: 'Name',
              name: 'name',
              validate: [smallTextLengthValidator],
              shrink: true,
              required: true
            }
          }
        ]
      }
    }
  ]
}

export const locationsConfiguration = {
  schema: ({ locationProviders, hasLocationProvidersEnabled, nextLocationSyncDate }) => {
    const schema = [
      {
        id: 'locations',
        field: 'Repeatable',
        layout: [
          'locations:12'
        ],
        props: {
          shrink: true,
          name: 'locations',
          label: 'Locations',
          formId: 'locations',
          repeatableLabelPlaceholder: 'Location',
          repeatableLabel: ['name'],
          repeatableLabelCapitalizedOnDisplay: true,
          required: true,
          schema: [
            {
              id: 'name',
              field: 'Input',
              props: {
                label: 'Name',
                name: 'name',
                validate: [longTextLengthValidator],
                shrink: true,
                required: true
              }
            }
          ]
        }
      }
    ]
    if (hasLocationProvidersEnabled) {
      schema.push({
        id: 'locationProvider',
        field: 'Dropdown',
        props: {
          shrink: true,
          name: 'locationProvider',
          label: nextLocationSyncDate
            ? translations('Location Management - Location Provider Label With Sync Date', { nextSyncDate: nextLocationSyncDate })
            : translations('Location Management - Location Provider Label'),
          options: locationProviders,
          required: false
        }
      })
    }
    return schema
  }
}

export const whoConfiguration = {
  layout: [
    'whoUsername:6',
    'whoPassword:6',
    'whoCountry:6'
  ],
  schema: [
    {
      id: 'whoUsername',
      field: 'Input',
      props: {
        label: 'Username',
        name: 'whoUsername',
        shrink: true,
        required: true
      }
    },
    {
      id: 'whoPassword',
      field: 'Input',
      props: {
        label: 'Password',
        name: 'whoPassword',
        type: 'password',
        shrink: true,
        required: true
      }
    },
    {
      id: 'whoCountry',
      field: 'Dropdown',
      props: {
        shrink: true,
        name: 'whoCountry',
        label: 'Country Code',
        autocomplete: true,
        options: countriesISO3,
        required: true
      }
    }
  ]
}

export const acknowledgementEmailsConfiguration = {
  layout: [
    'acknowledgementEmailSupportEmail:6',
    'acknowledgementEmailSupportTelephone:6',
    'acknowledgementEmailSupportLink:6'
  ],
  schema: [
    {
      id: 'acknowledgementEmailSupportEmail',
      field: 'Email',
      props: {
        label: 'Support email',
        name: 'acknowledgementEmailSupportEmail',
        validate: [validators.email, smallTextLengthValidator],
        shrink: true,
        required: false
      }
    },
    {
      id: 'acknowledgementEmailSupportTelephone',
      field: 'Input',
      props: {
        label: 'Support telephone',
        name: 'acknowledgementEmailSupportTelephone',
        validate: [validators.simplePhoneNumber],
        shrink: true,
        required: false
      }
    },
    {
      id: 'acknowledgementEmailSupportLink',
      field: 'Input',
      props: {
        label: 'Support website',
        name: 'acknowledgementEmailSupportLink',
        validate: [validators.url],
        shrink: true,
        required: false
      }
    }
  ]
}

export const ssoConfiguration = {
  layout: [
    'isSSOEnabled:6',
    'idpMetadata:12',
    'spMetadata:12'
  ],
  schema: [{
    id: 'isSSOEnabled',
    field: 'Checkbox',
    props: {
      label: 'SSO Enabled',
      name: 'isSSOEnabled'
    }
  }, {
    id: 'idpMetadata',
    field: 'Metadata',
    props: {
      label: 'Identity Provider Metadata',
      name: 'idpMetadata',
      multiline: true
    }
  }, {
    id: 'spMetadata',
    field: 'Button',
    props: {
      label: 'Download Service Provider Metadata',
      buttonLabel: 'Download',
      name: 'spMetadata',
      validationFields: [
        'isSSOEnabled',
        'idpMetadata'
      ],
      onClick: (value) => {
        window.open(value, '_blank')
      }
    }
  }]
}

export const communicationsConfiguration = {
  layout: [
    'communications_fromName:6',
    'communications_acknowledgeReports:6'
  ],
  schema: [
    {
      id: 'communications_fromName',
      field: 'Input',
      props: {
        label: 'From name',
        name: 'communications_fromName',
        help: 'Org From Name - Help',
        shrink: true
      }
    },
    {
      id: 'communications_acknowledgeReports',
      field: 'Checkbox',
      props: {
        label: 'Send Acknowledgment Emails',
        name: 'communications_acknowledgeReports',
        help: 'Org Acknowledge Reports - Help'
      }
    },
    {
      id: 'communications_internalReportNotificationEmail',
      field: 'Input',
      props: {
        label: 'Internal Report Notification Email Address',
        name: 'communications_internalReportNotificationEmail',
        help: 'Org Internal Report Notification Email - Help',
        validate: [validators.email, smallTextLengthValidator],
        shrink: true
      }
    },
  ]
}

const reportingOrganisationContactDetailsConfiguration = {
  schema: [
    {
      id: 'name',
      field: 'Input',
      props: {
        label: 'Organisation name',
        name: 'name',
        shrink: true,
        required: true
      }
    },
    {
      id: 'addressLine1',
      field: 'Input',
      props: {
        label: 'Address line 1',
        name: 'addressLine1',
        shrink: true,
        required: true,
        validate: [validators.maxLength(160, 'Address line 1')]
      }
    },
    {
      id: 'addressLine2',
      field: 'Input',
      props: {
        label: 'Address line 2',
        name: 'addressLine2',
        shrink: true,
        required: true,
        validate: [validators.maxLength(160, 'Address line 2')]
      }
    },
    {
      id: 'town',
      field: 'Input',
      props: {
        label: 'Town',
        name: 'town',
        shrink: true,
        required: true,
        validate: [validators.maxLength(50, 'Town')]
      }
    },
    {
      id: 'county',
      field: 'Input',
      props: {
        label: 'County',
        name: 'county',
        shrink: true,
        required: true,
        validate: [validators.maxLength(50, 'County')]
      }
    },
    {
      id: 'postCode',
      field: 'Input',
      props: {
        label: 'Post Code',
        name: 'postCode',
        shrink: true,
        required: true
      }
    },
    {
      id: 'country',
      field: 'Dropdown',
      props: {
        shrink: true,
        name: 'country',
        label: 'Country',
        autocomplete: true,
        options: countriesFullNamesValues,
        required: true
      }
    },
    {
      id: 'telephoneNumber',
      field: 'Input',
      props: {
        label: 'Telephone Number',
        name: 'telephoneNumber',
        shrink: true,
        required: true
      }
    },
    {
      id: 'nhsnbsRegion',
      field: 'TerminologyLookUpInput',
      props: {
        lookupOptions: {
          listName: 'NBS_REGION'
        },
        shrink: true,
        name: 'nhsnbsRegion',
        label: 'NHS / NBS Region',
        required: true
      }
    },
    {
      id: 'organisationType',
      field: 'TerminologyLookUpInput',
      props: {
        lookupOptions: {
          listName: 'BLOOD_ORGANISATION_TYPE'
        },
        shrink: true,
        name: 'organisationType',
        label: 'Blood Organisation Type',
        required: true
      }
    }
  ]
}

export const reportingOrganisationContactDetailsFormSchema = (formValues) => {
  let { schema } = reportingOrganisationContactDetailsConfiguration

  if (_.lowerCase(formValues?.organisationType) === 'other') {
    schema = [
      ...schema,
      ...[{
        id: 'organisationTypeOther',
        field: 'Input',
        props: {
          label: 'Blood Organisation Type other',
          name: 'organisationTypeOther',
          required: true,
        }
      }]
    ]
  }

  if (formValues?.orgCode) {
    schema = [
      ...schema,
      ...[{
        id: 'orgCode',
        field: 'Input',
        props: {
          label: translations('View Organisation - Organisation Code'),
          name: 'orgCode',
          readOnly: true,
          disabled: true
        }
      }]
    ]
  }

  return { schema }
}

export const sabreReportingOrganisationContactDetailsFormName = (formValues) => {
  const userContactDetails = [{
    id: 'firstName',
    field: 'Input',
    props: {
      label: translations('First Name'),
      name: 'firstName',
      validate: [smallTextLengthValidator],
      required: true
    }
  }, {
    id: 'lastName',
    field: 'Input',
    props: {
      label: translations('Last Name'),
      name: 'lastName',
      validate: [smallTextLengthValidator],
      required: true
    }
  }, {
    id: 'email',
    field: 'Email',
    props: {
      label: translations('Email'),
      name: 'email',
      validate: [validators.email, smallTextLengthValidator],
      required: true,
      shrink: true
    }
  }]

  const newSchema = [
    ...userContactDetails,
    ...reportingOrganisationContactDetailsFormSchema(formValues).schema
  ]

  return {
    schema: newSchema
  }
}

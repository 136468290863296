import _ from 'lodash'
import digitalStoreSdk from '../../../digitalStoreSdk'

import * as constants from './constants'
import { selectors as authSelectors } from '../auth'

const _formatPlatformForDB = (fields) => {
  return {
    name: fields.name,
    domain: fields.domain,
    appId: fields.appId,
    themeId: fields.themeId,
    organisations: fields.organisations,
    formSchemaFieldExclusions: fields.formSchemaFieldExclusions,
    config: fields.config,
    details: _.pick(fields, [
      'supportEmail',
      'supportTelephone',
      'privacyPolicyLink',
      'notificationRecipientsReportFollowUp',
      'edqmReportTarget'
    ]),
    permissions: _.pick(fields, [
      'postReport',
      'loadReport',
      'createAck',
      'watchList',
      'publicUserRegistration',
      'companyAccountRequest',
      'pharmaceuticalReferrals',
      'reports',
      'products',
      'analytics',
      'updateReport',
      'registerEmailConfirmation',
      'caseManagement',
      'denyPublicUpdates',
      'postMultipleReports',
      'advancedPostReport',
      'developer',
      'reportConfigurationBeta',
      'communications',
      'announcementManagement',
      'moreRegistration',
      'enableWorkspaces',
      'requireApprovalForJoiningOrganisations',
      'changeManagement',
      'enableNewReportBeta',
      'allowInvitingUsersToOrganisations',
      'integrationManagement',
      'enableMultipleApplications',
      'terminology',
      'reportAuditing',
      'websiteManagement',
      'enableEmailNotificationRecipient',
      'enableReportReassignment',
      'acknowledgmentSchemaName',
      'enableGuestReportEmailRegistration',
      'enableNewTranslationsManagement',
      'enableLocationsManagement',
      'disableNotificationEmails',
      'enableGuestUsersFollowUps',
      'enablePermissionsEditor',
      'enableActionsModalAfterUserCreation',
      'enableBackgroundAnalyticsExports',
      'enableExportManagement'
    ])
  }
}

class PlatformDetailsActions {
  fetchPlatform = ({ id }) => ({
    type: constants.FETCH_PLATFORM,
    promise: () => digitalStoreSdk.platforms.fetchPlatform({ id })
  })
  createPlatform = ({ fields }) => {
    return {
      type: constants.CREATE_PLATFORM,
      promise: (dispatch, getState) => {
        let newPlatform = _formatPlatformForDB(fields)
        return digitalStoreSdk.platforms.createPlatform(newPlatform)
      }
    }
  }
  updatePlatform = ({ id, params }) => ({
    type: constants.UPDATE_PLATFORM,
    payload: { id, ...params },
    promise: () => {
      let updatePlatform = _formatPlatformForDB(params)
      return digitalStoreSdk.platforms.updatePlatform({ id, fields: updatePlatform })
    }
  })
  resetStore = () => ({
    type: constants.RESET_STORE
  })
}

export default new PlatformDetailsActions()

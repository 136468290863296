import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { styled } from '@material-ui/core/styles'
import { push } from 'connected-react-router'

import { translations } from '../../../config'

import modalService from '../../../services/modalService'

import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { selectors as organisationDetailsSelectors, actions as organisationDetailsActions } from '../../../store/modules/organisationDetails'
import { actions as translationActions, selectors as translationSelectors } from '../../../store/modules/translations'
import { selectors as languagesSelectors } from '../../../store/modules/languages'

import SubHeader from '../../../components/SubHeader'
import BackBar from '../../../components/BackBar'
import Heading from '../../../components/Heading'
import Container from '../../../components/Container'
import Button from '../../../components/Button'
import VisuallyHidden from '../../../components/VisuallyHidden'

import SearchBar from './components/SearchBar'
import Table from './components/Table'
import SaveChangesModal from './components/SaveTranslationChangesModal'
import toastService from '../../../services/toastService'
import MoreMenu from '../../../components/MoreMenu'
import _ from 'lodash'
import TableFilters from './components/TableFilters'
import AddLanguageModal from './components/AddLanguageModal'

const ROWS_PER_PAGE = 50

const HeaderMenuContainer = styled(Button)({
  margin: '10px'
})

const TableContainer = styled(Container)({
  'padding-bottom': '50px'
})

const ManageTranslationsScreen = () => {
  const dispatch = useDispatch()
  const [canSave, setCanSave] = useState(false)
  const [filters, setFilters] = useState({})
  const [lastParams, setLastParams] = useState()
  const [showSource, setShowSource] = useState(false)

  const updateFilters = (newFilter) => {
    const newFilters = {
      ...filters,
      ...newFilter
    }
    const nonEmptyFilters = _.omitBy(newFilters, _.isNil || _.isEmpty)
    setFilters(nonEmptyFilters)
  }

  const handleSetShowSource = () => {
    setShowSource(!showSource)
  }

  const interfaceEnabled = useSelector(platformSelectors.getHasTranslationManagementInterfaceEnabled)
  const pageTitle = useSelector(state => platformSelectors.getPageTitle(state)('Manage Translations'))
  const organisationLanguages = useSelector(translationSelectors.getCurrentOrganisationLanguagesAsOptions)
  const languages = useSelector(languagesSelectors.getLanguages)
  const organisationId = useSelector(organisationDetailsSelectors.getCurrentOrganisationId)
  const searchedTranslations = useSelector(translationSelectors.getSearchedTranslations)
  const totalTranslations = useSelector(translationSelectors.getTotalTranslations)
  const offset = useSelector(translationSelectors.getOffset)
  const searchValue = useSelector(translationSelectors.getSearchValue)

  const selectedLanguages = filters.languages
  const visibleLanguages = _.isEmpty(selectedLanguages)
    ? organisationLanguages
    : _.filter(organisationLanguages, language => _.includes(selectedLanguages, language.value))

  useEffect(() => {
    if (organisationId) {
      dispatch(translationActions.findOrganisationLanguages({ organisationId }))
    }
  }, [])

  useEffect(() => {
    if (organisationId && (!searchValue || searchValue.length >= 3) && !_.isEqual({ offset, searchValue, filters }, lastParams)) {
      dispatch(translationActions.searchTranslationsForOrganisation({ organisationId, filters }))
      setLastParams({ offset, searchValue, filters })
    }
  }, [offset, organisationId, searchValue, filters])

  useEffect(() => {
    dispatch(translationActions.setOffset({ offset: 0 }))
  }, [])


  const onTablePageChange = useCallback((pageNumber) => {
    dispatch(translationActions.setOffset({ offset: pageNumber * ROWS_PER_PAGE }))
  }, [])

  const onSearchChanged = useCallback((searchValue) => {
    dispatch(translationActions.setSearchValue({ searchValue }))
  }, [dispatch])

  const onViewUploadBulkTranslations = useCallback(() => {
    const navigateToUpload = () => dispatch(push(`/organisations/${organisationId}/translations/upload`))

    if (canSave) {
      modalService.action({
        title: translations('Manage Translations - Confirm Lost Changes Modal Title'),
        text: translations('Manage Translations - Confirm Lost Changes Modal Description'),
        actions: [
          {
            success: false,
            text: translations('Manage Translations - Confirm Lost Changes Modal Continue Button'),
            primary: false,
            onClick: navigateToUpload
          },
          {
            success: true,
            text: translations('Manage Translations - Confirm Lost Changes Modal Cancel Button'),
            primary: true,
            onClick: () => modalService.close()
          }
        ]
      })
    } else {
      navigateToUpload()
    }
  }, [dispatch, organisationId, canSave])

  const onViewTranslationHistory = useCallback(() => {
    const navigateToHistory = () => dispatch(push(`/organisations/${organisationId}/translations/history`))
    navigateToHistory()
  }, [dispatch, organisationId])

  const onViewTranslationDifferences = useCallback(() => {
    dispatch(translationActions.getTranslationDifferences({ organisationId, translations: [] })).then((translationChanges) => {
      if (translationChanges.length) {
        modalService.open({
          component: SaveChangesModal,
          largeModal: true,
          changes: Object.values(translationChanges),
          onSubmitTranslationChanges: (changes) => {
            dispatch(translationActions.uploadTranslations({ organisationId, changes }))
            modalService.close()
          },
          onClose: () => modalService.close()
        })
      } else {
        toastService.action({
          type: 'success',
          message: translations('Manage Translations - No Updates'),
          autoHideDuration: 3000
        })
      }
    })

    toastService.action({
      type: 'success',
      message: translations('Manage Translations - Updates loading'),
      autoHideDuration: 3000
    })
  }, [dispatch, organisationId])

  const onCreateKey = useCallback(async (key) => {
    try {
      await dispatch(translationActions.createNewTranslationKeyForOrganisation({ organisationId, key }))
    } catch (error) {
      toastService.action({
        type: 'error',
        message: error.message,
        autoHideDuration: 3000
      })
    }
  }, [dispatch, organisationId])

  const onSubmitTranslationChanges = useCallback(async (changes) => {
    if (organisationId) {
      try {
        await dispatch(translationActions.saveTranslationChangesForOrganisation({ organisationId, changes }))
        toastService.action({
          type: 'success',
          message: translations('Manage Translations - Translation Changes Success'),
          autoHideDuration: 6000
        })
      } catch (error) {
        toastService.action({
          type: 'error',
          message: translations('Manage Translations - Translation Changes Failure'),
          autoHideDuration: 6000
        })
      }
    }
  }, [dispatch, organisationId])

  const onSubmitTranslationDelete = useCallback(async (changes) => {
    if (organisationId) {
      const ids = changes.map((record) => { return record.id })

      const result = await dispatch(translationActions.deleteTranslationsForOrganisation({ organisationId, ids }))

      if (result.success) {
        toastService.action({
          type: 'success',
          message: translations('Manage Translations - Keys Deletion Success'),
          autoHideDuration: 6000
        })
      } else {
        toastService.action({
          type: 'error',
          message: translations('Manage Translations - Keys Deletion Failed'),
          autoHideDuration: 6000
        })
      }
    }
  }, [dispatch, organisationId])

  const onDeleteLanguage = useCallback((languageId) => {
    if (organisationId) {
      dispatch(translationActions.deleteLanguagesTranslationsForOrganisation({ organisationId, languageId })).then(() => {
        dispatch(translationActions.findOrganisationLanguages({ organisationId }))
      })
    }
  }, [organisationId])

  const showAddLanguageModal = useCallback(() => {
    modalService.open({
      overflowContent: true,
      component: AddLanguageModal,
      languages,
      onSuccess: () => {
        modalService.close()
        if (organisationId) {
          dispatch(translationActions.findOrganisationLanguages({ organisationId }))
        }
      }
    })
  }, [languages, organisationId])

  return (
    <div>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <>
            <SearchBar value={searchValue} onSearchChanged={onSearchChanged} />
            <VisuallyHidden>
              <Heading component='h1' uppercase>{pageTitle.title}</Heading>
            </VisuallyHidden>
            <Helmet>
              <title>{pageTitle.titleWithName}</title>
            </Helmet>
          </>
        )}
        rightContent={(
          <HeaderMenuContainer>
            <MoreMenu options={[
              {
                label: translations('Manage Translations - Upload Button'),
                onClick: onViewUploadBulkTranslations
              },
              {
                label: translations('Manage Translations - Check For Updates Button'),
                onClick: onViewTranslationDifferences
              },
              {
                label: translations('Manage Translations - Amend History'),
                onClick: onViewTranslationHistory
              }
            ]} />
          </HeaderMenuContainer>
        )}
      />
      <TableContainer withMarginTop>
        <TableFilters updateFilters={updateFilters} setShowSource={handleSetShowSource} showSource={showSource} />
        {interfaceEnabled && searchedTranslations && organisationLanguages.length > 0 && languages.length > 0 && (
          <Table
            onTablePageChange={onTablePageChange}
            searchedTranslations={searchedTranslations}
            organisationLanguages={visibleLanguages}
            languages={languages}
            totalTranslationCount={totalTranslations}
            rowsPerPage={ROWS_PER_PAGE}
            onCreateKey={onCreateKey}
            onSubmitTranslationDelete={onSubmitTranslationDelete}
            onSubmitTranslationChanges={onSubmitTranslationChanges}
            onDeleteLanguage={onDeleteLanguage}
            showAddLanguageModal={showAddLanguageModal}
            setCanSave={setCanSave}
            canSave={canSave}
            showSource={showSource}
          />
        )}
      </TableContainer>
    </div>
  )
}

export default ManageTranslationsScreen

import { getFormValues } from 'redux-form'
import { utilities } from '@redant/mhra-form-schema-library'

import { selectors as authSelectors } from '../auth'
import { constants as reportDetailsConstants, selectors as reportDetailsSelectors } from '../reportDetails'
import digitalStoreSdk from '../../../digitalStoreSdk'
import ReportUploadService from '../../../services/reportUploadService'

import _ from 'lodash'
import { getRegisteredFormValuesByFormName } from '../redux-forms/selectors'

class ReportDetailsActions {
  fetchReportById = ({ id, attributes }) => ({
    type: reportDetailsConstants.FETCH_REPORT,
    promise: (dispatch, getState) => digitalStoreSdk.reports.fetchReportById({ id, attributes, includeApplications: true, includeSubmissionReports: true })
  })

  submitReport = ({ fields, organisationId, isPublic, formViewVersionId, formSchemaId, attachmentFields }) => ({
    type: reportDetailsConstants.SUBMIT_REPORT,
    isPublic,
    promise: (dispatch, getState) => {
      const state = getState()
      const status = utilities.getReportStatus(fields)
      return digitalStoreSdk.reports.sendReport({
        userId: authSelectors.getActiveUserId(state),
        organisationId: organisationId || authSelectors.getUserSelectedOrganisationId(state),
        reportJSON: fields,
        formViewVersionId,
        formSchemaId,
        status,
        source: 'evwebcreateform',
        sourceId: 'd97b3f5a-04ee-4ed5-a527-d27b1ce38657',
        attachmentFields
      })
    }
  })

  updateReport = ({ formViewVersionId, formSchemaId, attachmentFields }) => ({
    type: reportDetailsConstants.SUBMIT_REPORT,
    promise: (dispatch, getState) => {
      const state = getState()
      const fields = getRegisteredFormValuesByFormName(reportDetailsConstants.NEW_FORM_NAME)(state)
      const reportId = reportDetailsSelectors.getReportId(state)
      const reportSource = _.get(reportDetailsSelectors.getReport(state), 'details.source')
      const userId = authSelectors.getActiveUserId(state)
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      return digitalStoreSdk.reports.updateReport({ reportId, userId, organisationId, formViewVersionId, formSchemaId, reportJSON: fields, status: 'complete', source: reportSource, attachmentFields })
    }
  })

  submitReportDraft = ({ formViewVersionId, formSchemaId, attachmentFields }) => ({
    type: reportDetailsConstants.SUBMIT_REPORT_DRAFT,
    promise: (dispatch, getState) => {
      const fields = getRegisteredFormValuesByFormName(reportDetailsConstants.NEW_FORM_NAME)(getState())
      const state = getState()
      const userId = authSelectors.getActiveUserId(state)
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      return digitalStoreSdk.reports.sendReport({ userId, organisationId, reportJSON: fields, status: 'draft', formViewVersionId, formSchemaId, attachmentFields })
    }
  })

  updateReportDraft = ({ formViewVersionId, formSchemaId, attachmentFields }) => ({
    type: reportDetailsConstants.UPDATE_REPORT_DRAFT,
    promise: (dispatch, getState) => {
      const state = getState()
      const fields = getRegisteredFormValuesByFormName(reportDetailsConstants.NEW_FORM_NAME)(state)
      const reportId = reportDetailsSelectors.getReportId(state)
      const userId = authSelectors.getActiveUserId(state)
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      return digitalStoreSdk.reports.updateReport({ reportId, userId, organisationId, formViewVersionId, formSchemaId, reportJSON: fields, status: 'draft', attachmentFields })
    }
  })

  loadExistingReport = ({ file, formSchemaId }) => ({
    type: reportDetailsConstants.LOAD_EXISTING_REPORT,
    promise: (dispatch, getState) => {
      const state = getState()
      const userId = authSelectors.getActiveUserId(state)
      const organisationId = authSelectors.getUserSelectedOrganisationId(state)
      return ReportUploadService.load({ file, userId, organisationId, formSchemaId })
    }
  })

  fetchReportFollowupNotification = ({ id }) => ({
    type: reportDetailsConstants.FETCH_REPORT_FOLLOWUP_NOTIFICATION,
    promise: () => digitalStoreSdk.notifications.fetchNotificationById({ id })
  })

  resetReportFollowUpNotification = () => ({
    type: reportDetailsConstants.RESET_REPORT_FOLLOWUP_NOTIFICATION
  })

  regenerateReportById = ({ id }) => ({
    type: reportDetailsConstants.REGENERATE_REPORT_BY_ID,
    promise: async dispatch => {
      await digitalStoreSdk.reports.regenerateReportById({ id })
      await dispatch(this.fetchReportById({ id }))
    }
  })

  validateReport = ({ reportType, report, platformId }) => ({
    type: reportDetailsConstants.VALIDATE_REPORT,
    promise: () => digitalStoreSdk.reports.validateReport({
      reportType,
      report,
      platformId
    })
  })

  updateReportXML = ({ reportId, reportXML }) => ({
    type: reportDetailsConstants.UPDATE_REPORT_XML,
    promise: () => digitalStoreSdk.reports.updateReportXML({
      reportId,
      reportXML
    })
  })

  reassignReportToOrganisation = ({ reportId, organisationId }) => {
    return ({
      type: reportDetailsConstants.REASSIGN_REPORT_TO_ORGANISATION,
      promise: () => digitalStoreSdk.reports.reassignReportToOrganisation({
        reportId,
        organisationId
      })
    })
  }

  resetValidation = () => ({
    type: reportDetailsConstants.RESET_VALIDATION
  })

  resetUpdateXML = () => ({
    type: reportDetailsConstants.RESET_UPDATE_XML
  })

  resetReportReassignment = () => ({
    type: reportDetailsConstants.RESET_REPORT_REASSIGNMENT
  })

  resetStore = () => ({
    type: reportDetailsConstants.RESET_STORE
  })
}

export default new ReportDetailsActions()

import React, { useEffect, useState } from 'react'
import { utilities } from '@redant/mhra-form-schema-library'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { FormSubmit, FormBody, FormError, FormSection } from '../../../components/Form'
import style from './style'
import { SubmissionError } from 'redux-form'
import SubHeader from '../../../components/SubHeader'
import Heading from '../../../components/Heading'
import FullScreenLoader from '../../../components/FullScreenLoader'
import { themeDetails } from '../formSchemas/themeDetailsSchema'
import { constants as themeDetailsConstants } from '../../../store/modules/themeDetails'
import { translations } from '../../../config'

const ThemeDetailsModal = ({
    handleSubmit,
    onSubmit,
    submitting,
    invalid,
    error,
    initialValues,
    editing = true,
    givenClass,
    classes,
    closeModal,
    editMode,
    formValues,
    changeField,
    formName
}) => {
  const [schema, setSchema] = useState()
  const [previousFormValues, setPreviousFormValues] = useState(formValues)

  useEffect(() => {
    if (!_.isEqual(previousFormValues, formValues)) {
      setPreviousFormValues(formValues)
    }
  }, [formValues])

  useEffect(() => {
    const parser = utilities.parseDynamicSchema(formValues, previousFormValues, changeField, themeDetailsConstants.THEME_FORM_NAME, null, formName)

    const parsedSchema = parser(themeDetails.theme.schema)
    setSchema(parsedSchema)
  }, [formValues, previousFormValues])

  const submit = handleSubmit((...args) => {
    const submitResult = onSubmit(...args)
    if (submitResult && submitResult.then) {
      return submitResult
        .catch(error => {
          throw new SubmissionError({ _error: error.message })
        })
    } else {
      return submitResult
    }
  })
  return (
    <div className={classes.container}>
      <SubHeader
        centerContent={(
          <Heading component='h1'>{editMode ? 'Edit Theme' : 'Create Theme'}</Heading>
        )}
        rightContent={(
          <div>
            <IconButton aria-label={translations('Close')} onClick={() => closeModal()}><CloseIcon /></IconButton>
          </div>
        )}
      />
      <form onSubmit={submit} className={`${classes.root} ${givenClass}`} style={{ width: '100%' }}>
        <FormBody>
        {schema && FormSection({ title: translations('Theme'), schema })}
        {formValues.enableLegacyTheming && FormSection({ title: translations('Legacy'), schema: themeDetails.legacy.schema })}
        </FormBody>
        <FormError error={error} />
        {editing && <FormSubmit big invalid={invalid} submitting={submitting} fullWidth noContainer className={classes.footerButton}>{editMode ? 'Update' : 'Add'}</FormSubmit>}
      </form>
    </div>
  )
}

export default FullScreenLoader(withStyles(style)(ThemeDetailsModal))

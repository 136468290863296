const style = theme => ({
  centerContent: {
    marginTop: '30px'
  },
  dropdown: {
    width: '15em',
    margin: '0 0.5em',
    textAlign: 'left',
    height: '80px',

    '& .MuiAutocomplete-root': {
      '@media (max-width: 599px)': {
        paddingTop: 0
      }
    },

    '& .MuiAutocomplete-input::placeholder': {
      opacity: 1
    }
  },
  field: {
    width: '100%'
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
    padding: '0 20px',
    '@media (max-width: 814px)': {
      flexWrap: 'wrap',
        '& > div': {
          width: '47%',
        }
    },
    '@media (max-width: 599px)': {
        '& > div': {
          width: '100%',
        }
    }
  },
  organisationsLookupFilter: {
    width: '250px'
  },
  dateFilters: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '40px'
  },
  datePicker: {
    width: '100%',
  },
  headerWithDropdown: {
    height: '100px'
  },
  formControl: {
    width: '300px',
    paddingRight: '10px'
  },
})

export default style

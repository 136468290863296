import _ from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import Section from '../../Section'
import { ActionButton } from '../../ReportSummaryModal'
import { translations } from '../../../../../../config'

import { useAbilities } from '../../../../../../ability/ability-context'
import { getHasReportAuditingEnabled, getHasReportReassignmentEnabled } from '../../../../../../store/modules/platforms/selectors'

import modalService from '../../../../../../services/modalService'
import ReportReassignmentModal from './modals/ReportReassignmentModal'

import { actions as reportDetailActions } from '../../../../../../store/modules/reportDetails'
import { getIsReportEditable } from '../../../../../../store/modules/reportDetails/selectors'
import { canReassignReports as canReassignReportsSelector } from '../../../../../../store/modules/combinedSelectors/authCombinedSelectors'

const AmendmentActions = (props) => {
  const {
    registerSection,
    toggleSectionVisiblity,
    getSection,
    report
  } = props

  const dispatch = useDispatch()

  const reportId = _.get(report, 'id')
  const hasXML = _.get(report, 'hasXML')
  const ability = useAbilities()
  const hasAlterXMLPermissions = ability.can('view', 'reportAudit')
  const reportAuditingEnabled = useSelector(getHasReportAuditingEnabled)
  const isReportEditable = useSelector(getIsReportEditable)
  const reportOrganisationId = _.get(report, 'organisationId')

  const reportReassignmentEnabled = useSelector(getHasReportReassignmentEnabled)
  const canReassignReports = useSelector(canReassignReportsSelector)

  const amendXML = () => {
    dispatch(push(`/report-management/${reportId}/amend`))
  }

  const handleReportReassignment = (values) => {
    const { organisation } = values
    dispatch(reportDetailActions.reassignReportToOrganisation({ organisationId: organisation, reportId }))
  }

  const openReportReassignmentModal = () => {
    return modalService.open({
      component: ReportReassignmentModal,
      onSubmit: handleReportReassignment,
      reset: () => { dispatch(reportDetailActions.resetReportReassignment()) },
      close: modalService.close,
      organisationId: reportOrganisationId
    })
  }

  const toXML = <ActionButton onClick={amendXML}>{translations('to XML')}</ActionButton>
  const asAnIndustryReport = <ActionButton>{translations('as an Industry Report')}</ActionButton>
  const usingReportConfig = <ActionButton>{translations('using the Report Config')}</ActionButton>
  const reassignReport = <ActionButton onClick={openReportReassignmentModal}>{translations('Reassign Report')}</ActionButton>

  const rows = []
  if (hasAlterXMLPermissions) {
    rows.push({ type: 'ACTION', value: hasXML ? toXML : null })
  }

  if(reportReassignmentEnabled && canReassignReports) {
    rows.push({ type: 'ACTION', value: reassignReport })
  }

  if (!hasXML || _.isEmpty(rows) || !reportAuditingEnabled || !isReportEditable) {
    return <></>
  }

  return (
    <Section
      name='Make Amendments...'
      registerSection={registerSection}
      toggleSectionVisiblity={toggleSectionVisiblity}
      getSection={getSection}
      rows={rows}
    />
  )
}


export default AmendmentActions
import _ from 'lodash'
import React from 'react'
import Section, { SectionTypes } from '../Section'
import { ActionButton } from '../ReportSummaryModal'
import { translations } from '../../../../../config'
import { selectors as reportDetailsSelectors } from '../../../../../store/modules/reportDetails'
import { authCombinedSelectors } from '../../../../../store/modules/combinedSelectors'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { getIsReportEditable } from '../../../../../store/modules/reportDetails/selectors'

const UpdateActions = (props) => {
  const {
    registerSection,
    toggleSectionVisiblity,
    getSection
  } = props

  const dispatch = useDispatch()
  
  const reportId = useSelector(reportDetailsSelectors.getReportId)
  const notificationId = useSelector(reportDetailsSelectors.getNotificationIdForReport)
  const isReportEditable = useSelector(getIsReportEditable)
  const hasUpdateReportPermissions = useSelector(authCombinedSelectors.getHasUpdateReportsPermissions)
  const canUpdateReports = isReportEditable && hasUpdateReportPermissions
  const isReadOnlyReportWithNotification = useSelector(reportDetailsSelectors.isReadOnlyReportWithNotification)
  const title = canUpdateReports ? 'View/Update Report' : 'View Report'

  const updateReport = () => {
    const path = isReadOnlyReportWithNotification 
      ? `/reports/${reportId}/notification/${notificationId}`
      : `/reports/${reportId}` 
    dispatch(push(path))
  }

  const updateReportButton = 
    <ActionButton onClick={updateReport}>
      {canUpdateReports && translations('View/Update Report')}
      {!canUpdateReports && translations('View Report')}
    </ActionButton>

  const rows = [
    { type: SectionTypes.ACTION, value: updateReportButton }
  ]

  return (
    <Section
      name={title}
      registerSection={registerSection}
      toggleSectionVisiblity={toggleSectionVisiblity}
      getSection={getSection}
      rows={rows}
    />
  )
}

export default UpdateActions
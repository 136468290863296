import { connect } from 'react-redux'
import { getFormValues, SubmissionError, clearSubmitErrors, isInvalid } from 'redux-form'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import _ from 'lodash'
import { push } from 'connected-react-router'
import withWidth from '@material-ui/core/withWidth'
import { environment, translations } from '../../../config'
import imageUploadService from '../../../services/imageUploadService'

import ViewOrganisationScreen from './ViewOrganisationScreen'
import {
  selectors as organisationDetailsSelectors,
  actions as organisationDetailsActions,
  constants as organisationDetailsConstants
} from '../../../store/modules/organisationDetails'

import * as schema from '../formSchemas/organisationDetailsSchema'

import { selectors as authSelectors, actions as authActions } from '../../../store/modules/auth'
import { selectors as receiversSelectors } from '../../../store/modules/receivers'
import { selectors as formSchemaSelectors } from '../../../store/modules/formSchemas'
import { selectors as pagesSelectors } from '../../../store/modules/pages'
import { selectors as themesSelectors } from '../../../store/modules/themes'
import { selectors as meddraSelectors } from '../../../store/modules/meddra'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { selectors as ssoProviderSelectors, actions as ssoProviderActions } from '../../../store/modules/ssoProvider'
import { selectors as sourcesSelectors } from '../../../store/modules/sources'
import { organisationDetailsCombinedSelectors } from '../../../store/modules/combinedSelectors'

import FullScreenLoader from '../../../components/FullScreenLoader'
import modalService from '../../../services/modalService'

const _getRemoteImage = (image) => {
  if (!image || !_.startsWith(image, 'blob:')) return null
  return imageUploadService.upload(image)
    .then((image) => _.get(image, 'url'))
}

const mapStateToProps = state => {
  const isLoading = (
    organisationDetailsSelectors.getIsLoading(state) ||
    receiversSelectors.getIsLoading(state) ||
    themesSelectors.getIsLoadingAllThemes(state) ||
    ssoProviderSelectors.getIsLoading(state) ||
    sourcesSelectors.getIsLoading(state)
  )
  const isUploading = organisationDetailsSelectors.getIsUploading(state)
  const organisationDetails = organisationDetailsSelectors.getOrganisationDetailsForForm(state)
  const orgReceivers = organisationDetailsSelectors.uniqueOrgReceivers(state)
  const organisationName = organisationDetailsSelectors.getCurrentOrganisationName(state)
  const organisationId = organisationDetailsSelectors.getCurrentOrganisationId(state)
  const drugsLists = organisationDetailsSelectors.getCurrentOrganisationDrugsLists(state)
  const professions = organisationDetailsSelectors.getCurrentOrganisationProfessions(state)
  const professionGroups = organisationDetailsSelectors.getCurrentOrganisationProfessionGroups(state)
  const country = organisationDetailsSelectors.getCurrentOrganisationCountry(state)
  const { name: currentOrganisation } = organisationDetailsSelectors.getCurrentOrganisation(state)
  const canEditOrg = authSelectors.getHasConfigureOrgPermission(state)
  const canEditOrgFundamentals = authSelectors.getHasConfigureOrgFundamentalsPermission(state)
  const drugsFormValues = getFormValues(organisationDetailsConstants.ORGANISATION_DRUGS_FORM_NAME)(state)
  const orgDetailsFormValues = getFormValues(organisationDetailsConstants.EDIT_ORGANISATION_FORM_NAME)(state)
  const orgContactDetailsFormValues = getFormValues(organisationDetailsConstants.REPORTING_ORGANISATION_CONTACT_DETAILS_FORM_NAME)(state)
  const organisationTranslations = organisationDetailsSelectors.getCurrentOrganisationTranslationsForOrgDetails(state)
  const meddraVersionOptions = meddraSelectors.getVersionsAsOptions(state)
  const ssoProvider = ssoProviderSelectors.getSSOProvider(state)
  const receivers = receiversSelectors.getReceiversForDropdown(state)
  const formSchemas = formSchemaSelectors.getSchemasForDropdown(state)
  const sources = organisationDetailsCombinedSelectors.getSourcesForSelectedOrganisationAsOptions(state)
  const organisationPages = pagesSelectors.getPages(state)

  const publicNewsUrls = organisationTranslations.map(t => {
    return {
      isoCode: translations(`Language - ${t.isoCode}`),
      publicURL: `${environment.API_URL}/v1/news/feed.atom?organisationId=${organisationId}&language=${t.isoCode}`
    }
  })

  const platform = organisationDetailsSelectors.getCurrentOrganisationPlatform(state)

  if (orgReceivers) {
    _.set(organisationDetails, 'receiverSchemas', orgReceivers)
  }

  const initialValues = (
    isLoading
      ? undefined
      : {
        ...organisationDetails,
        professions,
        guestSubmissionUrl: `https://${_.get(platform, 'domain')}/reports/new/public/${organisationId}`,
        publicNewsUrls: publicNewsUrls,
        name: organisationName
      }
  )
  const loadingMessage = isUploading ? translations('Full Screen Loader - Uploading') : ''

  const ssoInitialValues = {
    id: _.get(ssoProvider, 'id'),
    isSSOEnabled: !!_.get(ssoProvider, 'id'),
    spMetadata: `${environment.API_URL}/v1/saml/metadata/${_.get(ssoProvider, 'id')}/download`,
    idpMetadata: _.get(ssoProvider, 'prodMetadataIDP')
  }
  return {
    isLoading,
    loadingMessage,
    organisationName,
    organisationId,
    orgReceivers,
    initialValues,
    currentOrganisation,
    drugsLists,
    drugsFormValues,
    professions,
    professionGroups,
    organisationTranslations,
    canEditOrg,
    canEditOrgFundamentals,
    platform,
    platformId: _.get(platform, 'id'),
    meddraVersionOptions,
    country,
    receivers,
    formSchemas,
    orgDetailsFormValues,
    pageTitle: platformSelectors.getPageTitle(state)(organisationName || 'App Name - Organisation Management'),
    ssoInitialValues,
    sources,
    organisationPages,
    orgContactDetailsFormValues
  }
}

const validateIDPMetadata = (idpMetadata) => {
  if (!idpMetadata) {
    return false
  }
  const domNode = new DOMParser().parseFromString(idpMetadata, 'application/xml').documentElement.nodeName
  console.log(`IDP XML validation parse result: ${domNode}`)
  return domNode.includes('EntityDescriptor')
}

export default compose(
  withWidth(),
  connect(mapStateToProps),
  withHandlers({
    onSubmit: ({ dispatch, organisationId }) => async (formValues) => {
      const orgIcon = await _getRemoteImage(formValues.orgIcon)

      if (orgIcon) {
        formValues.orgIcon = orgIcon
      }

      const params = _.omit(formValues, 'guestSubmissionUrl')
      params.orgName = params.name

      return dispatch(organisationDetailsActions.updateOrganisation({ params, id: organisationId }))
    },
    ssoOnSubmit: ({ dispatch, organisationName, ssoInitialValues, platformId, organisationId }) => async (formValues) => {
      if (formValues.isSSOEnabled) {
        if (validateIDPMetadata(formValues.idpMetadata)) {
          const params = {
            devMetadataIDP: formValues.idpMetadata,
            prodMetadataIDP: formValues.idpMetadata,
            fieldMapping: {},
            roleMapping: {},
            platformId: platformId,
            organisationId
          }
          modalService.action({
            title: translations(`Please confirm that you wish to ${ssoInitialValues.id ? 'update' : 'enable'} SSO for ${organisationName}?`),
            text: `${ssoInitialValues.id ? 'Updating' : 'Enabling'} SSO for this organisation will take immediate effect. Once enabled, all current users will be automatically logged out (including you) and required to log in again using their SSO credentials.\n\nWe recommend that you only enable SSO outside of regular working hours.`,
            actions: [
              {
                text: translations('No'),
                onClick: () => {
                  modalService.close()
                }
              },
              {
                text: translations('Yes'),
                onClick: () => {
                  if (ssoInitialValues.id) {
                    dispatch(ssoProviderActions.updateSSOProvider({ ...params, id: ssoInitialValues.id }))
                      .then(() => dispatch(authActions.logout()))
                  } else {
                    dispatch(ssoProviderActions.createSSOProvider({ ...params }))
                      .then(() => dispatch(authActions.logout()))
                  }
                },
                primary: true
              }
            ]
          })
        } else {
          modalService.action({
            title: translations('Invalid Metadata'),
            text: translations('Could not validate Identity Provider Metadata, please update and try again.'),
            actions: [
              {
                text: translations('OK'),
                primary: true
              }
            ]
          })
        }
      } else {
        if (!ssoInitialValues.id) {
          return
        }
        modalService.action({
          title: translations(`Please confirm that you wish to delete SSO for ${organisationName}?`),
          text: translations(`Deleting SSO for this organisation will take immediate effect. Once deleted, all current users will be automatically logged out (including you).`),
          actions: [
            {
              text: translations('No'),
              onClick: () => {
                modalService.close()
              }
            },
            {
              text: translations('Yes'),
              onClick: () => {
                dispatch(ssoProviderActions.deleteSSOProvider({ id: ssoInitialValues.id }))
                  .then(() => dispatch(authActions.logout()))
              },
              primary: true
            }
          ]
        })
      }
    }
  }),
  withHandlers({
    onSubmitProfessions: ({ dispatch, organisationId }) => (formValues) => {
      const newProfessionsList = _.get(formValues, 'professions', [])
      return dispatch(organisationDetailsActions.setProfessionsForOrganisation({ organisationId, professions: newProfessionsList }))
    },
    onSubmitProfessionGroups: ({ dispatch, organisationId }) => (formValues) => {
      const newProfessionGroupsList = _.get(formValues, 'professionGroups', [])
      return dispatch(organisationDetailsActions.setProfessionGroupsForOrganisation({ organisationId, professionGroups: newProfessionGroupsList }))
    },
    onSubmitLocations: ({ dispatch, organisationId }) => (formValues) => {
      const newLocationsList = _.get(formValues, 'locations', [])
      const newUniqueLocations = _.uniqWith(_.map(newLocationsList, location => ({
        name: _.trim(_.get(location, 'name').toLowerCase()),
        parentId: _.get(location, 'parentId')
      })), _.isEqual)
      const hasDuplicates = newLocationsList.length !== newUniqueLocations.length

      const locationProvider = _.get(formValues, 'locationProvider', null)

      if (hasDuplicates) {
        throw new SubmissionError({ _error: translations('Location management - Duplicated Locations') })
      } else {
        return dispatch(organisationDetailsActions.setLocationsForOrganisation({
          organisationId,
          locations: newLocationsList,
          locationProvider
        }))
      }
    },
    onClickManageTranslations: ({ dispatch, organisationId }) => {
      dispatch(push(`/organisations/${organisationId}/translations`))
    },
    onClickManagePages: ({ dispatch, organisationId }) => {
      dispatch(push(`/organisations/${organisationId}/page-management`))
    },
    onDuplicate: (props) => () => {
      const { dispatch, organisationId } = props

      return modalService.action({
        title: translations('Duplicate organisation Modal - Title'),
        text: translations('Duplicate organisation Modal - Message'),
        actions: [
          {
            text: translations('Cancel')
          },
          {
            text: translations('Continue'),
            onClick: () => {
              return dispatch(push(`/organisations/new?sourceOrganisationId=${organisationId}`))
            },
            primary: true
          }
        ]
      })
    }
  }),
  withPropsOnChange(
    ['professions', 'professionGroups'],
    (props) => {
      const { professions, professionGroups } = props
      const professionsInitialValues = { professions }
      const professionGroupOptions = _.map(professionGroups, professionGroup => {
        return {
          label: professionGroup.name,
          value: professionGroup.id
        }
      })
      const professionsSchema = schema.professionsConfiguration({ professionGroups: professionGroupOptions })
      const professionGroupsSchema = schema.professionGroupsConfiguration()
      const professionGroupsInitialValues = { professionGroups }
      return {
        professionsSchema,

        professionsInitialValues,
        professionGroupsSchema,
        professionGroupsInitialValues
      }
    }
  ),
  withPropsOnChange(
    ['meddraVersionOptions'],
    (props) => {
      const { meddraVersionOptions } = props
      const organisationConfigurationSchema = _.cloneDeep(schema.organisationConfiguration.schema)
      const meddraVersionSchema = organisationConfigurationSchema.find(x => x.id === 'meddraVersion')
      meddraVersionSchema.props.options = meddraVersionOptions
      return { organisationConfigurationSchema }
    }
  ),
  withPropsOnChange(
    ['orgReceivers'],
    (props) => {
      const { orgReceivers } = props
      return { orgReceivers }
    }
  ),
  withPropsOnChange(
    ['receivers', 'formSchemas', 'sources'],
    (props) => {
      const { receivers, formSchemas, sources } = props
      return {
        receiverOptions: {
          receivers,
          formSchemas,
          sources
        }
      }
    }
  ),
  FullScreenLoader
)(ViewOrganisationScreen)

import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, DialogActions, DialogContent, Divider } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close'

import { translations } from '../../../config'
import ChangeRequestEntryCard from './ChangeRequestEntryCard'
import Heading from '../../../components/Heading'
import Button from '../../../components/Button'
import ButtonsContainer from '../../../components/ButtonsContainer'
import { asyncActions, selectors } from '../../../store/modules/changeManagement'
import { CenterContainer, CloseButton, DetailsContainer, StyledDialogTitle } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { handleApprovalStatus, handleOrigin } from '../handlers'



const ReportSummaryModal = ({
  dismiss,
  changeRequestId
}) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(asyncActions.getChangeRequestById({ changeRequestId }))
  }, [dispatch, changeRequestId])
  const { entity, isLoading } = useSelector(selectors.getDetailById({ id: changeRequestId }))
  if (isLoading) {
    return (<CenterContainer>
      <CircularProgress />
    </CenterContainer>)
  }
  const approvalStatusCharacteristics = handleApprovalStatus({ approvalStatus: entity.approvalStatus })
  const originCharacteristics = handleOrigin({ entry: entity })
  return (
    <div>
      <StyledDialogTitle>
        <Heading>{originCharacteristics.subject}</Heading>
        <CloseButton
          aria-label={translations("close")}
          onClick={dismiss}
        >
          <CloseIcon />
        </CloseButton>
      </StyledDialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Alert severity={approvalStatusCharacteristics.alertSeverity}>{approvalStatusCharacteristics.alertText}</Alert>
        <DetailsContainer>
          <ChangeRequestEntryCard
            hide={originCharacteristics.hideApprovalEntry}
            details={
              [
                {
                  label: 'Change Request - Reviewed by',
                  value: originCharacteristics.decidedBy,
                  link: originCharacteristics.decidedByLink
                },
                {
                  label: 'Change Request - Reviewed at',
                  value: originCharacteristics.decidedAt,

                }
              ]
            }
            message={
              {
                subject: approvalStatusCharacteristics.reviewDescriptionTitle,
                body: originCharacteristics.approvalStatusMessage
              }
            }
          />
          <ChangeRequestEntryCard
            details={
              [
                {
                  label: 'Change Request - Raised by',
                  value: originCharacteristics.raisedBy,
                  link: originCharacteristics.raisedByLink
                },
                {
                  label: 'Change Request - Opened at',
                  value: originCharacteristics.createdAt
                }
              ]
            }
            message={
              {
                subject: 'Change Request - Message body title',
                body: originCharacteristics.message
              }
            }
          />
        </DetailsContainer>
        <ButtonsContainer align='right' wrap isModal>
          {approvalStatusCharacteristics.showReviewRequest && <Button raised buttonType='primary' type='submit' onClick={originCharacteristics.reviewButtonOnClick({ dispatch })}>{originCharacteristics.reviewButtonText}</Button>}
        </ButtonsContainer>
      </DialogContent>
    </div>
  )
}

ReportSummaryModal.propTypes = {
  dismiss: PropTypes.func.isRequired,
  changeRequestId: PropTypes.string.isRequired
}

export default ReportSummaryModal

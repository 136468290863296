import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withHandlers } from 'recompose'
import ReportsSearchFilters from './ReportsSearchFilters'
import { actions as reportsActions, selectors as reportsSelectors } from '../../../../store/modules/reportConfiguration'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { translations } from '../../../../config'
import modalService from '../../../../services/modalService'

const mapStateToProps = (state, ownProps) => {
  const currentFormViewId = _.get(ownProps, 'formViewId')
  const loggedInUserStoresOptions = authSelectors.getUserOrganisationsOptions(state)
  const canSelectOrgs = authSelectors.getHasMultipleOrganisations(state)
  const numberOfActiveFilters = reportsSelectors.getNumberOfActiveFilters(currentFormViewId)(state)
  const results = reportsSelectors.getReportResultsById(currentFormViewId)(state)
  const statusOptions = [
    {
      label: translations('Report Management - Status DRAFT'),
      value: 'draft'
    },
    {
      label: translations('Report Management - Status SUBMITTED'),
      value: 'complete'
    },
    {
      label: translations('Report Management - Status ACK SUCCESS'),
      value: 'ackPassed'
    },
    {
      label: translations('Report Management - Status ACK FAIL'),
      value: 'ackFailed'
    },
    {
      label: translations('Report Management - Status IN PROGRESS'),
      value: 'inProgress'
    }
  ]

  const typesOptions = [
    {
      label: translations('REPORT DEVICES'),
      value: 'devicesReport'
    },
    {
      label: translations('REPORT FSCA'),
      value: 'fscaReport'
    },
    {
      label: translations('REPORT R2'),
      value: 'mhraR2Report'
    },
    {
      label: translations('REPORT R3'),
      value: 'mhraR3Report'
    }
  ]

  const sourceOptions = [
    {
      label: translations('REPORT SOURCE WEBCREATEFORM'),
      value: 'evwebcreateform'
    },
    {
      label: translations('REPORT SOURCE WEBPOSTFORM'),
      value: 'evwebpostform'
    },
    {
      label: translations('REPORT SOURCE WEBSFTPIMPORT'),
      value: 'evwebsftpimport'
    },
    {
      label: translations('REPORT SOURCE PANDEMIC'),
      value: 'pandemicwebsite'
    },
    {
      label: 'PVAI',
      value: 'pvai'
    },
    {
      label: 'Vaccine Monitor',
      value: 'yellowcardVaccineMonitor'
    }, {
      label: translations('Post XML'),
      value: 'postxml'
    }, {
      label: translations('Incident API'),
      value: 'incidentapi'
    }
  ]

  return {
    results,
    numberOfActiveFilters,
    statusOptions,
    typesOptions,
    sourceOptions,
    loggedInUserStoresOptions,
    canSelectOrgs
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    downloadReports: ({ dispatch, results }) => {
      if (results.length && results.length > 0) {
        const ids = _.map(results, res => res.id)
        dispatch(reportsActions.fetchReportXMLForIds({ ids }))
      } else {
        modalService.action({
          title: translations('No reports'),
          text: translations('There are no reports to export'),
          actions: [
            {
              text: translations('Continue')
            }
          ]
        })
      }
    }
  })
)(ReportsSearchFilters)

import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'

import Heading from '../../../../../components/Heading'
import P from '../../../../../components/P'
import BasicModalOverlay from '../../../../../components/BasicModalOverlay'
import ContentBoxBody from '../../../../../components/ContentBox/ContentBoxBody'
import Chip from '../../../../../components/Chip'

import { statusMap } from '../../CaseSearchScreen'
import SubmissionStatus from '../../SubmissionStatus'

import { translations } from '../../../../../config'
import styles from './style'

const ErrorStatusModal = (props) => {
  const {
    classes,
    modalTitle,
    actions,
    status,
    message,
    errors,
    details,
    contextActions,
    sentToReceiverSince,
    submission,
    receiverId,
    receiverName
  } = props
  const hasErrors = _.size(errors) > 0
  const hasDetails = !_.isEmpty(details)
  const hasContextActions = _.size(contextActions) > 0

  return (
    <BasicModalOverlay
      title={modalTitle}
      actions={actions}
    >
      <ContentBoxBody>
        <div className={classes.container}>
          <div className={classes.status}>
            <div className={classes.statusMessage}>
              <P type={'large'} value={message} />
            </div>
            <SubmissionStatus
              isSent={sentToReceiverSince}
              receiverStatus={status}
              submission={submission}
              receiverId={receiverId}
              receiverName={receiverName}
            >
              <Chip variant={_.get(statusMap, status)} label={translations(`Case Management Status - ${status}`)} />
            </SubmissionStatus>
          </div>
          {hasContextActions && (
            <div className={classes.actions}>
              {_.map(contextActions, ({ label, icon: IconComponent, onClick }) => {
                return (
                  <Tooltip title={label} placement={'top'} classes={{ tooltip: classes.actionTooltip }}>
                    <IconButton
                      onClick={onClick}
                      aria-label={label}
                      classes={{
                        root: classes.actionButton
                      }}>
                      <IconComponent />
                    </IconButton>
                  </Tooltip>
                )
              })}
            </div>
          )}
          {hasDetails && (
            <div className={classes.section}>
              <Heading variant='h3'>{translations('Case Management - Response Title')}</Heading>
              <P value={details} />
            </div>
          )}
          {hasErrors && (
            <div className={classes.section}>
              <Heading variant='h3'>{translations('Case Management - Errors Title')}</Heading>
              <ul className={classes.list}>
                {_.map(errors, (error, index) => {
                  return (
                    <li key={index} className={classes.listItem}>
                      <P value={_.isString(error) ? error : JSON.stringify(error)} />
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </div>
      </ContentBoxBody>
    </BasicModalOverlay>
  )
}

ErrorStatusModal.defaultProps = {
  sentToReceiverSince: false
}

ErrorStatusModal.propTypes = {
  classes: PropTypes.any,
  modalTitle: PropTypes.string.isRequired,
  actions: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  message: PropTypes.string,
  errors: PropTypes.array,
  details: PropTypes.string,
  contextActions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired
  })),
  sentToReceiverSince: PropTypes.bool
}

export default withStyles(styles)(ErrorStatusModal)

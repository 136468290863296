import { constants as translationsConstants } from '../translations'
import { translations } from '../../../config'
import { actions as organisationDetailsActions, selectors as organisationDetailsSelectors } from '../organisationDetails'
import { selectors as authSelectors, constants as authConstants } from '../auth'
import { actions as translationsActions, selectors as translationsSelectors } from '../translations'
import { constants as platformsConstants } from '../platforms'
import { REHYDRATE } from 'redux-persist'
import fsService from '../../../services/fsService'
import toastService from '../../../services/toastService'

class TranslationsMiddleware {
  downloadCSVOnGenerateCSVSuccess = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === translationsConstants.GENERATE_CSV && action.status === 'SUCCESS') {
      fsService.downloadFileFromURL(action.result.downloadURL)
    }
  }

  fetchOrganisationDetailsOnCreateTranslationSuccess = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === translationsConstants.CREATE_TRANSLATION && action.status === 'SUCCESS') {
      const organisationId = organisationDetailsSelectors.getCurrentOrganisationId(getState())
      dispatch(organisationDetailsActions.fetchOrganisationById({
        id: organisationId
      }))
    }
  }

  fetchOrganisationDetailsOnUploadTranslationSuccess = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === translationsConstants.UPLOAD_TRANSLATIONS && action.status === 'SUCCESS') {
      const organisationId = organisationDetailsSelectors.getCurrentOrganisationId(getState())
      dispatch(organisationDetailsActions.fetchOrganisationById({
        id: organisationId
      }))
    }
  }

  fetchTranslationsForOrgOnOrgSelect = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === authConstants.SELECT_ORGANISATION) {
      const { organisationId } = action
      if (organisationId) {
        dispatch(translationsActions.fetchTranslationsForOrg(organisationId))
      }
    }
  }

  setTranslationsOnRehydrate = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === REHYDRATE) {
      const translations = translationsSelectors.getTranslations(getState())
      if (translations) {
        dispatch(translationsActions.setTranslations(translations))
      }
    }
  }

  setTranslationsOnTranslationLoad = ({ dispatch, getState }) => next => action => {
    next(action)
    if (action.type === translationsConstants.FETCH_TRANSLATIONS_FOR_ORG && action.status === 'SUCCESS') {
      dispatch(translationsActions.setTranslations(action.result))
    }
  }

  setTranslationsOnPlatformLoad = ({ dispatch, getState }) => next => action => {
    next(action)
    if (
      action.type === platformsConstants.FETCH_PLATFORM_FOR_DOMAIN && action.status === 'SUCCESS' &&
      action.result && action.result.translations
    ) {
      dispatch(translationsActions.setTranslations(action.result.translations))
    }
  }

  fetchTranslationSearchesOnChangesSaved = ({ dispatch, getState }) => next => action => {
    next(action)

    const ChangeWorthyConstants= [
      translationsConstants.SAVE_TRANSLATION_CHANGES_FOR_ORG,
      translationsConstants.CREATE_NEW_TRANSLATION_KEY_FOR_ORG,
      translationsConstants.DELETE_LANGUAGES_TRANSLATIONS_FOR_ORG,
      translationsConstants.SAVE_TRANSLATION_DELETE_FOR_ORG
    ]

    if (ChangeWorthyConstants.includes(action.type) && action.status === 'SUCCESS') {
      if (action.type !== translationsConstants.SAVE_TRANSLATION_DELETE_FOR_ORG ) {
        dispatch(translationsActions.setOffset({offset: 0}))
      }
      const organisationId = organisationDetailsSelectors.getCurrentOrganisationId(getState())
      dispatch(translationsActions.searchTranslationsForOrganisation({ organisationId }))
      dispatch(organisationDetailsActions.fetchOrganisationById({ id: organisationId }))
    }
  }
}

export default new TranslationsMiddleware()

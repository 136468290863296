import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { translations } from '../../../config'

import { getImage } from '../../../components/Images'
import Notification from '../../../components/Notification'
import Button from '../../../components/Button'

const reportIcon = getImage('reportsIcon')
const blankIcon = getImage('blankImg')
const messageIcon = getImage('messageIcon')

const NotificationSwitch = notification => {
  const { type, title, body, notificationReport, actionedByUserId, notificationId , onClick} = notification

  let iconSource = blankIcon
  let notificationText = ''
  let actions

  switch (type) {
    case 'REPORT_PROMPT':
      iconSource = reportIcon
      notificationText = translations('Report Prompt Text', { title })
      actions = [
        <Button
          disabled={!_.isNull(actionedByUserId)}
          onClick={onClick}
        >
          {translations('View Report')}
        </Button>
      ]
      break
    case 'REPORT_FOLLOWUP':
      iconSource = reportIcon
      notificationText = translations('Report Followup Text', { title })
      actions = [
        <Button
          disabled={!_.isNull(actionedByUserId)}
          onClick={onClick}
        >
          {translations('Edit Report')}
        </Button>
      ]
      break
    case 'CHANGE_REQUEST_ORGANISATION':
    case 'CHANGE_REQUEST_ORGANISATION_DECISION':
      iconSource = messageIcon
      notificationText = body
      break
    case 'ORGANISATION_INVITATION':
      iconSource = messageIcon
      notificationText = title
      break
    case 'REQUEST_ACCOUNT_DELETION':
      iconSource = messageIcon
      notificationText = title
      break
    default:
      console.log('Notification type not found')
  }


  return (
    <Notification
      {...notification}
      iconSource={iconSource}
      notificationText={notificationText}
      actions={actions}
    />
  )
}

NotificationSwitch.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default NotificationSwitch

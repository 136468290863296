import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers } from 'recompose'
import _ from 'lodash'
import { push } from 'connected-react-router'

import { selectors as notificationsSelectors, actions as notificationsActions } from '../../../store/modules/notifications'
import { selectors as authSelectors } from '../../../store/modules/auth'
import { selectors as platformSelectors } from '../../../store/modules/platforms'
import { actions as usersActions } from '../../../store/modules/users'
import * as combinedNotificationsSelectors from '../../../store/modules/combinedSelectors/notificationsUserSelector'
import * as authCombinedSelectors from '../../../store/modules/combinedSelectors/authCombinedSelectors'
import { authFormViewsSelectedReceiverSelectors } from '../../../store/modules/combinedSelectors'
import analyticsService from '../../../services/analyticsService'
import modalService from '../../../services/modalService'

import ChangeRequestSummaryModal from '../../ChangeManagement/ChangeRequestSummaryModal'
import NotificationsScreen from './NotificationsScreen'

const mapStateToProps = state => {
  const filters = notificationsSelectors.getNotificationsFilters(state)
  const filtersFalse = !filters.toDo && !filters.isFlagged
  const activeUserId = authSelectors.getActiveUserId(state)
  const notificationUserId = notificationsSelectors.getSelectedUserId(state)
  const selectedUserId = notificationUserId || activeUserId
  const currentUserSelected = !notificationUserId || (notificationUserId === activeUserId)

  return {
    notifications: combinedNotificationsSelectors.getNotificationsWithActionedValues(state),
    hasMore: notificationsSelectors.getNotificationsHasMore(state),
    isLoading: notificationsSelectors.getNotificationsIsLoadingInitial(state),
    canViewOtherUsersNotifications: authSelectors.getHasViewOtherUsersNotifications(state),
    filtersFalse,
    filters,
    userOptions: authCombinedSelectors.getUserOptionsIncludingCurrentUser(state),
    selectedUserId,
    currentUserSelected,
    pageTitle: platformSelectors.getPageTitle(state)('Notifications'),
    forms: authFormViewsSelectedReceiverSelectors.getPublishedForms(state),
    emailNotificationRecipientEnabled: platformSelectors.getEmailNotificationRecipientEnabled(state)
  }
}

const mapDispatchToProps = dispatch => ({
  searchUsers: params => dispatch(usersActions.searchUsersFresh(params)),
  fetchNotifications: params => dispatch(notificationsActions.fetchUserNotificationsFresh(params)),
  markNotificationsAsRead: () => dispatch(notificationsActions.setTotalNotificationsNotViewed({ total: 0 })),
  dispatch
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    onFiltersChange: ({ dispatch, filters, selectedUserId }) => status => {
      const newFilters = {
        ...filters,
        ...status
      }
      dispatch(notificationsActions.updateNotificationFilters(newFilters))
      selectedUserId
        ? dispatch(notificationsActions.fetchUserNotificationsFresh({ ...newFilters, id: selectedUserId }))
        : dispatch(notificationsActions.fetchPersonalNotificationsFresh(newFilters))
    },
    onUserInputChange: ({ searchUsers }) => _.debounce((inputValue) => {
      searchUsers({ query: inputValue })
    }, 500),
    onToggleStatus: ({ dispatch }) => ({ id, status }) => {
      dispatch(notificationsActions.setNotificationUserStatus({ id, status }))
    },
    fetchFreshNotifications: ({ dispatch, filters }) => e => {
      const userId = _.get(e, 'target')
        ? _.get(e.target, 'value')
        : e
      dispatch(notificationsActions.fetchUserNotificationsFresh(({ ...filters, id: userId })))
    },
    fetchNextNotifications: ({ dispatch, filters, selectedUserId }) => () => {
      dispatch(notificationsActions.fetchUserNotificationsNext({ ...filters, selectedUserId }))
    },
    onToggleActioned: ({ dispatch }) => ({ id, isActioned }) => {
      dispatch(notificationsActions.setNotificationActionedStatus({ id, isActioned }))
    },
    handleNotificationClick: ({ dispatch, currentUserSelected }) => async ({ type, notificationReport, notificationChangeRequest, notificationAccountDeletionRequest, notificationId, isRead }) => {
      const analyticsData = {
        type
      }
      analyticsService.sendCustomEvent({ type: 'notificationClickThrough', analyticsData })
      currentUserSelected && !isRead && await dispatch(notificationsActions.setNotificationUserStatus({ id: notificationId, status: { isRead: true } }))
      switch (type) {
        case 'REPORT_PROMPT':
        case 'REPORT_FOLLOWUP': {
          const reportId = _.get(notificationReport, 'reportId')
          const path = `/reports/${reportId}/notification/${notificationId}`
          path && dispatch(push(path))
          break
        }
        case 'CHANGE_REQUEST_ORGANISATION':
          const changeRequestId = _.get(notificationChangeRequest, 'changeRequestId')
          if (changeRequestId) {
            modalService.open({
              component: ChangeRequestSummaryModal,
              fullScreen: false,
              changeRequestId: _.get(notificationChangeRequest, 'changeRequestId')
            })
          }
          break
        case 'REQUEST_ACCOUNT_DELETION': {
          const userId = _.get(notificationAccountDeletionRequest, 'userId')
          const path = `/users/${userId}`
          path && dispatch(push(path))
          break
        }
        default:
          break
      }
    }
  }),
  lifecycle({
    componentDidMount() {}
  })
)(NotificationsScreen)

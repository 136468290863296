import _ from 'lodash'
import fp from 'lodash/fp'
import React from 'react'
import moment from 'moment'
import Section from '../Section'
import { styled, Table, TableCell, TableHead, TableContainer, TableRow } from '@material-ui/core'
import { statusMap } from '../../../../CaseManagement/CaseSearchScreen/CaseSearchScreen'
import { translations } from '../../../../../config'
import Chip from '../../../../../components/Chip'
import { showReceiverSummary, showValidationSummary } from '../../../../CaseManagement/CaseSearchScreen/CaseSearchScreen/utils'
import { useDispatch, useSelector } from 'react-redux'
import { getHasConfigureCasesPermission } from '../../../../../store/modules/auth/selectors'
import { canResendTransmissions } from '../../../../../store/modules/combinedSelectors/authCombinedSelectors'
import { getHasCaseManagementEnabled } from '../../../../../store/modules/platforms/selectors'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between'
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1)
}))

const AcknowledgementContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: theme.spacing(1),
  textAlign: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    paddingLeft: 20,
    paddingRight: 20
  }
}))

const StatusDetails = (props) => {
  const {
    registerSection,
    toggleSectionVisiblity,
    getSection,
    report
  } = props

  const dispatch = useDispatch()

  const hasConfigureCasesPermission = useSelector(getHasConfigureCasesPermission)
  const hasCaseManagementEnabled = useSelector(getHasCaseManagementEnabled)
  const canResendTransmissionsPermission = useSelector(canResendTransmissions)
  const status = _.get(report, 'status')
  const submissionReports = _.get(report, 'submissionReports')
  const acknowledgements = _.get(report, 'acknowledgements')
  
  const hasSubmissionReports = !_.isEmpty(submissionReports)
  const hasAcknowledgements = !_.isEmpty(acknowledgements)

  if(!hasConfigureCasesPermission || !hasCaseManagementEnabled) {
    return <></>
  }
  
  if (!hasSubmissionReports && !hasAcknowledgements) {
    return <></>
  }

  const rows = [
    {
      name: 'Receiver Status',
      type: 'ACTION',
      value: (
        <MiniCaseManagement
          dispatch={dispatch}
          submissionReports={submissionReports}
          hasConfigureCasesPermission={hasConfigureCasesPermission}
          canResendTransmissionsPermission={canResendTransmissionsPermission}
        />
      )
    },
    {
      name: 'Ack Status',
      type: 'ACTION',
      value: !_.isEmpty(acknowledgements)
        ? (
          <AcknowledgementStatus
            status={status}
          />
        )
        : null
    }
  ]

  return (
    <Section
      name='Status Details'
      registerSection={registerSection}
      toggleSectionVisiblity={toggleSectionVisiblity}
      getSection={getSection}
      rows={rows}
      collapsible={false}
    />
  )
}

const MiniCaseManagement = ({ dispatch, submissionReports, hasConfigureCasesPermission, canResendTransmissionsPermission }) => {
  if (!hasConfigureCasesPermission) {
    return <></>
  }

  const headers = [
    { name: 'Receiver Name', align: 'left' },
    { name: 'Receiver Status', align: 'center' },
    { name: 'Validation Status', align: 'center' },
    { name: 'Created At', align: 'right' },
  ]

  const mappedRows = fp.compose(
    fp.compact,
    fp.map((submissionReport) => {
      const {
        id,
        createdAt,
        receiverStatus,
        receiver,
        canResend,
        receiverStatusMessage,
        validationStatus,
        validationErrors
      } = submissionReport

      const receiverId = _.get(receiver, 'id')
      const receiverName = _.get(receiver, 'name')
      const receiverVariant = _.get(statusMap, receiverStatus)
      const validationVariant = _.get(statusMap, validationStatus)

      if (!receiverId && !receiverName) {
        return
      }

      return ({
        receiverName,
        receiverStatus:
          <Chip
            variant={receiverVariant}
            onClick={() => showReceiverSummary({ dispatch })({
              submissionReportId: id,
              receiverId,
              receiverStatus,
              canResend: (canResend || canResendTransmissionsPermission),
              receiverStatusMessage
            })}
            label={translations(`Case Management Status - ${receiverStatus}`)}
          />,
        validationStatus:
          <Chip
            variant={validationVariant}
            onClick={() => showValidationSummary()({
              validationStatus,
              validationErrors
            })}
            label={translations(`Case Management Status - ${validationStatus}`)}
          />,
        createdAt: moment(createdAt).format('DD/MM/YYYY HH:mm')
      })
    })
  )(submissionReports)

  const rows = _.sortBy(mappedRows, 'createdAt')

  const getTableCellComponent = (index, value, align) => {
    return (
      <TableCell
        component={StyledTableCell}
        align={align || 'center'}
        style={(index === rows.length - 1) ? { borderBottom: 'none' } : {}}
      >
        {value}
      </TableCell>
    )
  }

  return (
    <TableContainer component={Container}>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map(header => <TableCell align={header.align || 'center'}>{header.name}</TableCell>)}
          </TableRow>
          {rows.map((row, index) => {
            return (
              <TableRow>
                {getTableCellComponent(index, row.receiverName, 'left')}
                {getTableCellComponent(index, row.receiverStatus, 'center')}
                {getTableCellComponent(index, row.validationStatus, 'center')}
                {getTableCellComponent(index, row.createdAt, 'right')}
              </TableRow>
            )
          })}
        </TableHead>
      </Table>
    </TableContainer>
  )
}

const AcknowledgementStatus = ({ status }) => {
  const render = ['ackPassed', 'ackFailed'].includes(status)

  if (!render) {
    return <></>
  }

  const chipMap = {
    ackPassed: 'success',
    ackFaild: 'error'
  }

  return (
    <AcknowledgementContainer>
      <p style={{ fontWeight: 400 }}>{translations('Acknowledgement Status')}</p>
      <Chip
        variant={chipMap[status]}
        label={translations(status)}
        style={{ width: '20%' }}
      />
    </AcknowledgementContainer>
  )
}

export default StatusDetails
import _ from 'lodash'
import { promiseReducer } from '../../util'
import { SUCCESS, PENDING, FAIL } from '../../middleware/redux-promise'
import { translations } from '../../../config'

class ReportDetailsReducers {
  fetchReport = promiseReducer(
    (state, action) => {
      const fields = _.get(action, 'result.reportJSON')
      const reportStatus = _.get(action, 'result.status')
      const reportType = _.get(action, 'result.type')
      const reportId = _.get(action, 'result.id')
      return {
        ...state,
        report: action.result,
        fields,
        reportId,
        reportStatus,
        reportType
      }
    }
  )
  submitReport = promiseReducer(
    (state, action) => {
      return {
        ...state
      }
    }
  )
  loadExistingReport = (state, action) => {
    switch (action.status) {
      case FAIL:
        const { error } = action

        let validationErrors = _.get(error, 'error.validationErrors', [])
        if (error.code === 'RA-MHR-198-01') {
          validationErrors = _.get(error, 'meta.properties.error.Messages')
        }

        return {
          existingReport: {
            validationErrors,
            errorCode: error.code
          },
          ...state
        }
      default:
        return state
    }
  }
  fetchReportFollowupNotification = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchReportFollowupNotificationPending({ state, action })
      case SUCCESS:
        return this.fetchReportFollowupNotificationSuccess({ state, action })
      case FAIL:
        return this.fetchReportFollowupNotificationFail({ state, action })
      default:
        return state
    }
  }

  fetchReportFollowupNotificationPending = ({ state, action }) => {
    return {
      ...state,
      selectedReportFollowupNotification: {
        ...state.selectedReportFollowupNotification,
        status: PENDING
      }
    }
  }

  fetchReportFollowupNotificationSuccess = ({ state, action }) => {
    const { result } = action
    return {
      ...state,
      selectedReportFollowupNotification: {
        result,
        error: undefined,
        status: SUCCESS
      }
    }
  }

  fetchReportFollowupNotificationFail = ({ state, action }) => {
    const { error } = action
    return {
      ...state,
      selectedReportFollowupNotification: {
        ...state.selectedReportFollowupNotification,
        error: error.message,
        status: FAIL
      }
    }
  }

  resetReportFollowupNotification = (state, action) => {
    return {
      ...state,
      selectedReportFollowupNotification: undefined
    }
  }

  validateReport = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.validateReportPending({ state, action })
      case SUCCESS:
        return this.validateReportSuccess({ state, action })
      case FAIL:
        return this.validateReportFail({ state, action })
      default:
        return state
    }
  }

  validateReportPending = ({ state, action }) => {
    return {
      ...state,
      xmlEditor: {
        ...state.xmlEditor,
        validate: {
          ...state.xmlEditor.validate,
          status: PENDING
        }
      }
    }
  }

  validateReportSuccess = ({ state, action }) => {
    const { result } = action
    return {
      ...state,
      xmlEditor: {
        ...state.xmlEditor,
        validate: {
          ...state.xmlEditor.validate,
          message: action.result.message,
          error: undefined,
          validationErrors: undefined,
          status: SUCCESS
        }
      }
    }
  }

  validateReportFail = ({ state, action }) => {
    const { error } = action
    let validationErrors = _.get(error, 'error.validationErrors', [])
    if (error.code === 'RA-MHR-198-01') {
      validationErrors = _.get(error, 'meta.properties.error.Messages')
    }
    return {
      ...state,
      xmlEditor: {
        ...state.xmlEditor,
        validate: {
          ...state.xmlEditor.validate,
          error: error.message,
          validationErrors,
          status: FAIL
        }
      }
    }
  }

  updateReportXML = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.updateReportXMLPending({ state, action })
      case SUCCESS:
        return this.updateReportXMLSuccess({ state, action })
      case FAIL:
        return this.updateReportXMLFail({ state, action })
      default:
        return state
    }
  }

  updateReportXMLPending = ({ state, action }) => {
    return {
      ...state,
      xmlEditor: {
        ...state.xmlEditor,
        updateXML: {
          error: undefined,
          message: undefined,
          status: PENDING
        }
      }
    }
  }

  updateReportXMLSuccess = ({ state, action }) => {
    return {
      ...state,
      xmlEditor: {
        ...state.xmlEditor,
        updateXML: {
          error: undefined,
          message: translations('XML updated successfully'),
          status: SUCCESS
        }
      }
    }
  }

  updateReportXMLFail = ({ state, action }) => {
    const { error } = action
    return {
      ...state,
      xmlEditor: {
        ...state.xmlEditor,
        updateXML: {
          error: error.message,
          message: undefined,
          status: FAIL
        }
      }
    }
  }

  resetValidation = (state, action) => {
    return {
      ...state,
      xmlEditor: {
        ...state.xmlEditor,
        validate: {
          error: undefined,
          status: undefined,
          message: undefined
        }
      }
    }
  }

  resetUpdateXML = (state, action) => {
    return {
      ...state,
      xmlEditor: {
        ...state.xmlEditor,
        updateXML: {
          error: undefined,
          status: undefined,
          message: undefined
        }
      }
    }
  }

  reassignReportToOrganisation = (state, action) => {
    return {
      ...state,
      reassignment: {
        ...action
      }
    }
  }

  resetReportAssignment = (state, action) => {
    return {
      ...state,
      reassignment: {
        error: undefined,
        status: undefined
      }
    }
  }
}

export default new ReportDetailsReducers()

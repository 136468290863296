import * as validators from '../../../validators'

const smallTextLengthValidator = validators.maxLength(50, 'Text')

export const platformDetails = {
  schema: [
    {
      id: 'name',
      field: 'Input',
      props: {
        label: 'Name',
        name: 'name',
        validate: [smallTextLengthValidator],
        shrink: true,
        required: true
      }
    },
    {
      id: 'domain',
      field: 'Input',
      props: {
        label: 'Hostname',
        name: 'domain',
        shrink: true,
        required: true,
        validate: [validators.hostname]
      }
    },
    {
      id: 'appId',
      field: 'Input',
      props: {
        label: 'App Bundle ID',
        name: 'appId',
        shrink: true,
        required: false
      }
    },
    {
      id: 'themeId',
      field: 'ThemeSelect',
      props: {
        label: 'Theme',
        name: 'themeId',
        shrink: true,
        required: true
      }
    },
    {
      id: 'supportEmail',
      field: 'Email',
      props: {
        label: 'Support Email',
        name: 'supportEmail',
        validate: [validators.email, smallTextLengthValidator],
        required: false,
        shrink: true
      }
    },
    {
      id: 'supportTelephone',
      field: 'Input',
      props: {
        label: 'Support Telephone',
        name: 'supportTelephone',
        validate: [validators.simplePhoneNumber],
        required: false,
        shrink: true
      }
    },
    {
      id: 'privacyPolicyLink',
      field: 'Input',
      props: {
        label: 'Privacy Policy Link',
        name: 'privacyPolicyLink',
        shrink: true,
        required: false,
        validate: [validators.url]
      }
    },
    {
      id: 'publicUserRegistration',
      field: 'Checkbox',
      props: {
        label: 'Allow Public User Registration',
        name: 'publicUserRegistration'
      }
    },
    {
      id: 'companyAccountRequest',
      field: 'Checkbox',
      props: {
        label: 'Allow Company Account Request',
        name: 'companyAccountRequest'
      }
    },
    {
      id: 'reports',
      field: 'Checkbox',
      props: {
        label: 'Allow Reports',
        name: 'reports'
      }
    },
    {
      id: 'caseManagement',
      field: 'Checkbox',
      props: {
        label: 'Allow Transmission Management',
        name: 'caseManagement'
      }
    },
    {
      id: 'postReport',
      field: 'Checkbox',
      props: {
        label: 'Allow Post Reports',
        name: 'postReport'
      }
    },
    {
      id: 'postMultipleReports',
      field: 'Checkbox',
      props: {
        label: 'Allow Post Bulk Upload of Reports',
        name: 'postMultipleReports'
      }
    },
    {
      id: 'advancedPostReport',
      field: 'Checkbox',
      props: {
        label: 'Allow Advanced Post Report',
        name: 'advancedPostReport'
      }
    },
    {
      id: 'analytics',
      field: 'Checkbox',
      props: {
        label: 'Allow Analytics',
        name: 'analytics'
      }
    },
    {
      id: 'changeManagement',
      field: 'Checkbox',
      props: {
        label: 'Allow Change Management',
        name: 'changeManagement'
      }
    },
    {
      id: 'loadReport',
      field: 'Checkbox',
      props: {
        label: 'Allow Load Reports',
        name: 'loadReport'
      }
    },
    {
      id: 'updateReport',
      field: 'Checkbox',
      props: {
        label: 'Allow Update Reports',
        name: 'updateReport'
      }
    },
    {
      id: 'denyPublicUpdates',
      field: 'Checkbox',
      props: {
        label: 'Deny Public User Report Updates',
        name: 'denyPublicUpdates'
      }
    },
    {
      id: 'createAck',
      field: 'Checkbox',
      props: {
        label: 'Allow Acknowledgement Creation',
        name: 'createAck'
      }
    },
    {
      id: 'watchList',
      field: 'Checkbox',
      props: {
        label: 'Allow Watch List',
        name: 'watchList'
      }
    },
    {
      id: 'products',
      field: 'Checkbox',
      props: {
        label: 'Allow Products',
        name: 'products'
      }
    },
    {
      id: 'pharmaceuticalReferrals',
      field: 'Checkbox',
      props: {
        label: 'Allow Pharmaceutical Referrals',
        name: 'pharmaceuticalReferrals'
      }
    },
    {
      id: 'registerEmailConfirmation',
      field: 'Checkbox',
      props: {
        label: 'Allow Registration Email Confirmation',
        name: 'registerEmailConfirmation'
      }
    },
    {
      id: 'developer',
      field: 'Checkbox',
      props: {
        label: 'Allow Developer Configuration',
        name: 'developer'
      }
    },
    {
      id: 'reportConfigurationBeta',
      field: 'Checkbox',
      props: {
        label: 'Allow Report Configuration (Beta)',
        name: 'reportConfigurationBeta'
      }
    },
    {
      id: 'communications',
      field: 'Checkbox',
      props: {
        label: 'Allow Communications Tile',
        name: 'communications'
      }
    },
    {
      id: 'announcementManagement',
      field: 'Checkbox',
      props: {
        label: 'Allow Announcement Management Tile',
        name: 'announcementManagement'
      }
    },
    {
      id: 'enableWorkspaces',
      field: 'Checkbox',
      props: {
        label: 'Allow Users To Sign In To Private Workspaces',
        name: 'enableWorkspaces'
      }
    },
    {
      id: 'requireApprovalForJoiningOrganisations',
      field: 'Checkbox',
      props: {
        label: 'Require Approvals for joining organisations',
        name: 'requireApprovalForJoiningOrganisations'
      }
    },
    {
      id: 'enableNewReportBeta',
      field: 'Checkbox',
      props: {
        label: 'Enable New Report - Beta',
        name: 'enableNewReportBeta'
      }
    },
    {
      id: 'allowInvitingUsersToOrganisations',
      field: 'Checkbox',
      props: {
        label: 'Allow Inviting Users to Organisations',
        name: 'allowInvitingUsersToOrganisations'
      }
    },
    {
      id: 'allowIntegrationManagement',
      field: 'Checkbox',
      props: {
        label: 'Allow Receiver Configuration Tile',
        name: 'integrationManagement'
      }
    },
    {
      id: 'enableMultipleApplications',
      field: 'Checkbox',
      props: {
        label: 'Enable Multiple Applications',
        name: 'enableMultipleApplications'
      }
    },
    {
      id: 'allowTerminology',
      field: 'Checkbox',
      props: {
        label: 'Allow Terminology Tile',
        name: 'terminology'
      }
    },
    {
      id: 'allowReportAudit',
      field: 'Checkbox',
      props: {
        label: 'Allow Report Amendments',
        name: 'reportAuditing'
      }
    },
    {
      id: 'allowWebsiteManagement',
      field: 'Checkbox',
      props: {
        label: 'Allow Website Management Tile',
        name: 'websiteManagement'
      }
    },
    {
      id: 'notificationRecipientsReportFollowUp',
      field: 'Dropdown',
      props: {
        name: 'notificationRecipientsReportFollowUp',
        label: 'Report follow-up notification recipients',
        noNullOption: true,
        shrink: true,
        options: [
          { value: 'NONE', label: 'None' },
          { value: 'SUBMITTER', label: 'Submitter' },
          { value: 'ORGANISATION', label: 'Organisation' }
        ]
      }
    },
    {
      id: 'enableEmailNotificationRecipient',
      field: 'Checkbox',
      props: {
        label: 'Enable Email Notification Recipient',
        name: 'enableEmailNotificationRecipient'
      }
    },
    {
      id: 'enableReportReassignment',
      field: 'Checkbox',
      props: {
        label: 'Enable Report Reassignment',
        name: 'enableReportReassignment'
      }
    },
    {
      id: 'acknowledgmentSchemaName',
      field: 'Dropdown',
      props: {
        name: 'acknowledgmentSchemaName',
        label: 'Default Acknowledgment Form Schema',
        noNullOption: true,
        shrink: true,
        options: [
          { value: '', label: 'Default (Current Behaviour)' },
          { value: 'mhraR2Ack', label: 'R2 Ack' },
          { value: 'mhraR3Ack', label: 'R3 Ack' }
        ]
      }
    },
    {
      id: 'enableGuestReportEmailRegistration',
      field: 'Checkbox',
      props: {
        label: 'Enable Guest Report Email Registration',
        name: 'enableGuestReportEmailRegistration'
      }
    },
    {
      id: 'enableNewTranslationsManagement',
      field: 'Checkbox',
      props: {
        label: 'Enable New Translation Management Interface',
        name: 'enableNewTranslationsManagement'
      }
    },
    {
      id: 'enableLocationsManagement',
      field: 'Checkbox',
      props: {
        label: 'Allow Location Management',
        name: 'enableLocationsManagement'
      }
    },
    {
      id: 'disableNotificationEmails',
      field: 'Checkbox',
      props: {
        label: 'Disable Notification Emails',
        name: 'disableNotificationEmails'
      }
    },
    {
      id: 'edqmReportTarget',
      field: 'Dropdown',
      props: {
        name: 'edqmReportTarget',
        label: 'EDQM Report Target',
        shrink: true,
        options: [
          { value: 'sentinel', label: 'Sentinel' },
          { value: 'halo', label: 'Halo' }
        ]
      }
    },
    {
      id: 'enableGuestUsersFollowUps',
      field: 'Checkbox',
      props: {
        label: 'Enable Guest User Followups',
        name: 'enableGuestUsersFollowUps'
      }
    },
    {
      id: 'enablePermissionsEditor',
      field: 'Checkbox',
      props: {
        label: 'Allow Permissions Tile',
        name: 'enablePermissionsEditor'
      }
    },
    {
      id: 'enableActionsModalAfterUserCreation',
      field: 'Checkbox',
      props: {
        label: 'Enable Action Modal After User Creation',
        name: 'enableActionsModalAfterUserCreation'
      }
    },
    {
      id: 'enableBackgroundAnalyticsExports',
      field: 'Checkbox',
      props: {
        label: 'Enable Background Download for Analytic Exports',
        name: 'enableBackgroundAnalyticsExports'
      }
    },
    {
      id: 'enableExportManagement',
      field: 'Checkbox',
      props: {
        label: 'Enable Export Management',
        name: 'enableExportManagement'
      }
    }
  ]
}

export const formSchemaFieldExclusionsConfiguration = {
  schema: [
    {
      id: 'formSchemaFieldExclusions',
      field: 'Repeatable',
      layout: [
        'formSchemaFieldExclusions:12'
      ],
      props: {
        shrink: true,
        name: 'formSchemaFieldExclusions',
        label: 'Exclusions for NCA Report No (MIR/FSCA)',
        formId: 'formSchemaFieldExclusions',
        repeatableLabelPlaceholder: 'Form Schema Configuration',
        repeatableLabel: ['exclusionText'],
        repeatableLabelCapitalizedOnDisplay: true,
        required: true,
        schema: [
          {
            id: 'exclusionText',
            field: 'Input',
            props: {
              label: 'Exclusion Text',
              name: 'exclusionText',
              shrink: true,
              required: true
            }
          }
        ]
      }
    }
  ]
}

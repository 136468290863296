import fp from 'lodash/fp'

export const types = {
    SET_FILTER_ORGANISATIONS: 'SET_FILTER_ORGANSATIONS',
    SET_FILTER_APPROVAL_STATUS: 'SET_FILTER_APPROVAL_STATUS',
    SET_FILTER_DATE_FROM: 'SET_FILTER_DATE_FROM',
    SET_FILTER_DATE_TO: 'SET_FILTER_DATE_TO'
}

export const initialiseState = ({ organisationIds, approvalStatus, dateFrom, dateTo }) => () => {
    return {
        organisationIds,
        approvalStatus,
        dateFrom,
        dateTo
    }
}

export const reducer = (state, action) => {
    switch (action.type) {
        case types.SET_FILTER_ORGANISATIONS:
            return fp.set('organisationIds', action.payload.value, state)
        case types.SET_FILTER_APPROVAL_STATUS:
            return fp.set('approvalStatus', action.payload.value, state)
        case types.SET_FILTER_DATE_FROM:
            return fp.set('dateFrom', action.payload.value, state)
        case types.SET_FILTER_DATE_TO:
            return fp.set('dateTo', action.payload.value, state)
        default:
            return state
    }
}

import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import { rem } from 'polished'
import Select from 'react-select'

import FieldMeta from '../FieldMeta'
import Field from '../Field'
import NullFlavourField from '../NullFlavourField'

const StyledOption = styled.option`
  ${({ hidden }) => hidden ? `display: none;` : ''}
`

export const StyledSelect = styled.input`
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.inputBorder};
  width: 100%;
  height: ${rem('35px')};
  padding: 8px;
  border-radius: 0;
  line-height: 1.4;
  margin: 0;
  color: ${props => props.theme.colors.inputText};
  font-size: ${props => `${props.theme.type.input.small}px`};
  font-weight: 400;
  transition: box-shadow .3s ease-in-out;
  ${media.greaterThan('small')`
    height: ${rem('41px')};
    padding: 8px 12px;
    font-size: ${props => `${props.theme.type.input.large}px`};
  `}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  &:focus, &:active, &:focus {
    outline: 0;
    box-shadow: ${({ theme, error }) => error ? `0 0 0 3px ${theme.colors.error}` : `0 0 0 3px ${theme.colors.primary}`};
  }
  &:disabled {
    background-color: #FAFAFA;
  }
  padding-right: 25px;
  background-color: #fff;
`
export const StyledSelectBox = styled(Select)`
  width: 100%;
  .mhraUISelect__control {
    border-radius: 0;
    border-color: ${props => props.theme.colors.inputBorder};
    background-color: ${props => props.disabled ? '#FAFAFA' : '#fff'};
  }
  .mhraUISelect__value-container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .mhraUISelect__control:focus, .mhraUISelect__control:active, .mhraUISelect__control:focus,
  .mhraUISelect__control--is-focused {
    outline: 0;
    box-shadow: ${({ theme, error }) => error ? `0 0 0 3px ${theme.colors.error}` : `0 0 0 3px ${theme.colors.primary}`};
  }
  .mhraUISelect__control--is-focused:hover {
    border-color: ${props => props.theme.colors.inputBorder};
  }
  .mhraUISelect__option--is-selected,
  .mhraUISelect__option--is-selected.mhraUISelect__option--is-focused {
    background-color: ${props => props.theme.colors.secondary};
  }
  .mhraUISelect__option--is-focused {
    background-color: #eaeaea;
  }
  .mhraUISelect__option--is-focused:active {
    background-color: #ddd;
  }
  .mhraUISelect__dropdown-indicator {
    background-image: ${props => `url(${props.theme.icons.selectArrow})`};
    background-position: center center;
    background-size: 8px;
    background-repeat: no-repeat;
    width: 32px;
  }
  .mhraUISelect__dropdown-indicator > svg {
    display: none;
  }
`


export const SelectBoxWrapper = styled.div`
  position: relative;
`

export const SelectArrowIconWrapper = styled.div`
  position: absolute;
  width: 100%; 
  height: 0;
  top: 4px;
  display: flex;
  justify-content: end;
`

export const SelectArrowIcon = styled.div`
  background-image: ${props => `url(${props.theme.icons.selectArrow})`};
  background-position: center center;
  background-size: 8px;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
`

/**
 * A basic select with `react-select` for complex functionality e.g. multiple selection
 */
const SelectBox = (props) => {
  const {
    input: {
      name,
      value,
      onChange,
      onFocus,
      onBlur
    },
    meta: {
      error,
      touched
    },
    id,
    label,
    hint,
    required,
    options,
    isMulti,
    placeholder: _placeHolder,
    nullFlavours,
    disabled,
    handleChangeOtherFields,
    groupOptions = false,
    groupAttribute,
    parent,
    parentValue,
    parentLabel
  } = props

  const [selectValue, setSelectValue] = useState()
  const fieldInvalid = error && touched
  const errorMessage = fieldInvalid ? error : undefined

  const shouldGroupOptions = groupOptions && groupAttribute && parent && parentValue && parentLabel
  const hasNullFlavours = nullFlavours && !_.isEmpty(nullFlavours)
  const placeholder = disabled ? null : _placeHolder

  useEffect(() => {
    let currentValue

    if (_.isArray(value)) {
      /**
      * Handle `isMulti` and the values being an array of strings
      */
      currentValue = _.reduce(value, (memo, item) => {
        const matchedOption = _.find(options, { value: item })
        if (matchedOption) {
          memo.push(matchedOption)
        }
        return memo
      }, [])
    } else {
      /**
       * A normal select will be a single string
       */
      currentValue = _.find(options, { value })
    }

    setSelectValue(currentValue)
  }, [value, options])

  const handleChangeMulti = (event) => {
    const value = _.isArray(event) ? _.map(event, (option) => option.value) : _.get(event, 'value')
    if (_.isFunction(handleChangeOtherFields)) {
      handleChangeOtherFields(value)
    }
    onChange(value)
    onBlur(value)
  }

  const handleChange = (event) => {
    const value = _.get(event, 'target.value')
    if (_.isFunction(handleChangeOtherFields)) {
      handleChangeOtherFields(value)
    }
    onChange(value)
    onBlur(value)
  }

  const renderOptions = (options) => {
    return options.map((option) => {
      const { value: optionValue, label: optionLabel, hidden = false } = option
      return <StyledOption
        key={`${optionLabel}_${optionValue}`}
        value={optionValue}
        hidden={hidden}
      >
        {optionLabel}
      </StyledOption>
    })
  }

  /**
 * Uses parent to find the nested parent object in the children
 * Uses groupAttribute to know what attribute to group the children by
 * Uses parentValue to access what value the children are grouped by
 * Uses parentLabel to access what the group should be labelled with
 */
  const renderGroupedOptions = (options) => {
    const parents = _.chain(options)
      .map(parent)
      .compact()
      .uniqBy('id')
      .value()

    const groupedChildren = _.groupBy(options, groupAttribute)
    const groupedOptions = _.map(parents, parent => {
      const parentId = parent[parentValue]
      const children = groupedChildren[parentId]
      if (children) {
        return (
          <optgroup label={parent[parentLabel]}>
            {_.map(children, option => {
              const { value: optionValue, label: optionLabel, hidden = false } = option
              return (
                <StyledOption
                  key={`${optionLabel}_${optionValue}`}
                  value={optionValue}
                  hidden={hidden}
                >
                  {optionLabel}
                </StyledOption>
              )
            })}
          </optgroup>
        )
      }
    })

    const orphans = groupedChildren['null']
    const remainingOptions = []
    if (orphans) {
      remainingOptions.push(...renderOptions(orphans))
    }

    const allOptions = [...groupedOptions, ...remainingOptions]

    if (placeholder) {
      allOptions.unshift(
        <StyledOption
          key={placeholder}
          value={''}
        >
          {placeholder}
        </StyledOption>
      )
    }
    return allOptions
  }

  const renderSelectBox = () => {
    const dislayValueForDisable = selectValue ? selectValue.label : ''

    return (
      <Field error={errorMessage}>
        <FieldMeta
          name={id}
          label={label}
          error={errorMessage}
          hint={hint}
          required={required}
          disabled={disabled}
        />
        <SelectBoxWrapper>
        {
          isMulti
            ? (
              <StyledSelectBox
                value={selectValue}
                options={options}
                inputId={id}
                classNamePrefix={'mhraUISelect'}
                error={errorMessage}
                isMulti={isMulti}
                aria-required={required}
                aria-invalid={fieldInvalid}
                placeholder={placeholder}
                onChange={handleChangeMulti}
                isOptionDisabled={true}
                disabled={disabled}
                isDisabled={disabled}
                components={
                  disabled && !isMulti ?
                    {
                      SelectContainer: () => <StyledSelect disabled={disabled} value={dislayValueForDisable} />
                    }
                    : {}
                }
              />
            )
            : (
                <StyledSelect
                  id={id}
                  as={'select'}
                  name={name}
                  value={value}
                  error={errorMessage}
                  aria-required={required}
                  aria-invalid={fieldInvalid}
                  onChange={handleChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  disabled={disabled}
                  placeholder={placeholder}
                >
                  {shouldGroupOptions ? renderGroupedOptions(options) : renderOptions(options)}
                  <optgroup hidden></optgroup>
                </StyledSelect>
            )
        }
          <SelectArrowIconWrapper>
            <SelectArrowIcon />
          </SelectArrowIconWrapper>
        </SelectBoxWrapper>
      </Field>
    )
  }

  if (hasNullFlavours) {
    return (
      <NullFlavourField
        {...props}
        render={renderSelectBox}
      />
    )
  }

  return (renderSelectBox())
}

SelectBox.propTypes = {
  /** ID used for input */
  id: PropTypes.string,
  /** User friendly name for the field */
  label: PropTypes.string,
  /** Hints and helpful information about completing the the field */
  help: PropTypes.string,
  /** If the field is required */
  required: PropTypes.bool,
  /** If multiple options can be selected at once */
  multiple: PropTypes.bool,
  /** Placeholder text when no value is selected */
  placeholder: PropTypes.string,
  /** Input props based from React Final Form
   *
   * `name` - Used to associate label and input
   *
   * `value` - Value of the input
   *
   * `onChange` - Function called when value of the field has changed
   *
   * `onBlur` - Function called when focus has been removed from the field
   *
   * `onFocus` - Function called when focus has been given to the field
  */
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }),
  /** Meta props based from React Final Form
   *
   * `error` - Field validation message
   *
   * `touched` - true if this field has ever gained and lost focus. false otherwise.
  */
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  })
}

SelectBox.defaultProps = {
  required: false,
  input: {},
  meta: {},
  options: []
}

export default SelectBox

"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFormSchema = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var formHelpers = _interopRequireWildcard(require("./formHelpers"));

var _yesNo = _interopRequireDefault(require("../config/yesNo"));

var _incidentType = _interopRequireDefault(require("../config/incidentType"));

var _computedHidden = require("../../../formHelpers/computedHidden");

var _validators = require("../../../formHelpers/validators");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var getFormSchema = function getFormSchema(validators) {
  return [{
    id: 'incidentType',
    field: 'Input',
    computedHidden: _computedHidden.hide,
    props: {
      shrink: true,
      name: 'incidentType',
      label: 'Incident Type',
      required: false
    }
  }, {
    id: 'transfusionDate',
    field: 'Date',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'transfusionDate',
      label: 'Date of transfusion',
      required: true,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.handle(_validators.dateTimeNotInFutureWithoutFormat), validators.isDateBeforeOrEqual('reactionStartDate', 'Date reaction started')],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'transfusionTime',
    field: 'Input',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'transfusionTime',
      label: 'Time of transfusion started',
      validate: [validators.isTimeWith15MinBlock]
    }
  }, {
    id: 'reactionStartDate',
    field: 'Date',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'reactionStartDate',
      label: 'Date reaction started',
      required: true,
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.handle(_validators.dateTimeNotInFutureWithoutFormat), validators.isDateAfterOrEqual('transfusionDate', 'Date of transfusion')],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'reactionEndDate',
    field: 'Date',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'reactionEndDate',
      label: 'Date reaction ended',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.handle(_validators.dateTimeNotInFutureWithoutFormat), validators.isDateAfterOrEqual('reactionStartDate', 'Date reaction started')],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'componentNtf',
    field: 'TerminologyLookupInput',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      lookupOptions: {
        listName: 'SABRE_SAR_COMPONENT'
      },
      shrink: true,
      name: 'componentNtf',
      label: 'Adverse Reaction related to',
      required: true,
      multiple: true
    }
  }, {
    id: 'componentOtherNtf',
    computedHidden: formHelpers.showIfComponentNtfOther,
    computedValue: formHelpers.clearIfNotComponentNftOther,
    computedDisabled: formHelpers.isNotNotification,
    field: 'Input',
    props: {
      shrink: true,
      name: 'componentOtherNtf',
      label: 'If other, please state here',
      required: true,
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'reactionTypeNtf',
    field: 'TerminologyLookupInput',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      lookupOptions: {
        listName: 'SABRE_REACTION_TYPE'
      },
      shrink: true,
      name: 'reactionTypeNtf',
      label: 'Type of adverse reaction(s)',
      required: true
    }
  }, {
    id: 'reactionTypeOtherNtf',
    computedHidden: formHelpers.showIfReactionTypeNtfOther,
    computedValue: formHelpers.clearIfNotReactionTypeNtfOther,
    computedDisabled: formHelpers.isNotNotification,
    field: 'Input',
    props: {
      shrink: true,
      name: 'reactionTypeOtherNtf',
      label: 'If other, please state here',
      required: true,
      validate: [validators.maxLength(200, 'Field')]
    }
  }, {
    id: 'imputabilityNtf',
    field: 'TerminologyLookupInput',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      lookupOptions: {
        listName: 'SABRE_IMPUTABILITY_LEVEL'
      },
      shrink: true,
      name: 'imputabilityNtf',
      label: 'Imputability level',
      required: true
    }
  }, {
    id: 'reactionConfirmed',
    computedHidden: formHelpers.showIfIsConfirmation,
    computedDisabled: formHelpers.lockIfIsConfirmation,
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'reactionConfirmed',
      label: 'Confirmation of Serious Adverse Reaction',
      required: true,
      options: _yesNo["default"]
    }
  }, {
    id: 'componentCnf',
    computedHidden: formHelpers.showIfIsConfirmation,
    field: 'TerminologyLookupInput',
    computedDisabled: formHelpers.lockIfIsConfirmation,
    props: {
      lookupOptions: {
        listName: 'SABRE_SAR_COMPONENT'
      },
      shrink: true,
      name: 'componentCnf',
      label: 'Confirm that SAR is related to',
      required: true,
      multiple: true
    }
  }, {
    id: 'componentOtherCnf',
    computedHidden: formHelpers.showIfComponentCnfOther,
    computedValue: formHelpers.clearIfNotComponentCnfOther,
    computedDisabled: formHelpers.lockIfIsConfirmation,
    field: 'Input',
    props: {
      shrink: true,
      name: 'componentOtherCnf',
      label: 'If other, please state here',
      required: false
    }
  }, {
    id: 'reactionConfirmedDate',
    computedHidden: formHelpers.showIfIsConfirmation,
    computedDisabled: formHelpers.lockIfIsConfirmation,
    field: 'Date',
    props: {
      shrink: true,
      name: 'reactionConfirmedDate',
      label: 'Date of confirmation',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD'])],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'submittedCnf',
    computedHidden: _computedHidden.hide,
    field: 'Date',
    props: {
      shrink: true,
      name: 'submittedCnf',
      label: 'Date Confirmation was submitted'
    }
  }, {
    id: 'reactionDateCnf',
    computedHidden: formHelpers.showIfIsConfirmation,
    computedDisabled: formHelpers.lockIfIsConfirmation,
    field: 'Date',
    props: {
      shrink: true,
      name: 'reactionDateCnf',
      label: 'Confirm Date of reaction',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.handle(_validators.dateTimeNotInFutureWithoutFormat)],
      helperText: 'YYYY/MM/DD'
    }
  }, {
    id: 'reactionTypeChange',
    computedHidden: formHelpers.showIfIsConfirmation,
    computedDisabled: formHelpers.lockIfIsConfirmation,
    field: 'Dropdown',
    props: {
      shrink: true,
      name: 'reactionTypeChange',
      label: 'Change of reaction type',
      required: true,
      options: _yesNo["default"]
    }
  }, {
    id: 'reactionTypeCnf',
    computedHidden: formHelpers.showIfIsConfirmation,
    field: 'TerminologyLookupInput',
    computedDisabled: formHelpers.lockIfIsConfirmation,
    props: {
      lookupOptions: {
        listName: 'SABRE_REACTION_TYPE'
      },
      shrink: true,
      name: 'reactionTypeCnf',
      label: 'Reaction Type'
    }
  }, {
    id: 'reactionTypeOtherCnf',
    computedHidden: formHelpers.showIfReactionTypeCnfOther,
    computedValue: formHelpers.clearIfNotReactionTypeCnfOther,
    computedDisabled: formHelpers.lockIfIsConfirmation,
    field: 'Input',
    props: {
      shrink: true,
      name: 'reactionTypeOtherCnf',
      label: 'Reaction Type Other',
      required: false
    }
  }, {
    id: 'clinicalOutcome',
    computedHidden: formHelpers.showIfIsConfirmation,
    field: 'TerminologyLookupInput',
    computedDisabled: formHelpers.lockIfIsConfirmation,
    props: {
      lookupOptions: {
        listName: 'SABRE_CLINICAL_OUTCOME'
      },
      shrink: true,
      name: 'clinicalOutcome',
      label: 'Clinical Outcome (if known)',
      required: true
    }
  }, {
    id: 'imputabilityCnf',
    computedHidden: formHelpers.showIfIsConfirmation,
    field: 'TerminologyLookupInput',
    computedDisabled: formHelpers.lockIfIsConfirmation,
    props: {
      lookupOptions: {
        listName: 'SABRE_IMPUTABILITY_LEVEL'
      },
      shrink: true,
      name: 'imputabilityCnf',
      label: 'Imputability level',
      required: true
    }
  }, {
    id: 'report',
    computedHidden: formHelpers.showIfShouldShowReportField,
    computedValue: formHelpers.clearIfShouldNotShowReportField,
    computedDisabled: formHelpers.lockIfIsConfirmation,
    field: 'Input',
    props: {
      shrink: true,
      name: 'report',
      label: 'Report (For Imputability 2/3)',
      required: true,
      rows: 5,
      multiline: true
    }
  }, {
    id: 'dob',
    field: 'Date',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'dob',
      label: 'Date of birth',
      validate: [validators.isAcceptedDateFormat(['YYYYMMDD']), validators.minimumOf1(formHelpers.ageCompletion.fields, formHelpers.ageCompletion.error)]
    }
  }, {
    id: 'age',
    field: 'Input',
    computedDisabled: formHelpers.isNotNotification,
    props: {
      shrink: true,
      name: 'age',
      label: 'Age',
      validate: [validators.numeric, validators.isBelow(140), validators.isAbove(-1), validators.minimumOf1(formHelpers.ageCompletion.fields, formHelpers.ageCompletion.error)]
    }
  }];
};

exports.getFormSchema = getFormSchema;
import EmailIcon from './icon-email.svg'
import PhoneIcon from './icon-phone.svg'
import CopyIcon from './icon-copy.svg'
import UserManagementIcon from './icon-user-management.svg'
import DeactivatedIcon from './icon-deactivated.svg'
import CloseIcon from './icon-close.png'
import MoreIcon from './icon-more.svg'
import BasketIcon from './icon-basket.png'
import TrashIcon from './icon-trash-dark.svg'
import SmsIcon from './icon-sms-dark.svg'
import ScanIcon from './icon-scan-light.svg'
import WishlistIcon from './icon-add-to-wishlist.svg'
import MessageIcon from './icon-add-to-message.svg'
import CloseIconBlack from './icon-close-black.svg'
import FileIcon from './icon-file.svg'
import ShirtSizeIcon from './icon-shirt-size.svg'

import ReportsIcon from './reports.svg'
import ReportManagementIcon from './report-management.svg'
import ReportConfigurationIcon from './report-configuration.svg'
import ResourcesManagementIcon from './resources.svg'
import NewReportIcon from './new-report.svg'
import PostReportIcon from './post-report.svg'
import NewsIcon from './news.svg'
import newsIconWhite from './news-white.svg'
import communicationsIcon from './communications-icon.svg'
import analyticsIcon from './retail-analytics-icon.svg'
import CaseManagementIcon from './case-management.svg'

import ApptIcon from './icon-appointment.png'
import messageDarkIcon from './icon-message-dark.svg'
import GreenCheckIcon from './icon-check-circle-green.svg'
import OrangeCheckIcon from './icon-check-circle-orange.svg'
import RedCrossIcon from './Icon-cross-circle-red.svg'
import LocationIcon from './icon-location.svg'

import UserManagementImg from './user-management.png'
import ReportsImg from './reports.png'
import ReportManagementImg from './report-management.png'
import ReportConfigurationImg from './report-configuration.png'
import ReportConfigurationBetaImg from './report-configuration-beta.png'
import ResourcesManagementImg from './resources.png'
import NewReportImg from './new-r2-report.png'
import PostReportImg from './post-report.png'
import OrganisationManagementImg from './organisation-management.png'
import NewsImg from './news.png'
import PlatformsImg from './platform-management.png'
import productsImg from './Tile-Products.png'
import watchListImg from './Tile-Favourites.png'
import communicationsImg from './Tile-Communications.png'
import analyticsImg from './Tile-Analytics.png'
import CaseManagementImg from './case-management.png'
import changeManagementImg from './Tile-Change-Management.png'
import terminologyImg from './Tile-Terminology.png'
import BlankImg from './1x1.png'
import ImageNotAvailableImg from './image-not-available.png'
import notificationsImg from './notificationsImg.png'
import pharmaceuticalReferralsImg from './pharmaceuticalReferralsImg.png'
import IntegrationManagementImg from './integration-management.png'
import DeveloperImg from './developer.png'
import websiteManagementImg from './Tile-Website-Management.png'
import permissionsImg from './permissions-tile.png'
import userGroupsImg from './user-groups-tile.png'

import addFromGallery from './icon-add-from-gallery.svg'
import camera from './icon-camera.svg'
import exportImg from './export.svg'

const icons = {
  emailIcon: EmailIcon,
  phoneIcon: PhoneIcon,
  copyIcon: CopyIcon,
  userManagementImg: UserManagementImg,
  organisationManagementImg: OrganisationManagementImg,
  deactivatedIcon: DeactivatedIcon,
  closeIcon: CloseIcon,
  moreIcon: MoreIcon,
  messageDarkIcon,
  basketIcon: BasketIcon,
  trashIcon: TrashIcon,
  smsIcon: SmsIcon,
  scanIcon: ScanIcon,
  wishlistIcon: WishlistIcon,
  messageIcon: MessageIcon,
  closeIconBlack: CloseIconBlack,
  fileIcon: FileIcon,
  shirtSizeIcon: ShirtSizeIcon,
  greenCheckIcon: GreenCheckIcon,
  orangeCheckIcon: OrangeCheckIcon,
  redCrossIcon: RedCrossIcon,
  locationIcon: LocationIcon,
  blankImg: BlankImg,
  imageNotAvailableImg: ImageNotAvailableImg,
  addFromGallery,
  camera
}

const cornerIcon = {
  apptIcon: ApptIcon,
  reportsIcon: ReportsIcon,
  reportManagementIcon: ReportManagementIcon,
  reportConfigurationIcon: ReportConfigurationIcon,
  resourcesManagementIcon: ResourcesManagementIcon,
  userManagementIcon: UserManagementIcon,
  organisationManagementIcon: UserManagementIcon,
  newReportIcon: NewReportIcon,
  postReportIcon: PostReportIcon,
  newsIcon: NewsIcon,
  newsIconWhite,
  communicationsIcon,
  analyticsIcon,
  caseManagementIcon: CaseManagementIcon
}
const hubTileImages = {
  reportsImg: ReportsImg,
  reportManagementImg: ReportManagementImg,
  reportConfigurationImg: ReportConfigurationImg,
  reportConfigurationBetaImg: ReportConfigurationBetaImg,
  resourcesManagementImg: ResourcesManagementImg,
  userManagementImg: UserManagementImg,
  newReportImg: NewReportImg,
  postReportImg: PostReportImg,
  organisationManagementImg: OrganisationManagementImg,
  newsImg: NewsImg,
  platformsImg: PlatformsImg,
  productsImg,
  watchListImg,
  notificationsImg,
  pharmaceuticalReferralsImg,
  communicationsImg,
  analyticsImg,
  caseManagementImg: CaseManagementImg,
  changeManagementImg,
  terminologyImg,
  integrationManagementImg: IntegrationManagementImg,
  developerImg: DeveloperImg,
  websiteManagementImg,
  permissionsImg,
  userGroupsImg,
  exportImg
}

export const getImage = name => {
  return icons[name] || cornerIcon[name] || hubTileImages[name]
}
